import { AuditDestinationAuthentication, Connector } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuditDestinationElement, DestinationTypeEnum } from '../audit-destinations/audit-destinations.component';
import { getDefaultNewRowProperties } from '../table-layout-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';

export interface AuditDestinationConnectorDialogData {
  connectors: Array<Connector>;
  orgId: string;
}

@Component({
  selector: 'portal-audit-destination-connector-dialog',
  templateUrl: './audit-destination-connector-dialog.component.html',
  styleUrls: ['./audit-destination-connector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditDestinationConnectorDialogComponent implements OnInit {
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public connectors: Array<Connector>;
  private orgId: string;
  public allForms: UntypedFormGroup;
  public connectorFormGroup: UntypedFormGroup;
  public nameFormGroup: UntypedFormGroup;
  private emptyAuditDestinationConnectorElement = this.getEmptyAuditDestinationConnectorElement();
  public connectorIdToConnectorMap: Map<string, Connector> = new Map();
  public connectorNameToConnectorMap: Map<string, Connector> = new Map();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AuditDestinationConnectorDialogData,
    public dialogRef: MatDialogRef<AuditDestinationConnectorDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef
  ) {
    if (data) {
      this.connectors = data.connectors;
      this.orgId = data.orgId;
    }
  }

  public ngOnInit(): void {
    this.setConnectorMaps();
    this.setAllFormsData();
  }

  private setConnectorMaps(): void {
    if (!this.connectors) {
      return;
    }
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of this.connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private initializeNameFormGroup(): void {
    this.nameFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }

  private initializeConnectorFormGroup(): void {
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: ['', [Validators.required]],
    });
  }

  private initializeFormGroups(): void {
    this.initializeNameFormGroup();
    this.initializeConnectorFormGroup();
  }

  private setAllFormsData(): void {
    this.initializeFormGroups();
    this.allForms = this.formBuilder.group({
      nameFormGroup: this.nameFormGroup,
      connectorFormGroup: this.connectorFormGroup,
    });
    this.changeDetector.detectChanges();
  }

  private getEmptyAuditDestinationConnectorElement(): AuditDestinationElement {
    return {
      name: '',
      destination_type: DestinationTypeEnum.connector,
      location: '',
      enabled: true,
      org_id: this.orgId,
      filters: [],
      comment: '',
      authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      authentication: {
        authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      },
      backingObject: {
        spec: {
          name: '',
          destination_type: DestinationTypeEnum.connector,
          location: '',
          enabled: true,
          org_id: this.orgId,
          filters: [],
          comment: '',
          authentication: {
            authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
          },
        },
      },
      ...getDefaultNewRowProperties(),
    };
  }

  public updateConnector(connectorName: string): void {
    const targetConnector = this.connectorNameToConnectorMap.get(connectorName);
    this.emptyAuditDestinationConnectorElement.location = targetConnector.metadata.id;
  }

  public disableSaveButton(): boolean {
    return this.allForms.invalid;
  }

  public onConfirmClick(): void {
    const name = this.nameFormGroup.get('name').value;
    this.emptyAuditDestinationConnectorElement.name = name;
    this.emptyAuditDestinationConnectorElement.org_id = this.orgId;
    this.dialogRef.close(this.emptyAuditDestinationConnectorElement);
  }
}
