import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import {
  AuditDestination,
  AuditsService,
  ListAuditDestinationsRequestParams,
  AuditDestinationSpec,
  CreateAuditDestinationRequestParams,
  GetAuditDestinationRequestParams,
  ReplaceAuditDestinationRequestParams,
  patch_via_put,
  AuditDestinationAuthentication,
  AuditDestinationFilter,
  Connector,
  ConnectorsService,
  ConnectorService,
} from '@agilicus/angular';
import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { takeUntil, concatMap, map, catchError } from 'rxjs/operators';
import { updateTableElements, createEnumChecker } from '@app/shared/components/utils';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { Subject } from 'rxjs';
import { selectCanAdminAuditDestinations } from '@app/core/user/permissions/audit-destinations.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import {
  Column,
  createInputColumn,
  setColumnDefs,
  createSelectRowColumn,
  createSelectColumn,
  createCheckBoxColumn,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { ButtonType } from '../button-type.enum';
import { AuditRecordAccessType } from '../audit-record-access-types.enum';
import { capitalizeFirstLetter, replaceCharacterWithSpace } from '../utils';
import { OptionalAuditDestinationElement } from '../optional-types';
import { sortArrayByMetadataCreatedDate } from '../date-utils';
import { createAuditConnectorService$, getConnectors } from '@app/core/api/connectors/connectors-api-utils';
import {
  AuditDestinationConnectorDialogComponent,
  AuditDestinationConnectorDialogData,
} from '../audit-destination-connector-dialog/audit-destination-connector-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getDefaultDialogConfig } from '../dialog-utils';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';

export interface AuditDestinationElement extends AuditDestinationSpec, TableElement {
  backingObject?: AuditDestination;
  authentication_type?: AuditDestinationAuthentication.AuthenticationTypeEnum;
  username?: string;
  password?: string;
  token?: string;
  access?: boolean;
  authorization?: boolean;
  connector_id?: string;
}

export interface CombinedPermissionsAndAuditDestinationData {
  permission: OrgQualifiedPermission;
  auditDestinations: Array<AuditDestination>;
  connectors: Array<Connector>;
}

export enum DestinationTypeEnum {
  file = 'file',
  webhook = 'webhook',
  graylog = 'graylog',
  connector = 'connector',
}

@Component({
  selector: 'portal-audit-destinations',
  templateUrl: './audit-destinations.component.html',
  styleUrls: ['./audit-destinations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditDestinationsComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public columnDefs: Map<string, Column<AuditDestinationElement>> = new Map();
  public tableData: Array<AuditDestinationElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'DESTINATION';
  public buttonsToShow: Array<string> = [ButtonType.ADD, ButtonType.DELETE];
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  public pageDescriptiveText = `Audit records are written for events ranging from authentication, authorisation, and API access. Configure how to receive these through an external system such as a SIEM.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/audit-destination/`;
  public agilicusAccess = false;
  public agilicusAuthorization = false;
  private agilicusDestinations: Array<AuditDestination> = [];
  private basePathLocation = '/v1/bulk_audit_events';
  private auditDestinations: Array<AuditDestination>;
  private destinationTypes = [DestinationTypeEnum.file, DestinationTypeEnum.webhook, DestinationTypeEnum.graylog];
  public connectors: Array<Connector>;
  public connectorIdToConnectorMap: Map<string, Connector> = new Map();
  public connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public customButtons: Array<TableButton> = [
    new TableScopedButton('ADD CONNECTOR DESTINATION', ButtonColor.PRIMARY, '', 'Button that creates a connector destination', () => {
      this.openAuditDestinationConnectorDialog();
    }),
  ];
  constructor(
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private auditsService: AuditsService,
    private notificationService: NotificationService,
    private appErrorHandler: AppErrorHandler,
    private connectorsService: ConnectorsService,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.basePathLocation = this.auditsService.configuration.basePath + this.basePathLocation;
    this.initializeColumnDefs();
    this.permissions$ = this.store.pipe(select(selectCanAdminAuditDestinations));
    this.getPermissionsAndData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getPermissionsAndData(): void {
    const combinedPermissionsAndData$ = this.getCombinedPermissionsAndData$();
    combinedPermissionsAndData$.pipe(takeUntil(this.unsubscribe$)).subscribe((combinedPermissionsAndDataResp) => {
      this.hasPermissions = combinedPermissionsAndDataResp?.permission?.hasPermission;
      this.auditDestinations = combinedPermissionsAndDataResp?.auditDestinations;
      this.connectors = combinedPermissionsAndDataResp?.connectors;
      this.setConnectorMaps();
      this.setColumnAllowedValues();
      if (!this.hasPermissions || !this.auditDestinations) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.setInitialData(this.auditDestinations);
      this.updateTable(this.auditDestinations);
    });
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndAuditDestinationData> {
    return this.permissions$.pipe(
      takeUntil(this.unsubscribe$),
      concatMap((hasPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasPermissionsResp?.orgId;
        let auditDestinations$: Observable<Array<AuditDestination> | undefined> = of(undefined);
        if (!!this.orgId && !!hasPermissionsResp?.hasPermission) {
          auditDestinations$ = this.getPermissionsAndData$();
        }
        let connectors$: Observable<Array<Connector> | undefined> = of(undefined);
        if (!!this.orgId && !!hasPermissionsResp?.hasPermission) {
          connectors$ = getConnectors(this.connectorsService, this.orgId);
        }
        return combineLatest([of(hasPermissionsResp), auditDestinations$, connectors$]);
      }),
      map(
        ([hasPermissionsResp, auditDestinationsResp, connectorsResp]: [
          OrgQualifiedPermission,
          Array<AuditDestination>,
          Array<Connector>
        ]) => {
          const combinedPermissionsAndAuditDestinations: CombinedPermissionsAndAuditDestinationData = {
            permission: hasPermissionsResp,
            auditDestinations: !!auditDestinationsResp ? sortArrayByMetadataCreatedDate(auditDestinationsResp) : auditDestinationsResp,
            connectors: connectorsResp,
          };
          return combinedPermissionsAndAuditDestinations;
        }
      )
    );
  }

  private getPermissionsAndData$(): Observable<Array<AuditDestination>> {
    const listAuditDestinationsRequestParams: ListAuditDestinationsRequestParams = {
      org_id: this.orgId,
    };
    return this.auditsService.listAuditDestinations(listAuditDestinationsRequestParams).pipe(
      map((resp) => {
        return resp.audit_destinations;
      }),
      catchError((e) => {
        this.notificationService.error('Failed to list audit destinations');
        return of(undefined);
      })
    );
  }

  private setConnectorMaps(): void {
    if (!this.connectors) {
      return;
    }
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of this.connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private setColumnAllowedValues(): void {
    const column = this.columnDefs.get('connector_id');
    if (!column || !this.connectors) {
      return;
    }
    column.allowedValues = ['', ...this.connectors.map((connector) => connector.spec.name)];
  }

  public checkBoxChanged(): void {
    if (this.agilicusDestinations.length === 0) {
      if (this.agilicusAccess || this.agilicusAuthorization) {
        this.createDestinationRow();
      }
      return;
    }
    if (!this.agilicusAccess && !this.agilicusAuthorization) {
      this.deleteDestination();
      return;
    }
    this.updateDestinationRow();
  }

  /**
   * Delete agilicus destination
   */
  private deleteDestination(): void {
    const destinationsToDelete = this.tableData.filter(
      (destination) =>
        destination.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
        destination.location === this.basePathLocation
    );
    if (destinationsToDelete.length === 0) {
      return;
    }
    this.deleteSelected(destinationsToDelete);
  }

  // change agilicus destination filter list
  private updateDestinationRow(): void {
    this.tableData.forEach((destination) => {
      if (
        destination.backingObject.spec.authentication.authentication_type ===
          AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
        destination.backingObject.spec.location === this.basePathLocation
      ) {
        destination.backingObject.spec.filters[0].or_list = [];
        if (this.agilicusAccess) {
          destination.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.access);
        }
        if (this.agilicusAuthorization) {
          destination.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.authorization);
        }
        this.updateExistingItem(destination);
      }
    });
  }

  // create agilicus destination
  private createDestinationRow(): void {
    const newAgilicusElement: AuditDestinationElement = this.makeEmptyTableElement();
    newAgilicusElement.backingObject.spec.destination_type = 'webhook';
    newAgilicusElement.backingObject.spec.location = this.basePathLocation;
    newAgilicusElement.backingObject.spec.name = 'admin UI';
    newAgilicusElement.backingObject.spec.filters = [
      {
        filter_type: 'subsystem',
        or_list: [],
      },
    ];
    newAgilicusElement.backingObject.spec.authentication.authentication_type =
      AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer;
    newAgilicusElement.backingObject.spec.comment = 'This destination added automatically by admin UI';

    if (this.agilicusAccess) {
      newAgilicusElement.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.access);
    }
    if (this.agilicusAuthorization) {
      newAgilicusElement.backingObject.spec.filters[0].or_list.push(AuditRecordAccessType.authorization);
    }
    this.createNewItem(newAgilicusElement);
  }

  private makeEmptyTableElement(): AuditDestinationElement {
    return {
      name: '',
      destination_type: this.destinationTypes[0],
      location: '',
      enabled: true,
      org_id: this.orgId,
      filters: [],
      comment: '',
      authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      authentication: {
        authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      },
      backingObject: {
        spec: {
          name: '',
          destination_type: this.destinationTypes[0],
          location: '',
          enabled: true,
          org_id: this.orgId,
          filters: [],
          comment: '',
          authentication: {
            authentication_type: AuditDestinationAuthentication.AuthenticationTypeEnum.none,
          },
        },
      },
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Set initial Access and Authz check boxes
   */
  private setInitialData(auditsResp: Array<AuditDestination>): void {
    this.agilicusDestinations = [];
    if (auditsResp.length === 0) {
      return;
    }
    auditsResp.forEach((destination) => {
      if (!destination.spec.authentication) {
        destination.spec.authentication = { authentication_type: 'none' };
      }
      if (
        destination.spec.authentication.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
        destination.spec.location === this.basePathLocation
      ) {
        this.agilicusDestinations.push(destination);
      }
    });
    if (this.agilicusDestinations.length === 0 || !this.filterTypeExists(this.agilicusDestinations[0].spec.filters, 'subsystem')) {
      return;
    }
    const subsystemObj = this.agilicusDestinations[0].spec.filters.find((element) => element.filter_type === 'subsystem');
    this.agilicusAccess = subsystemObj.or_list.includes(AuditRecordAccessType.access);
    this.agilicusAuthorization = subsystemObj.or_list.includes(AuditRecordAccessType.authorization);
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getNameColumn(),
        this.getTypeColumn(),
        this.getLocationColumn(),
        this.getConnectorColumn(),
        this.getAuthTypeColumn(),
        this.getAccessColumn(),
        this.getAuthColumn(),
        this.getUserNameColumn(),
        this.getPasswordColumn(),
        this.getTokenColumn(),
      ],
      this.columnDefs
    );
  }

  private getNameColumn(): Column<AuditDestinationElement> {
    const column = createInputColumn('name');
    column.displayName = 'Name';
    column.requiredField = () => true;
    column.isEditable = true;
    return column;
  }

  private getTypeColumn(): Column<AuditDestinationElement> {
    const column = createSelectColumn('destination_type');
    column.displayName = 'Type';
    column.isEditable = true;
    column.getAllowedValues = (element: OptionalAuditDestinationElement) => {
      if (element?.destination_type === DestinationTypeEnum.connector) {
        return [...this.destinationTypes, DestinationTypeEnum.connector];
      }
      return this.destinationTypes;
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    column.isReadOnly = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    return column;
  }

  private getLocationColumn(): Column<AuditDestinationElement> {
    const column = createInputColumn('location');
    column.displayName = 'Location';
    column.getDisplayValue = (element: OptionalAuditDestinationElement) => {
      if (element.destination_type !== DestinationTypeEnum.connector) {
        return element?.location;
      }
      const targetConnector = this.connectorIdToConnectorMap.get(element?.location);
      if (!!targetConnector) {
        return targetConnector.spec.name;
      }
      return element?.location;
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    column.isReadOnly = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    column.isEditable = true;
    return column;
  }

  private disableColumnEditingIfConnectorType(element: OptionalAuditDestinationElement): boolean {
    if (element?.destination_type === DestinationTypeEnum.connector) {
      return true;
    }
    return false;
  }

  private getConnectorColumn(): Column<AuditDestinationElement> {
    const column = createSelectColumn('connector_id');
    column.displayName = 'Via Connector';
    column.getDisplayValue = (element: OptionalAuditDestinationElement) => {
      const targetConnector = this.connectorIdToConnectorMap.get(element.connector_id);
      if (!!targetConnector) {
        return targetConnector.spec.name;
      }
      return '';
    };
    column.getAllowedValues = (element: OptionalAuditDestinationElement) => {
      if (!!element?.connector_id) {
        // Prevent clearing the connector when one has been set
        return column.allowedValues.filter((value) => !!value);
      }
      return column.allowedValues;
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    return column;
  }

  private getAuthTypeColumn(): Column<AuditDestinationElement> {
    const column = createSelectColumn('authentication_type');
    column.displayName = 'Authentication Type';
    column.isEditable = true;
    column.allowedValues = [
      AuditDestinationAuthentication.AuthenticationTypeEnum.none,
      AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic,
      AuditDestinationAuthentication.AuthenticationTypeEnum.http_bearer,
    ];
    column.getOptionDisplayValue = (option: AuditDestinationAuthentication.AuthenticationTypeEnum) => {
      return capitalizeFirstLetter(replaceCharacterWithSpace(option, '_'));
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    return column;
  }

  private getAccessColumn(): Column<AuditDestinationElement> {
    const column = createCheckBoxColumn('access');
    column.displayName = 'Access';
    column.isEditable = true;
    column.isChecked = (element: AuditDestinationElement) => {
      return element.access;
    };
    return column;
  }

  private getAuthColumn(): Column<AuditDestinationElement> {
    const column = createCheckBoxColumn('authorization');
    column.displayName = 'Auth';
    column.isEditable = true;
    column.isChecked = (element: AuditDestinationElement) => {
      return element.authorization;
    };
    return column;
  }

  private getUserNameColumn(): Column<AuditDestinationElement> {
    const column = createInputColumn('username');
    column.isEditable = true;
    column.isValidEntry = (value: string, element: OptionalAuditDestinationElement): boolean => {
      if (element.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic && !value) {
        return false;
      }
      return true;
    };
    column.requiredField = (element: OptionalAuditDestinationElement) => {
      if (element?.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic) {
        return true;
      }
      return false;
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    return column;
  }

  private getPasswordColumn(): Column<AuditDestinationElement> {
    const column = createInputColumn('password');
    column.displayName = 'Password';
    column.isEditable = true;
    column.isValidEntry = (value: string, element: OptionalAuditDestinationElement): boolean => {
      if (element.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic && !value) {
        return false;
      }
      return true;
    };
    column.requiredField = (element: OptionalAuditDestinationElement) => {
      if (element?.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_basic) {
        return true;
      }
      return false;
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    return column;
  }

  private getTokenColumn(): Column<AuditDestinationElement> {
    const column = createInputColumn('token');
    column.displayName = 'Token';
    column.isEditable = true;
    column.isValidEntry = (value: string, element: OptionalAuditDestinationElement): boolean => {
      if (element.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_bearer && !value) {
        return false;
      }
      return true;
    };
    column.requiredField = (element: OptionalAuditDestinationElement) => {
      if (element?.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.http_bearer) {
        return true;
      }
      return false;
    };
    column.disableField = (element: OptionalAuditDestinationElement) => {
      return this.disableColumnEditingIfConnectorType(element);
    };
    return column;
  }

  private buildData(data: Array<AuditDestination>): void {
    const dataForTable: Array<AuditDestinationElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private getTableElementConnectorId(auditDestination: AuditDestination): string {
    const connectorFilter = !!auditDestination.spec.filters
      ? auditDestination.spec.filters.find((element) => element.filter_type === 'audit_agent_id')
      : undefined;
    if (!!connectorFilter && connectorFilter.or_list.length !== 0) {
      return connectorFilter.or_list[0];
    }
    return '';
  }

  private getTableElementSubsystemFilters(auditDestination: AuditDestination): Array<string> {
    const subsystemFilter = !!auditDestination.spec.filters
      ? auditDestination.spec.filters.find((element) => element.filter_type === 'subsystem')
      : undefined;
    if (!!subsystemFilter && subsystemFilter.or_list.length !== 0) {
      return subsystemFilter.or_list;
    }
    return [];
  }

  private createTableElement(auditDestination: AuditDestination, index: number): AuditDestinationElement {
    const data: AuditDestinationElement = {
      name: auditDestination.spec?.name,
      destination_type: auditDestination.spec?.destination_type,
      location: auditDestination.spec?.location,
      enabled: auditDestination.spec?.enabled,
      org_id: auditDestination.spec?.org_id,
      filters: auditDestination.spec?.filters,
      comment: auditDestination.spec?.comment,
      authentication_type: auditDestination.spec?.authentication?.authentication_type,
      username: auditDestination.spec?.authentication?.http_basic?.username,
      password: auditDestination.spec?.authentication?.http_basic?.password,
      token: auditDestination.spec?.authentication?.http_bearer?.token,
      connector_id: this.getTableElementConnectorId(auditDestination),
      backingObject: auditDestination,
      access: false,
      authorization: false,
      ...getDefaultTableProperties(index),
    };
    if (auditDestination.spec.filters.length > 0) {
      const subsystemFiltersList = this.getTableElementSubsystemFilters(auditDestination);
      data.access = subsystemFiltersList.includes(AuditRecordAccessType.access) ? true : false;
      data.authorization = subsystemFiltersList.includes(AuditRecordAccessType.authorization) ? true : false;
    }
    if (
      data.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
      data.location === this.basePathLocation
    ) {
      data.showRow = false;
    }
    return data;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private updateTable(data: Array<AuditDestination>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: AuditDestinationElement): void {
    this.saveItem(updatedElement);
  }

  private saveItem(tableElement: AuditDestinationElement): void {
    if (tableElement.index === -1) {
      this.createNewItem(tableElement);
    } else {
      this.updateExistingItem(tableElement);
    }
  }

  private getItemFromTableElement(tableElement: AuditDestinationElement): AuditDestination {
    let result: AuditDestination = tableElement.backingObject;
    if (
      result.spec.authentication.authentication_type === AuditDestinationAuthentication.AuthenticationTypeEnum.agilicus_bearer &&
      result.spec.location === this.basePathLocation
    ) {
      return result;
    }
    result.spec.name = tableElement.name.trim();
    result.spec.destination_type = tableElement.destination_type;
    result.spec.location = tableElement.location.trim();
    result.spec.authentication.authentication_type = tableElement.authentication_type;
    result.spec.enabled = tableElement.enabled;
    result.spec.org_id = tableElement.org_id;
    result.spec.filters = tableElement.filters;
    result.spec.comment = tableElement.comment;
    if (!!result.spec.authentication.http_basic) {
      result.spec.authentication.http_basic.username = tableElement?.username ? tableElement?.username.trim() : '';
      result.spec.authentication.http_basic.password = tableElement?.password ? tableElement?.password : '';
    }
    if (!!result.spec.authentication.http_bearer) {
      result.spec.authentication.http_bearer.token = tableElement?.token ? tableElement?.token.trim() : '';
    }
    if ((tableElement.username || tableElement.password) && !result.spec.authentication.http_basic) {
      result.spec.authentication.http_basic = {
        username: tableElement?.username ? tableElement?.username.trim() : '',
        password: tableElement?.password ? tableElement?.password : '',
      };
    }
    if (tableElement.token && !result.spec.authentication.http_bearer) {
      result.spec.authentication.http_bearer = { token: tableElement?.token ? tableElement?.token.trim() : '' };
    }
    result = this.setAuditDestinationSubsystemFilter(result, tableElement);
    result = this.setAuditDestinationConnectorFilter(result, tableElement);
    return result;
  }

  private setAuditDestinationSubsystemFilter(auditDestination: AuditDestination, tableElement: AuditDestinationElement): AuditDestination {
    let filter: AuditDestinationFilter;
    if (auditDestination.spec.filters.length > 0) {
      filter = auditDestination.spec.filters.find((element) => element.filter_type === 'subsystem');
      if (!filter) {
        filter = {
          filter_type: 'subsystem',
          or_list: [],
        };
        auditDestination.spec.filters.push(filter);
      } else {
        filter.or_list = [];
      }
    } else {
      auditDestination.spec.filters = [
        {
          filter_type: 'subsystem',
          or_list: [],
        },
      ];
      filter = auditDestination.spec.filters[0];
    }
    if (tableElement.access) {
      filter.or_list.push(AuditRecordAccessType.access);
    }
    if (tableElement.authorization) {
      filter.or_list.push(AuditRecordAccessType.authorization);
    }
    return auditDestination;
  }

  private setAuditDestinationConnectorFilter(auditDestination: AuditDestination, tableElement: AuditDestinationElement): AuditDestination {
    if (tableElement.destination_type === DestinationTypeEnum.connector) {
      return auditDestination;
    }
    let filter: AuditDestinationFilter;
    if (auditDestination.spec.filters.length > 0) {
      filter = auditDestination.spec.filters.find((element) => element.filter_type === 'audit_agent_id');
      if (!filter) {
        filter = {
          filter_type: 'audit_agent_id',
          or_list: [],
        };
        auditDestination.spec.filters.push(filter);
      } else {
        filter.or_list = [];
      }
    } else {
      auditDestination.spec.filters = [
        {
          filter_type: 'audit_agent_id',
          or_list: [],
        },
      ];
      filter = auditDestination.spec.filters[0];
    }
    if (tableElement.connector_id && tableElement.destination_type !== DestinationTypeEnum.connector) {
      filter.or_list.push(tableElement.connector_id);
    }
    return auditDestination;
  }

  private filterTypeExists(arr: Array<AuditDestinationFilter>, value: string): boolean {
    return arr.some((el) => {
      return el.filter_type === value;
    });
  }

  private postItem(itemToCreate: AuditDestination): Observable<AuditDestination> {
    const createRequestParams: CreateAuditDestinationRequestParams = {
      AuditDestination: itemToCreate,
    };
    return this.auditsService.createAuditDestination(createRequestParams);
  }

  private doAuditConnectorServiceCheck$(tableElement: AuditDestinationElement): Observable<ConnectorService | undefined> {
    let auditConnectorService$: Observable<ConnectorService | undefined> = of(undefined);
    const connectorId = tableElement.location;
    const targetConnector = this.connectorIdToConnectorMap.get(connectorId);
    if (tableElement.destination_type === DestinationTypeEnum.connector && !!targetConnector) {
      auditConnectorService$ = createAuditConnectorService$(this.connectorsService, connectorId, this.orgId);
    }
    return auditConnectorService$;
  }

  private createNewItem(newTableElement: AuditDestinationElement): void {
    const newItem = this.getItemFromTableElement(newTableElement);
    const auditConnectorService$ = this.doAuditConnectorServiceCheck$(newTableElement);
    const result$ = auditConnectorService$.pipe(
      concatMap((_) => {
        return this.postItem(newItem);
      })
    );
    result$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (postResp) => {
        this.notificationService.success(`Destination "${postResp.spec.name}" was successfully created`);
      },
      (errorResp) => {
        const baseMessage = `Failed to create Destination "${newTableElement.backingObject.spec.name}"`;
        this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
      },
      () => {
        this.getPermissionsAndData();
      }
    );
  }

  private updateExistingItem(updatedTableElement: AuditDestinationElement): void {
    const updatedItem = this.getItemFromTableElement(updatedTableElement);
    const auditConnectorService$ = this.doAuditConnectorServiceCheck$(updatedTableElement);
    const result$ = auditConnectorService$.pipe(
      concatMap((_) => {
        return this.putItem(updatedItem);
      })
    );
    result$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (putResp) => {
        updatedTableElement.backingObject = putResp;
        this.notificationService.success(`Destination "${putResp.spec.name}" was successfully updated`);
      },
      (errorResp) => {
        const baseMessage = `Failed to update Destination "${updatedTableElement.backingObject.spec.name}"`;
        this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
      },
      () => {
        this.getPermissionsAndData();
      }
    );
  }

  private putItem(itemToUpdate: AuditDestination): Observable<AuditDestination> {
    const getter = (item: AuditDestination) => {
      const getRequestParams: GetAuditDestinationRequestParams = {
        destination_id: item.metadata.id,
        org_id: this.orgId,
      };
      return this.auditsService.getAuditDestination(getRequestParams);
    };
    const putter = (item: AuditDestination) => {
      const replaceRequestParams: ReplaceAuditDestinationRequestParams = {
        destination_id: item.metadata.id,
        AuditDestination: item,
      };
      return this.auditsService.replaceAuditDestination(replaceRequestParams);
    };
    return patch_via_put(itemToUpdate, getter, putter);
  }

  public deleteSelected(itemsToDelete: Array<AuditDestinationElement>): void {
    const observablesArray: Array<Observable<object>> = [];
    for (const item of itemsToDelete) {
      if (item.index === -1) {
        continue;
      }
      observablesArray.push(
        this.auditsService.deleteAuditDestination({
          destination_id: item.backingObject.metadata.id,
          org_id: this.orgId,
        })
      );
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Destinations were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected destinations');
        },
        () => {
          this.getPermissionsAndData();
        }
      );
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<AuditDestinationElement>; element: AuditDestinationElement }): void {
    if (
      params.column.name !== 'authentication_type' &&
      params.column.name !== 'destination_type' &&
      params.column.name !== 'connector_id'
    ) {
      return;
    }
    if (params.column.name === 'authentication_type') {
      const isAuthenticationTypeEnum = createEnumChecker(AuditDestinationAuthentication.AuthenticationTypeEnum);
      if (isAuthenticationTypeEnum(params.value)) {
        params.element.authentication_type = params.value;
      }
    } else if (params.column.name === 'destination_type') {
      const selectedType = params.value;
      params.element.destination_type = selectedType;
    } else if (params.column.name === 'connector_id') {
      const targetConnector = this.connectorNameToConnectorMap.get(params.value);
      params.element.connector_id = targetConnector?.metadata?.id ? targetConnector.metadata.id : '';
    }
  }

  public openAuditDestinationConnectorDialog(): void {
    const dialogData: AuditDestinationConnectorDialogData = {
      orgId: this.orgId,
      connectors: this.connectors,
    };
    const dialogRef = this.dialog.open(
      AuditDestinationConnectorDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((auditDestinationToCreate: AuditDestinationElement | undefined) => {
        if (!!auditDestinationToCreate) {
          this.updateEvent(auditDestinationToCreate);
        }
      });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this), ['destination_type', 'authentication_type']);
  }
}
