import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import {
  User,
  UsersService,
  ResetUserMfaChallengeMethodsRequestParams,
  ResetMFAChallengeMethod,
  UserStatusEnum,
  ListCombinedUserDetailsRequestParams,
  Organisation,
  ListUserMetadataRequestParams,
  ListUserMetadataResponse,
  UserMetadataSpec,
  ReplaceUserMetadataRequestParams,
  CreateUserMetadataRequestParams,
  UserMetadata,
  OrganisationsService,
  UsageMetrics,
  TokensService,
  BulkTokenRevokeResponse,
} from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, forkJoin, combineLatest, EMPTY, of } from 'rxjs';
import { concatMap, expand, map, reduce, take, takeUntil } from 'rxjs/operators';
import { Group } from '@agilicus/angular';
import { getMfaEnrollmentExpiryFromUserMetadata, sortArrayByKey } from '@app/shared/components/utils';
import { PaginatorActions, PaginatorConfig, UpdateTableParams } from '../table-paginator/table-paginator.component';
import { TableElement } from '../table-layout/table-element';
import { UsersResp, UsersToGroupsService, UserWithDetail } from '@app/core/api/users-to-groups/users-to-groups.service';

import { FilterManager, CheckboxOption } from '../filter/filter-manager';
import {
  Column,
  createIconColumn,
  createSelectRowColumn,
  createInputColumn,
  createChipListColumn,
  setColumnDefs,
  createActionsColumn,
  ActionMenuOptions,
} from '../table-layout/column-definitions';
import { cloneDeep } from 'lodash-es';
import { ButtonType } from '../button-type.enum';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { OptionalUserWithDetail } from '../optional-types';
import { ButtonColor, RowScopedButton, TableButton } from '../buttons/table-button/table-button.component';
import { selectOrganisations } from '@app/core/organisations/organisations.selectors';
import { getMfaEnrollmentExpiryDateString } from '../utils';
import { ParamSpecificSearchOptions } from '../param-specific-search-options.enum';
import { UsersFilterLabel } from '../user-filter-label.enum';
import { FilterType } from '../filter-type.enum';
import { ParamSpecificFilterManager } from '../param-specific-filter-manager/param-specific-filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { getEmailDomains$ } from '@app/core/api/organisations/organisations-api.utils';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { HttpErrorResponse } from '@angular/common/http';
import { UserIdentityDialogComponent, UserIdentityDialogData } from '../user-identity-dialog/user-identity-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { createDialogData, getDefaultDialogConfig } from '../dialog-utils';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { UserEndDateDialogComponent, UserEndDateDialogData } from '../user-end-date-dialog/user-end-date-dialog.component';
import { checkIfUserEmailFieldValid } from '../validation-utils';
import {
  canReadBillingData,
  doNotNeedPaymentNag,
  getPaymentDialogEndMessage,
  getStarterPaymentDialogMessageBeginning,
  getStarterPlanMaxUsers,
  getSubscriptionButtonText,
  isStarterPlanWithoutPayment,
} from '@app/core/billing-state/billing-api-utils';
import { EventsService } from '@app/core/services/events.service';
import { initBillingAccountFull } from '@app/core/billing-state/billing-account-full.actions';
import {
  selectBillingAccountFullDefaultPaymentMethod,
  selectBillingAccountFullIsStarterPlan,
  selectBillingAccountFullRefreshDataValue,
} from '@app/core/billing-state/billing-account-full.selectors';
import { PaymentDialogComponent, PaymentDialogData } from '../payment-dialog/payment-dialog.component';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { bulkRevokeUserSessionAndTokens$, getRevokeSessionsButton } from '@app/core/api/token-api-utils';
import { selectUser } from '@app/core/user/user.selectors';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '@app/core/services/auth-service.service';
import { OrganisationsState } from '@app/core/organisations/organisations.models';

export interface UserElement extends TableElement, UserWithDetail, Description {}

export interface Description {
  description?: string;
}

export interface CombinedPermissionsAndData {
  permission: OrgQualifiedPermission;
  emailDomains: Array<string>;
  paymentMethod: string | undefined | null;
  isStarterPlan: boolean | undefined;
  usageMetrics: UsageMetrics;
  queryParamMap: ParamMap;
  orgState: OrganisationsState;
  currentUser: User;
  refreshBillingDataState: number;
}

export enum UsersCheckboxOptions {
  ACTIVE = 'Hide active users',
  DISABLED = 'Hide disabled users',
  PENDING = 'Hide pending users',
  MFAON = 'Show only users with MFA enrolled',
  MFAOFF = 'Show only users without MFA enrolled',
  AUTOCREATED = 'Show only auto-created users',
  NONAUTOCREATED = 'Show only manually added users',
}

export enum UpdateAction {
  DELETE = 'delete',
  DISABLE = 'disable',
  ENABLE = 'enable',
  RESET = 'reset',
  MOVE = 'move',
}

@Component({
  selector: 'portal-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private usersAndGroups$: Observable<[UsersResp, Array<Group>]>;
  public columnDefs: Map<string, Column<UserElement>> = new Map();
  private orgId: string;
  private currentUser: User;
  public tableData: Array<UserElement> = [];
  public firstNameToGroupMap: Map<string, User> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public paramSpecificFilterManager: ParamSpecificFilterManager = new ParamSpecificFilterManager();
  public rowObjectName = 'USER';
  public emailToUserMap: Map<string, User> = new Map();
  public hideActiveUsers = false;
  public hideDisabledUsers = true;
  public hidePendingUsers = true;
  public showOnlyNonMFAUsers = false;
  public showOnlyMFAUsers = false;
  public showOnlyAutoCreatedUsers = false;
  public showOnlyNonAutoCreatedUsers = false;
  public linkDataSource = false;
  public paginatorConfig = new PaginatorConfig<UserElement>(true, true, 25, 5, new PaginatorActions<UserElement>(), 'email', {
    previousKey: '',
    nextKey: '',
    previousWindow: [],
  });
  public useBackendFilter = true;
  public hasUsersPermissions: boolean;
  private currentOrg: Organisation;
  public resetButtonTooltipText = `Click to reset the selected user's multi-factor authentication preferences. This will also reset the selected user's enrollment deadline to the organisation's default.`;
  public buttonsToShow: Array<string> = [ButtonType.ADD, ButtonType.DELETE, ButtonType.DISABLE, ButtonType.ENABLE];
  public customButtons: Array<TableButton> = [
    new RowScopedButton(
      'RESET MFA PREFERENCES',
      ButtonColor.WARN,
      this.resetButtonTooltipText,
      `Reset the selected user's multi-factor authentication preferences`,
      () => 'Please select users to reset',
      `Button container that displays a tooltip when the reset user's multi-factor authentication preferences button is disabled`,
      (usersToReset: Array<UserElement>) => {
        this.resetSelected(usersToReset);
      }
    ),
    new RowScopedButton(
      'MOVE TO MANUALLY CREATED USERS',
      ButtonColor.WARN,
      'Move auto-created users to the all-users group',
      'Move auto-created users to the all-users group',
      () => 'Please select users to move to the all-users group',
      'Button container that displays a tooltip when the move to manually created users button is disabled',
      (usersToMove: Array<UserElement>) => {
        this.moveSelectedFromAutoCreate(usersToMove);
      }
    ),
    getRevokeSessionsButton<UserElement>('user', this.onRevokeSelected.bind(this)),
  ];
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public pageDescriptiveText = `A user is a person, authenticated against an external identity provider, who you may grant some permissions within the Agilicus system`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/users/`;
  private emailDomains: Array<string> = [];
  private userEmailToFilter: string | undefined = undefined;
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'status',
      {
        name: 'status',
        displayName: UsersFilterLabel.USER,
        icon: 'person_add_disabled',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'mfa',
      {
        name: 'mfa',
        displayName: UsersFilterLabel.MFAON,
        icon: 'check',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'auto_created_status',
      {
        name: 'auto_created_status',
        displayName: UsersFilterLabel.AUTOCREATED,
        icon: 'autorenew',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      ParamSpecificSearchOptions.EMAIL_DOMAIN,
      {
        name: ParamSpecificSearchOptions.EMAIL_DOMAIN,
        displayName: UsersFilterLabel.EMAIL_DOMAIN,
        icon: 'alternate_email',
        type: FilterMenuOptionType.dropdown,
        allowedValues: () => [],
      },
    ],
    [
      ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH,
      {
        name: ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH,
        displayName: UsersFilterLabel.EMAIL,
        icon: 'email',
        type: FilterMenuOptionType.text,
      },
    ],
    [
      ParamSpecificSearchOptions.LAST_NAME,
      {
        name: ParamSpecificSearchOptions.LAST_NAME,
        displayName: UsersFilterLabel.LAST_NAME,
        icon: 'person_search',
        type: FilterMenuOptionType.text,
      },
    ],
    [
      ParamSpecificSearchOptions.FIRST_NAME,
      {
        name: ParamSpecificSearchOptions.FIRST_NAME,
        displayName: UsersFilterLabel.FIRST_NAME,
        icon: 'person_search',
        type: FilterMenuOptionType.text,
      },
    ],
  ]);
  private paymentMethod: string | undefined | null;
  private isStarterPlan = undefined;
  private dialogOpen = false;
  /**
   * Determines whether a user is permitted to add further users
   */
  public preventAddUser = false;
  public fetchedData = false;
  private checkedPaymentStatus = false;
  private usageMetrics: UsageMetrics;
  private queryParamMap: ParamMap;
  private refreshBillingDataStateValue = 0;
  private reloadTableCount = 0;

  public getStarterPlanMaxUsers = getStarterPlanMaxUsers;

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<UserElement>;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private usersToGroupsService: UsersToGroupsService,
    private appErrorHandler: AppErrorHandler,
    private usersService: UsersService,
    private organisationsService: OrganisationsService,
    private dialog: MatDialog,
    private eventsService: EventsService,
    public paymentDialog: MatDialog,
    public route: ActivatedRoute,
    private tokensService: TokensService,
    private authService: AuthService
  ) {
    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.ACTIVE,
      displayName: UsersCheckboxOptions.ACTIVE,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleActiveUsers.bind(this),
    });

    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.DISABLED,
      displayName: UsersCheckboxOptions.DISABLED,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleDisabledUsers.bind(this),
    });

    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.PENDING,
      displayName: UsersCheckboxOptions.PENDING,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.togglePendingUsers.bind(this),
    });

    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.MFAON,
      displayName: UsersCheckboxOptions.MFAON,
      label: this.filterMenuOptions.get('mfa').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleMFAOnUsers.bind(this),
    });

    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.MFAOFF,
      displayName: UsersCheckboxOptions.MFAOFF,
      label: this.filterMenuOptions.get('mfa').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleMFAOffUsers.bind(this),
    });

    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.AUTOCREATED,
      displayName: UsersCheckboxOptions.AUTOCREATED,
      label: this.filterMenuOptions.get('auto_created_status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleAutoCreatedUsers.bind(this),
    });

    this.filterManager.addCheckboxFilterOption({
      name: UsersCheckboxOptions.NONAUTOCREATED,
      displayName: UsersCheckboxOptions.NONAUTOCREATED,
      label: this.filterMenuOptions.get('auto_created_status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleNonAutoCreatedUsers.bind(this),
    });

    this.paramSpecificFilterManager.addParamSpecificSearchFilterOption({
      name: ParamSpecificSearchOptions.EMAIL_DOMAIN,
      displayName: '',
      label: this.filterMenuOptions.get(ParamSpecificSearchOptions.EMAIL_DOMAIN).displayName,
      type: FilterType.PARAM_SPECIFIC_SEARCH,
      doParamSpecificSearchFilter: this.reloadFirstPage.bind(this),
    });

    this.paramSpecificFilterManager.addParamSpecificSearchFilterOption({
      name: ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH,
      displayName: '',
      label: this.filterMenuOptions.get(ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH).displayName,
      type: FilterType.PARAM_SPECIFIC_SEARCH,
      doParamSpecificSearchFilter: this.reloadFirstPage.bind(this),
    });

    this.paramSpecificFilterManager.addParamSpecificSearchFilterOption({
      name: ParamSpecificSearchOptions.LAST_NAME,
      displayName: '',
      label: this.filterMenuOptions.get(ParamSpecificSearchOptions.LAST_NAME).displayName,
      type: FilterType.PARAM_SPECIFIC_SEARCH,
      doParamSpecificSearchFilter: this.reloadFirstPage.bind(this),
    });

    this.paramSpecificFilterManager.addParamSpecificSearchFilterOption({
      name: ParamSpecificSearchOptions.FIRST_NAME,
      displayName: '',
      label: this.filterMenuOptions.get(ParamSpecificSearchOptions.FIRST_NAME).displayName,
      type: FilterType.PARAM_SPECIFIC_SEARCH,
      doParamSpecificSearchFilter: this.reloadFirstPage.bind(this),
    });
    // Turn these filters on when the page loads:
    this.setPendingUserFilter();
    this.setDisabledUserFilter();
  }

  public ngOnInit(): void {
    this.store.dispatch(initBillingAccountFull({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.getCombinedPermissionsAndData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((combinedPermissionsAndDataResp) => {
        this.orgId = combinedPermissionsAndDataResp?.permission?.orgId;
        this.hasUsersPermissions = combinedPermissionsAndDataResp?.permission.hasPermission;
        this.currentOrg = combinedPermissionsAndDataResp?.orgState?.current_organisation;
        this.currentUser = combinedPermissionsAndDataResp?.currentUser;
        this.refreshBillingDataStateValue = combinedPermissionsAndDataResp?.refreshBillingDataState;
        this.queryParamMap = combinedPermissionsAndDataResp.queryParamMap;
        if (!this.orgId || !this.hasUsersPermissions) {
          // Need this in order for the "No Permissions" text to be displayed when the page first loads.
          this.changeDetector.detectChanges();
          return;
        }
        this.paymentMethod = combinedPermissionsAndDataResp?.paymentMethod;
        this.isStarterPlan = combinedPermissionsAndDataResp.isStarterPlan;
        this.usageMetrics = combinedPermissionsAndDataResp?.usageMetrics;
        if (!!combinedPermissionsAndDataResp?.emailDomains) {
          this.emailDomains = combinedPermissionsAndDataResp?.emailDomains;
          this.filterMenuOptions.get(ParamSpecificSearchOptions.EMAIL_DOMAIN).allowedValues = () => {
            return ['', ...this.emailDomains];
          };
        }
        if (!this.usersAndGroups$) {
          // we are fetching data for the first time
          this.updateTable();
          this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
            this.reloadTableCount = this.reloadTableCount + 1;
            if (this.reloadTableCount === 1) {
              // This will prevent the table from immediately reloading when first loading the page.
              return;
            }
            this.updateTable(params.key, params.searchDirection, params.limit);
          });
        }
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getNumberOfUsersFromMetrics(): number | undefined {
    if (!this.usageMetrics) {
      return undefined;
    }
    for (const metric of this.usageMetrics.metrics) {
      if (metric.type === 'user') {
        return metric.active?.current;
      }
    }
    return undefined;
  }

  private doPaymentChecks(): void {
    if (!this.currentOrg) {
      // Do not have required data for checks.
      return;
    }
    if (doNotNeedPaymentNag(this.paymentMethod, this.isStarterPlan, this.currentOrg, this.refreshBillingDataStateValue)) {
      this.checkedPaymentStatus = true;
      this.preventAddUser = false;
      return;
    }
    if (!this.queryParamMap || this.isStarterPlan === undefined) {
      // Do not have required data for checks.
      return;
    }
    const refreshBillingParam = this.queryParamMap.get('refresh_billing');
    if (!!refreshBillingParam && this.refreshBillingDataStateValue === 0) {
      // Need to get new data from the api before doing the check.
      return;
    }
    if (this.fetchedData) {
      // Have retrieved the required data.
      if (this.shouldShowPaymentDialog()) {
        this.preventAddUser = true;
      } else {
        this.preventAddUser = false;
      }
      this.checkedPaymentStatus = true;
    }
  }

  private doPaymentChecksAndDetectChanges(): void {
    this.doPaymentChecks();
    this.changeDetector.detectChanges();
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndData> {
    const permissions$ = this.store.pipe(select(selectCanAdminUsers));
    const orgState$ = this.store.pipe(select(selectOrganisations));
    const defaultPaymentMethod$ = this.store.pipe(select(selectBillingAccountFullDefaultPaymentMethod));
    const isStarterPlan$ = this.store.pipe(select(selectBillingAccountFullIsStarterPlan));
    const refreshBillingDataState$ = this.store.pipe(select(selectBillingAccountFullRefreshDataValue));
    const currentUser$ = this.store.pipe(select(selectUser));
    return combineLatest([
      this.route.queryParamMap,
      permissions$,
      orgState$,
      defaultPaymentMethod$,
      isStarterPlan$,
      refreshBillingDataState$,
      currentUser$,
    ]).pipe(
      concatMap(
        ([
          queryParamMapResp,
          hasPermissionsResp,
          orgStateResp,
          defaultPaymentMethodResp,
          isStarterPlanResp,
          refreshBillingDataStateResp,
          currentUserResp,
        ]) => {
          let emailDomains$: Observable<Array<string>> = of([]);
          let usageMetrics$: Observable<UsageMetrics> | undefined = of(undefined);
          if (!!hasPermissionsResp?.orgId && !!hasPermissionsResp?.hasPermission) {
            emailDomains$ = getEmailDomains$(this.organisationsService, hasPermissionsResp.orgId);
            usageMetrics$ = this.organisationsService.getUsageMetrics({ org_id: hasPermissionsResp.orgId });
          }
          return combineLatest([
            of(queryParamMapResp),
            of(hasPermissionsResp),
            of(orgStateResp),
            emailDomains$,
            usageMetrics$,
            of(defaultPaymentMethodResp),
            of(isStarterPlanResp),
            of(refreshBillingDataStateResp),
            of(currentUserResp),
          ]);
        }
      ),
      map(
        ([
          queryParamMapResp,
          hasPermissionsResp,
          orgStateResp,
          emailDomainsResp,
          usageMetricsResp,
          defaultPaymentMethodResp,
          isStarterPlanResp,
          refreshBillingDataStateResp,
          currentUserResp,
        ]: [
          ParamMap,
          OrgQualifiedPermission,
          OrganisationsState,
          Array<string>,
          UsageMetrics,
          string | undefined | null,
          boolean | undefined,
          number,
          User
        ]) => {
          const combinedPermissionsAndData: CombinedPermissionsAndData = {
            queryParamMap: queryParamMapResp,
            permission: hasPermissionsResp,
            emailDomains: emailDomainsResp,
            paymentMethod: defaultPaymentMethodResp,
            isStarterPlan: isStarterPlanResp,
            usageMetrics: usageMetricsResp,
            orgState: orgStateResp,
            currentUser: currentUserResp,
            refreshBillingDataState: refreshBillingDataStateResp,
          };
          return combinedPermissionsAndData;
        }
      )
    );
  }

  private getStatusColumn(): Column<UserElement> {
    const statusColumn = createIconColumn('status');
    statusColumn.getDisplayValue = (element: OptionalUserWithDetail) => {
      if (element.status === UserStatusEnum.active) {
        return 'person';
      }
      if (element.status === UserStatusEnum.disabled) {
        return 'person_add_disabled';
      }
      if (element.status === UserStatusEnum.pending) {
        return 'pending_actions';
      }
      return '';
    };
    statusColumn.getTooltip = (element: UserElement) => {
      if (element.status === UserStatusEnum.active) {
        return 'Active User';
      }
      if (element.status === UserStatusEnum.disabled) {
        return 'Disabled User';
      }
      if (element.status === UserStatusEnum.pending) {
        return 'Pending User';
      }
      return '';
    };
    return statusColumn;
  }

  private getMfaColumn(): Column<UserElement> {
    const mfaColumn = createIconColumn('mfa');
    mfaColumn.displayName = 'MFA';
    mfaColumn.getDisplayValue = (element: OptionalUserWithDetail) => {
      if (element.mfaEnabled) {
        return 'verified_user';
      }
      if (element.mfaEnrolled) {
        return 'check';
      }
      return '';
    };
    mfaColumn.getTooltip = (element: UserElement) => {
      if (element.mfaEnabled) {
        return 'Multi-Factor Enabled';
      }
      if (element.mfaEnrolled) {
        return 'Multi-Factor Enrolled';
      }
      return 'No Multi-Factor Setup';
    };
    return mfaColumn;
  }

  private getEmailColumn(): Column<UserElement> {
    const emailColumn = createInputColumn('email');
    emailColumn.requiredField = () => true;
    emailColumn.isEditable = true;
    emailColumn.isUnique = true;
    emailColumn.isValidEntry = (item: string) => {
      return checkIfUserEmailFieldValid(item);
    };
    emailColumn.disableField = (element: UserElement): boolean => {
      return element.email !== '' && !element.isNew;
    };
    return emailColumn;
  }

  private getFirstNameColumn(): Column<UserElement> {
    const firstNameColumn = createInputColumn('first_name');
    firstNameColumn.displayName = 'First Name';
    firstNameColumn.isEditable = true;
    firstNameColumn.isCaseSensitive = true;
    return firstNameColumn;
  }

  private getLastNameColumn(): Column<UserElement> {
    const lastNameColumn = createInputColumn('last_name');
    lastNameColumn.displayName = 'Last Name';
    lastNameColumn.isEditable = true;
    lastNameColumn.isCaseSensitive = true;
    return lastNameColumn;
  }

  private getDescriptionColumn(): Column<UserElement> {
    const descriptionColumn = createInputColumn('description');
    descriptionColumn.displayName = 'Description';
    descriptionColumn.isEditable = true;
    descriptionColumn.isCaseSensitive = true;
    return descriptionColumn;
  }

  private getExternalIdColumn(): Column<UserElement> {
    const externalIdColumn = createInputColumn('external_id');
    externalIdColumn.displayName = 'External Id';
    externalIdColumn.isEditable = true;
    externalIdColumn.isCaseSensitive = true;
    return externalIdColumn;
  }

  private getMemberOfColumn(): Column<UserElement> {
    const memberOfColumn = createChipListColumn('member_of');
    memberOfColumn.getDisplayValue = (member_of: Group) => {
      return member_of.first_name;
    };
    memberOfColumn.getElementFromValue = (first_name: string): any => {
      return this.firstNameToGroupMap.get(first_name);
    };
    return memberOfColumn;
  }

  private getActionsColumn(): Column<UserElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<UserElement>> = [
      {
        displayName: 'Update User Identity',
        icon: 'perm_identity',
        tooltip: `Click to update the user's identity`,
        onClick: (element: UserElement) => {
          // open dialog:
          const dialogData: UserIdentityDialogData = {
            userElement: element,
            orgId: element.org_id,
          };
          const dialogRef = this.dialog.open(
            UserIdentityDialogComponent,
            getDefaultDialogConfig({
              data: dialogData,
              maxWidth: '550px',
            })
          );
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.reloadWindow();
            }
          });
        },
      },
      {
        displayName: 'Update User End Date',
        icon: 'calendar_today',
        tooltip: `Click to update the user's end date`,
        onClick: (element: UserElement) => {
          // open dialog:
          const dialogData: UserEndDateDialogData = {
            userElement: element,
          };
          const dialogRef = this.dialog.open(
            UserEndDateDialogComponent,
            getDefaultDialogConfig({
              data: dialogData,
              maxWidth: '550px',
            })
          );
          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              this.reloadWindow();
            }
          });
        },
      },
      {
        displayName: 'Reset User Multi-factor Authentication Preferences',
        icon: 'refresh',
        tooltip: this.resetButtonTooltipText,
        onClick: (element: UserElement) => {
          this.resetSelected([element]);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getStatusColumn(),
        this.getMfaColumn(),
        this.getEmailColumn(),
        this.getFirstNameColumn(),
        this.getLastNameColumn(),
        this.getDescriptionColumn(),
        this.getExternalIdColumn(),
        this.getMemberOfColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private getEmptyTableApiResponse(): Observable<[UsersResp, Array<Group>]> {
    return of([{ users: [], nextPageEmail: '', previousPageEmail: '' }, []]);
  }

  public updateTable(
    emailKey = '',
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    // Clear the maps when data reloaded
    this.emailToUserMap.clear();
    this.firstNameToGroupMap.clear();
    const initialParams = this.getParams(emailKey, searchDirectionParam, limitParam);
    const allParams = this.mergeAdditionalUserFilterParams(initialParams, this.getAdditionalUserFilterParams());
    this.usersAndGroups$ =
      allParams.status.length === 0 ? this.getEmptyTableApiResponse() : this.usersToGroupsService.get_users_and_groups(allParams);
    this.usersAndGroups$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ([usersResp, groupsResp]) => {
        this.buildData(usersResp, groupsResp, searchDirectionParam, limitParam);
        this.doPaymentChecksAndDetectChanges();
      },
      (err) => {
        this.paginatorConfig.actions.errorHandler(err);
      }
    );
  }

  private createUserElement(user: User, index: number): UserElement {
    const data: UserElement = {
      description: user.inheritable_config?.description ? user.inheritable_config.description : '',
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(user)) {
      data[key] = user[key];
    }
    data.member_of = sortArrayByKey(data.member_of, 'first_name');
    return data;
  }

  private buildData(
    usersResp: UsersResp,
    groupsResp: Array<Group>,
    searchDirectionParam: 'forwards' | 'backwards',
    limitParam: number
  ): void {
    // Reset the chipColumn member_of list if it contains groups
    this.columnDefs.get('member_of').allowedValues.length = 0;
    groupsResp.forEach((group) => {
      this.usersToGroupsService.idToGroupMap.set(group.id, group);
      if (group.enabled) {
        this.firstNameToGroupMap.set(group.first_name, group);
        this.columnDefs.get('member_of').allowedValues.push(group);
      }
    });
    this.tableData = [];
    this.tableData = this.setData(usersResp.users);
    this.columnDefs.get('member_of').formControl.reset();
    // signal that data has been fetched from the api
    this.paginatorConfig.actions.dataFetched({
      data: this.tableData,
      searchDirection: searchDirectionParam,
      limit: limitParam,
      nextKey: usersResp.nextPageEmail,
      previousKey: usersResp.previousPageEmail,
    });
    this.fetchedData = true;
  }

  private setData(users: Array<User>): Array<UserElement> {
    const data: Array<UserElement> = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      this.updateUserMaps(user);
      if (user.type === User.TypeEnum.user) {
        // Need to clone a copy of the user since we are modifying
        // it in the table and it cannot point to the user stored in
        // the idToUserMap map, since it is used to compare the
        // original version of the user with the updated version.
        data.push(this.createUserElement(cloneDeep(user), i));
      }
    }
    return data;
  }

  public reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorConfig.actions.reloadFirstPage();
  }

  public reloadFirstPageAndRefreshUsageMetrics(): void {
    if (isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod) && canReadBillingData(this.currentOrg)) {
      const usageMetrics$ = this.organisationsService.getUsageMetrics({ org_id: this.orgId });
      usageMetrics$.pipe(take(1)).subscribe((usageMetricsResp) => {
        this.usageMetrics = usageMetricsResp;
        this.reloadFirstPage();
      });
    } else {
      this.reloadFirstPage();
    }
  }

  private reloadWindow(): void {
    this.paginatorConfig.actions.reloadWindow();
  }

  private updateUserMaps(user: User): void {
    // replace/set email and id values in maps
    this.usersToGroupsService.idToUserMap.set(user.id, user);
    this.emailToUserMap.set(user.email, user);
  }

  private updateUserElement(userElement: UserElement, userResp: User): void {
    // need to clone usersResp for comparison with table changes
    this.updateUserMaps(cloneDeep(userResp));
    for (const key of Object.keys(userResp)) {
      userElement[key] = userResp[key];
    }
    userElement.member_of = userElement.member_of.filter((group) => group.type === User.TypeEnum.group);
  }

  private postUser(updatedUser: UserElement): void {
    this.usersToGroupsService
      .post_users_to_groups(updatedUser)
      .pipe(
        concatMap((usersResp) => {
          let usageMetrics$: Observable<UsageMetrics> = of(undefined);
          if (!!isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod)) {
            usageMetrics$ = this.organisationsService.getUsageMetrics({ org_id: this.orgId });
          }
          // Need to update usage metric to check number of users:
          return usageMetrics$.pipe(
            map((usageMetricsResp) => {
              return {
                usersResp,
                usageMetricsResp,
              };
            })
          );
        })
      )
      .pipe(take(1))
      .subscribe(
        (combinedResp) => {
          this.usageMetrics = combinedResp.usageMetricsResp;
          if (Array.isArray(combinedResp.usersResp)) {
            // ignore groups response included in userResp
            combinedResp.usersResp = combinedResp.usersResp[0];
          }
          this.notificationService.success('New user "' + combinedResp.usersResp.email + '" was successfully created');
          this.updateUserElement(updatedUser, combinedResp.usersResp);
          updatedUser.isNew = false;
          this.doPaymentChecksAndDetectChanges();
        },
        (errorResp) => {
          let errorMessage = `Failed to create user "${updatedUser.email}"`;
          if (errorResp instanceof HttpErrorResponse && errorResp.status === 409) {
            const failedUserResp: User = errorResp.error;
            this.userEmailToFilter = failedUserResp.email;
            errorMessage = `<br />` + errorMessage;
            errorMessage += `. This user already exists with a status of "${failedUserResp.status}". You may need to adjust the table filters to view this user.
            <br />
            Click "Confirm" to set the appropriate filters or cancel the dialog to reset the data to the last successfully saved version.`;
            if (failedUserResp.status === UserStatusEnum.disabled) {
              this.appErrorHandler.openErrorMessageDialog(
                errorResp,
                errorMessage,
                this.setDisabledUserFilter.bind(this),
                this.reloadFirstPage.bind(this)
              );
            }
            if (failedUserResp.status === UserStatusEnum.pending) {
              this.appErrorHandler.openErrorMessageDialog(
                errorResp,
                errorMessage,
                this.setPendingUserFilter.bind(this),
                this.reloadFirstPage.bind(this)
              );
            }
          } else {
            this.notificationService.error(errorMessage);
          }
        }
      );
  }

  private setDisabledUserFilter(): void {
    const disabledUserCheckboxOption = this.filterManager.checkboxOptions.find((option) => option.name === UsersCheckboxOptions.DISABLED);
    this.filterManager.onCheckBoxToggle(disabledUserCheckboxOption, null);
    this.setUserEmailFilter();
  }

  private setPendingUserFilter(): void {
    const pendingUserCheckboxOption = this.filterManager.checkboxOptions.find((option) => option.name === UsersCheckboxOptions.PENDING);
    this.filterManager.onCheckBoxToggle(pendingUserCheckboxOption, null);
    this.setUserEmailFilter();
  }

  private setUserEmailFilter(): void {
    const prefixEmailSearchOption = this.paramSpecificFilterManager.paramSpecificSearchOptions.find(
      (option) => option.name === ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH
    );
    this.paramSpecificFilterManager.addParamSpecificFilterOptionWithValueToFilterBar(
      this.userEmailToFilter,
      prefixEmailSearchOption,
      this.filterManager.filterBar
    );
    this.userEmailToFilter = undefined;
  }

  private putUser(updatedUser: UserElement): void {
    this.usersToGroupsService
      .put_users_to_groups(updatedUser)
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success('User "' + updatedUser.email + '" was successfully updated');
          this.updateUserElement(updatedUser, resp);
          this.doPaymentChecksAndDetectChanges();
        },
        (errorResp) => {
          const baseMessage = 'Failed to update user "' + updatedUser.email + '"';
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        }
      );
  }

  /**
   * Receives a UserElement and checks it for validity.
   * It either submits the new data via an api call
   * or notifies the user of an error.
   */
  public updateEvent(updatedUser: UserElement): void {
    if (updatedUser.inheritable_config?.description) {
      updatedUser.inheritable_config.description = updatedUser.description;
    } else {
      updatedUser.inheritable_config = {
        description: updatedUser.description,
      };
    }
    updatedUser.email = updatedUser.email.trim();
    if (!updatedUser.id) {
      this.postUser(updatedUser);
    } else {
      this.putUser(updatedUser);
    }
  }

  private populateDeleteObservablesArray(users: Array<UserElement>): Array<Observable<object>> {
    const observablesArray: Array<Observable<object>> = [];
    for (const user of users) {
      if (user.isChecked && user.id) {
        observablesArray.push(
          this.usersToGroupsService.delete_users_to_groups({
            orgId: this.orgId,
            userId: user.id,
          })
        );
      }
    }
    return observablesArray;
  }

  private populateEnableObservablesArray(users: Array<UserElement>, enable: boolean): Array<Observable<User>> {
    const observablesArray: Array<Observable<User>> = [];
    for (const user of users) {
      if (user.isChecked && user.id) {
        (user.enabled as boolean) = enable;
        if (enable) {
          user.status = UserStatusEnum.active;
        } else {
          user.status = UserStatusEnum.disabled;
        }
        observablesArray.push(this.usersToGroupsService.put_users_to_groups(user));
      }
    }
    return observablesArray;
  }

  private populateMoveObservablesArray(users: Array<UserElement>): Array<Observable<User>> {
    const observablesArray: Array<Observable<User>> = [];
    for (const user of users) {
      user.auto_created = false;
      observablesArray.push(this.usersToGroupsService.put_users_to_groups(user));
    }
    return observablesArray;
  }

  public updateUsers(observablesArray: Array<Observable<any>>, updateAction: UpdateAction): void {
    if (observablesArray.length === 0) {
      this.notificationService.success('Unsaved users were removed');
      this.reloadTableCount = this.reloadTableCount + 1;
      this.reloadWindow();
      return;
    }
    forkJoin(observablesArray)
      .pipe(
        concatMap((usersResp) => {
          let usageMetrics$: Observable<UsageMetrics> = of(undefined);
          if (!!isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod)) {
            usageMetrics$ = this.organisationsService.getUsageMetrics({ org_id: this.orgId });
          }
          // Need to update usage metric to check number of users:
          return usageMetrics$.pipe(
            map((usageMetricsResp) => {
              return {
                usersResp,
                usageMetricsResp,
              };
            })
          );
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (combinedResp) => {
          this.usageMetrics = combinedResp.usageMetricsResp;
          this.notificationService.success('Users were successfully ' + updateAction + 'd');
        },
        (errorResp) => {
          this.notificationService.error('Failed to ' + updateAction + ' all selected users');
        },
        () => {
          this.reloadWindow();
        }
      );
  }

  public deleteSelected(usersToDelete: Array<UserElement>): void {
    this.updateUsers(this.populateDeleteObservablesArray(usersToDelete), UpdateAction.DELETE);
  }

  public enableSelected(usersToEnable: Array<UserElement>): void {
    this.updateUsers(this.populateEnableObservablesArray(usersToEnable, true), UpdateAction.ENABLE);
  }

  public disableSelected(usersToDisable: Array<UserElement>): void {
    this.updateUsers(this.populateEnableObservablesArray(usersToDisable, false), UpdateAction.DISABLE);
  }

  public moveSelectedFromAutoCreate(usersToMove: Array<UserElement>): void {
    // Move the selected users from the auto-created-users group to the all-users group
    this.updateUsers(this.populateMoveObservablesArray(usersToMove), UpdateAction.MOVE);
  }

  public resetSelected(usersToReset: Array<UserElement>): void {
    // Reset the given users 2-factor enrollment preferences
    usersToReset.forEach((user) => {
      const body: ResetMFAChallengeMethod = {
        org_id: this.orgId,
      };
      const mrp: ResetUserMfaChallengeMethodsRequestParams = {
        user_id: user.id,
        ResetMFAChallengeMethod: body,
      };
      this.usersService.resetUserMfaChallengeMethods(mrp).subscribe(
        (method) => {
          // OK
        },
        (error) => {
          this.notificationService.error(`Unable to reset user ${user.email} multi-factor-authentication`);
        }
      );
      if (this.currentOrg.trust_on_first_use_duration === undefined) {
        this.notificationService.error(
          `Unable to reset user multi-factor-authentication deadline. No organisation enrollment deadline set`
        );
      } else {
        const userMetadata$ = this.getUserMetadata(user.id, this.orgId).pipe(
          concatMap((userMetadataResp: ListUserMetadataResponse) => {
            const mfaEnrollmentExpiryUserMetadata = this.getMfaEnrollmentExpiryUserMetadata(userMetadataResp);

            if (mfaEnrollmentExpiryUserMetadata === undefined) {
              const createUserMetadataParams: CreateUserMetadataRequestParams = {
                UserMetadata: {
                  spec: {
                    user_id: user.id,
                    org_id: this.orgId,
                    name: 'mfa_enrollment_expiry_time',
                    data: getMfaEnrollmentExpiryDateString(this.currentOrg.trust_on_first_use_duration),
                    data_type: UserMetadataSpec.DataTypeEnum.mfa_enrollment_expiry,
                  },
                },
              };
              return this.usersService.createUserMetadata(createUserMetadataParams);
            } else {
              const replaceUserMetadataParams: ReplaceUserMetadataRequestParams = {
                metadata_id: mfaEnrollmentExpiryUserMetadata.metadata.id,
                UserMetadata: mfaEnrollmentExpiryUserMetadata,
              };
              return this.usersService.replaceUserMetadata(replaceUserMetadataParams);
            }
          })
        );
        userMetadata$.subscribe(
          (method) => {
            // OK
          },
          (error) => {
            this.notificationService.error(`Unable to reset user ${user.email} multi-factor-authentication deadline`);
          }
        );
      }
    });
    this.reloadFirstPage();
  }

  private getUserMetadata(userId: string, orgId: string): Observable<ListUserMetadataResponse> {
    const listUserMetadataParams: ListUserMetadataRequestParams = {
      user_id: userId,
      org_id: orgId,
      data_type: UserMetadataSpec.DataTypeEnum.mfa_enrollment_expiry,
    };
    return this.usersService.listUserMetadata(listUserMetadataParams);
  }

  private getMfaEnrollmentExpiryUserMetadata(userMetadataResp: ListUserMetadataResponse): UserMetadata | undefined {
    const expiryString = getMfaEnrollmentExpiryDateString(this.currentOrg.trust_on_first_use_duration);
    const mfaEnrollmentExpiryUserMetadata = getMfaEnrollmentExpiryFromUserMetadata(userMetadataResp);
    if (mfaEnrollmentExpiryUserMetadata !== undefined) {
      mfaEnrollmentExpiryUserMetadata.spec.data = expiryString;
    }
    return mfaEnrollmentExpiryUserMetadata;
  }

  public makeEmptyTableElement(): UserElement {
    return {
      email: '',
      first_name: '',
      last_name: '',
      description: '',
      member_of: [],
      org_id: this.orgId,
      mfaEnrolled: false,
      mfaEnabled: false,
      mfaMethods: [],
      ...getDefaultNewRowProperties(),
    };
  }

  public toggleActiveUsers(checkboxOption: CheckboxOption): void {
    this.hideActiveUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  public toggleDisabledUsers(checkboxOption: CheckboxOption): void {
    this.hideDisabledUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  public togglePendingUsers(checkboxOption: CheckboxOption): void {
    this.hidePendingUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  public toggleMFAOnUsers(checkboxOption: CheckboxOption): void {
    this.showOnlyMFAUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  public toggleMFAOffUsers(checkboxOption: CheckboxOption): void {
    this.showOnlyNonMFAUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  public toggleAutoCreatedUsers(checkboxOption: CheckboxOption): void {
    this.showOnlyAutoCreatedUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  public toggleNonAutoCreatedUsers(checkboxOption: CheckboxOption): void {
    this.showOnlyNonAutoCreatedUsers = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  private getParams(
    email_key: string,
    search_direction_param: 'forwards' | 'backwards',
    limit_param: number
  ): ListCombinedUserDetailsRequestParams {
    const params: ListCombinedUserDetailsRequestParams = {
      org_id: this.orgId,
      status: [],
      limit: limit_param,
      previous_email: email_key,
      search_direction: search_direction_param,
      type: 'user',
    };
    if (!this.hideActiveUsers) {
      params.status.push(UserStatusEnum.active);
    }
    if (!this.hideDisabledUsers) {
      params.status.push(UserStatusEnum.disabled);
    }
    if (!this.hidePendingUsers) {
      params.status.push(UserStatusEnum.pending);
    }
    if (this.showOnlyMFAUsers) {
      params.mfa_enrolled = true;
    }
    if (this.showOnlyNonMFAUsers) {
      params.mfa_enrolled = false;
    }
    if (this.showOnlyAutoCreatedUsers) {
      params.auto_created = true;
    }
    if (this.showOnlyNonAutoCreatedUsers) {
      params.auto_created = false;
    }
    return params;
  }

  public getAllUsers(): Observable<any> {
    const searchDirection = 'forwards';
    const limit = 500;
    const params = this.getParams('', searchDirection, limit);
    if (params.status.length === 0) {
      return of([]);
    }
    return this.usersToGroupsService
      .get_users_and_groups(params)
      .pipe(
        takeUntil(this.unsubscribe$),
        expand(([usersResp, groupsResp]) => {
          if (usersResp.nextPageEmail) {
            const newParams = this.getParams(usersResp.nextPageEmail, searchDirection, limit);
            return this.usersToGroupsService.get_users_and_groups(newParams);
          }
          return EMPTY;
        })
      )
      .pipe(
        reduce((accUsers, [usersData, groupsData]) => {
          if (accUsers) {
            return accUsers.concat(usersData.users);
          } else {
            // first fetch
            return usersData.users;
          }
        }, [])
      );
  }

  private getAdditionalUserFilterParams(): ListCombinedUserDetailsRequestParams {
    const listCombinedUserDetailsRequestParams: ListCombinedUserDetailsRequestParams = {
      org_id: this.orgId,
      allow_partial_match: true,
    };
    const genericSearchOptionsWithValues = this.filterManager.getAllInputOptionsWithValues();
    const genericSearchParams = this.filterManager.getGenericSearchParams(genericSearchOptionsWithValues);
    if (genericSearchParams.length !== 0) {
      listCombinedUserDetailsRequestParams.search_params = genericSearchParams;
    }
    const paramSpecificSearchOptionsWithValues = this.paramSpecificFilterManager.getAllParamSpecificSearchOptionsWithValues();
    for (const option of paramSpecificSearchOptionsWithValues) {
      listCombinedUserDetailsRequestParams[option.name.trim().toLowerCase()] = option.displayName;
    }
    return listCombinedUserDetailsRequestParams;
  }

  private mergeAdditionalUserFilterParams(
    currentParams: ListCombinedUserDetailsRequestParams,
    additonalParams: ListCombinedUserDetailsRequestParams
  ): ListCombinedUserDetailsRequestParams {
    return { ...currentParams, ...additonalParams };
  }

  public filterBySearchParam(): void {
    this.reloadFirstPage();
  }

  private shouldShowPaymentDialog(): boolean {
    if (!canReadBillingData(this.currentOrg)) {
      // Do not show dialog if user does not have access to billing data
      return false;
    }
    return (
      isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod) && this.getNumberOfUsersFromMetrics() >= getStarterPlanMaxUsers()
    );
  }

  public openPaymentNotificationDialog(): void {
    this.eventsService.SendEvent({
      event_id: 'payment-nag-user',
      event_type: 'admin-payment',
      category: 'upsell',
      sub_category: 'payment',
    });
    this.dialogOpen = true;
    const messagePrefix = `No Payment On File`;
    let message = `${getStarterPaymentDialogMessageBeginning('user')}
    If you would like to add additional users, please click the "${getSubscriptionButtonText()}" button to update your subscription and add a payment method.`;
    message += getPaymentDialogEndMessage();
    const dialogData: PaymentDialogData = {
      messagePrefix,
      message,
      orgId: this.orgId,
    };
    const dialogRef = this.paymentDialog.open(PaymentDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.dialogOpen = false;
    });
  }

  public onPreventAddNewRow(): void {
    if (this.preventAddUser && !this.dialogOpen) {
      this.openPaymentNotificationDialog();
    }
  }

  /**
   * Disables the add user buttons until it is determined whether the user
   * is allowed to add users or not. Once that is determined the button is
   * enabled, but the user may still be notified that they are unable to add users.
   */
  public allowAddNewUserClick(): boolean {
    if (!this.fetchedData || !this.checkedPaymentStatus) {
      return false;
    }
    if (this.isStarterPlan === undefined && this.refreshBillingDataStateValue === 0) {
      // Haven't fetched the billing data yet, so block.
      return false;
    }
    // If isStarterPlan is still undefined at this point, then they have no billing set up, so do not block/nag
    return true;
  }

  public notifyUserOnCsvUpload(): void {
    this.openPaymentNotificationDialog();
  }

  public getUserLimit(): number | undefined {
    if (isStarterPlanWithoutPayment(this.isStarterPlan, this.paymentMethod)) {
      // Need to limit users:
      return getStarterPlanMaxUsers();
    }
    // Do not limit users:
    return undefined;
  }

  private revokeSelected(usersToRevoke: Array<UserElement>, needToLogout: boolean): void {
    const observablesArray: Array<Observable<BulkTokenRevokeResponse>> = [];
    for (const user of usersToRevoke) {
      observablesArray.push(bulkRevokeUserSessionAndTokens$(this.tokensService, user.id, this.orgId));
    }
    if (observablesArray.length === 0) {
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('All sessions have been successfully revoked for the selected users');
          if (needToLogout) {
            this.authService.triggerLogout();
          }
        },
        (err) => {
          this.notificationService.error('Failed to revoke all sessions for the selected users');
        },
        () => {
          this.reloadFirstPage();
        }
      );
  }

  private onRevokeSelected(usersToRevoke: Array<UserElement>): void {
    const ownUserToRevoke = usersToRevoke.find((user) => user.id === this.currentUser.id);
    if (!!ownUserToRevoke) {
      this.openSelfUserRevokeWarningDialog(usersToRevoke);
    } else {
      this.revokeSelected(usersToRevoke, false);
    }
  }

  public openSelfUserRevokeWarningDialog(usersToRevoke: Array<UserElement>): void {
    this.dialogOpen = true;
    const messagePrefix = `Revoking Sessions for Current User`;
    let message = `Please note you are about to revoke sessions for the user you are currently logged in with.
    By doing so you will be logged out of this session. Do you wish to continue?`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: 'Yes', cancel: 'No' } },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) {
        return;
      }
      this.revokeSelected(usersToRevoke, true);
    });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
