<portal-page-info
  pageTitle="Resource Permissions"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="permissionsDefined() && !hasAllPermissions()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="permissionsDefined() && hasAllPermissions()"
  class="permissions-admin-container"
>
  <portal-table-layout
    #tableLayoutComp
    [columnDefs]="columnDefs"
    [filterMenuOptions]="filterMenuOptions"
    [filterManager]="filterManager"
    [paramSpecificFilterManager]="paramSpecificFilterManager"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [rowObjectName]="rowObjectName"
    [paginatorConfig]="paginatorConfig"
    [linkDataSource] = "linkDataSource"
    (updateEvent)="updateEvent($event)"
    (updateAutoInput)="updateAutoInput($event)"
    (removeSelected)="removeSelected($event)"
    (filterSearchDelay)="paramSpecificFilterManager.filterSearchDelay($event)"
  >
  </portal-table-layout>
</div>
