import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input, OnChanges } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import {
  Column,
  createInputColumn,
  createChipListColumn,
  createSelectColumn,
  createSelectRowColumn,
  createActionsColumn,
  ActionMenuOptions,
  ChiplistColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { Application, ApplicationConfig, RuntimeStatus } from '@agilicus/angular';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { cloneDeep } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { ApplicationAssignment } from '@agilicus/angular';
import { Organisation } from '@agilicus/angular';
import { selectOrganisations } from '@app/core/organisations/organisations.selectors';
import { Environment } from '@agilicus/angular';
import { TableElement } from '../table-layout/table-element';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import {
  ActionApiApplicationsModifyCurrentApp,
  ActionApiApplicationsInitApplications,
} from '@app/core/api-applications/api-applications.actions';
import { Router } from '@angular/router';
import {
  generateUniqueUuid,
  updateTableElements,
  isOrgAlreadyAssigned,
  useValueIfNotInMap,
  getStatusIconColor,
  getStatusIcon,
  capitalizeFirstLetter,
  isApplicationExternal,
} from '../utils';
import { OrganisationsState } from '@app/core/organisations/organisations.models';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { createBlankAppBundle } from '../file-utils';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { AppBundle } from '@app/core/api/applications/app-bundle';
import { OptionalEnvironmentElement } from '../optional-types';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { getDefaultApplicationConfig } from '@app/core/models/application/application-model-api-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { InputData } from '../custom-chiplist-input/input-data';
import { ButtonType } from '../button-type.enum';
import { FeatureFlagService } from '@app/core/feature-flag/feature-flag.service';

export interface EnvironmentElement extends TableElement {
  assignments: Array<ApplicationAssignment>;
  maintenance_org_id?: string;
  name: string;
  /**
   * Used for updating the application assignments when an
   * environment name changes. We find the assignment using
   * the previous_name and update it with the new name.
   */
  previous_name: string;
  version_tag: string;
  serverless_image: string;
  overallStatus: RuntimeStatus.OverallStatusEnum;
}
@Component({
  selector: 'portal-application-instances',
  templateUrl: './application-instances.component.html',
  styleUrls: ['./application-instances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationInstancesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<EnvironmentElement>> = new Map();
  private orgId$: Observable<string>;
  private orgId: string;
  private appState$: Observable<ApiApplicationsState>;
  public currentApplicationCopy: Application;
  public savingApp = false;
  private orgsState$: Observable<OrganisationsState>;
  private allOrganisations: Array<Organisation>;
  public tableData: Array<EnvironmentElement> = [];
  public filterManager: FilterManager = new FilterManager();
  private orgIdToOrgNameMap: Map<string, string>;
  private orgNameToOrgIdMap: Map<string, string>;
  private orgNametoAssignmentMap: Map<string, ApplicationAssignment> = new Map();
  public rowObjectName = 'INSTANCE';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private appBundleLabelToIdMap: Map<string, string> = new Map();
  private appBundleIdToLabelMap: Map<string, string> = new Map();
  private currentEnvAppConfigs: ApplicationConfig;
  public instancesProductGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-instances`;
  public instancesDescriptiveText = `An 'instance' is a separate running copy of the application, which in turn has some specific configuration overrides. 
  For customers who have multiple organisations (e.g. Production, Staging), this allows them to run multiple copies with different versions.`;
  public buttonsToShow: Array<ButtonType> = [];
  private canOrgCreateHostedApplications = false;
  private columnsInitialized = false;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(new ActionApiApplicationsInitApplications());
    this.orgId$ = this.store.pipe(select(selectApiOrgId));
    this.appState$ = this.store.pipe(select(selectApiApplications));
    this.orgsState$ = this.store.pipe(select(selectOrganisations));
    const canOrgCreateHostedApplications$ = this.featureFlagService.canOrgCreateHostedApplications$();
    combineLatest([this.orgId$, this.appState$, this.orgsState$, canOrgCreateHostedApplications$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, appStateResp, orgsStateResp, canOrgCreateHostedApplicationsResp]) => {
        this.orgId = orgIdResp;
        if (canOrgCreateHostedApplicationsResp !== undefined && !this.columnsInitialized) {
          this.canOrgCreateHostedApplications = canOrgCreateHostedApplicationsResp;
          this.initializeColumnDefs();
          this.columnsInitialized = true;
        }
        if (!!this.isHostedApplicationsEnabled()) {
          this.buttonsToShow.push(ButtonType.ADD, ButtonType.DELETE);
        }
        this.doWhenAppsDataIsLoaded(appStateResp);
        this.doWhenOrgsDataIsLoaded(orgsStateResp);
        if (
          appStateResp === undefined ||
          appStateResp.current_application === undefined ||
          appStateResp.current_application_bundles_list === undefined ||
          orgsStateResp === undefined ||
          orgsStateResp.all_organisations === undefined ||
          orgsStateResp.org_id_to_org_name_map === undefined ||
          orgsStateResp.org_name_to_org_id_map === undefined
        ) {
          this.resetEmptyTable();
          return;
        }
        this.doWhenAllDataIsLoaded();
      });
  }

  public ngOnChanges(): void {
    if (this.currentApplicationCopy === undefined) {
      return;
    }
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private doWhenAllDataIsLoaded(): void {
    if (this.savingApp) {
      return;
    }
    this.updateTable();
  }

  private doWhenAppsDataIsLoaded(appStateResp: ApiApplicationsState): void {
    if (appStateResp === undefined) {
      return;
    }
    this.currentApplicationCopy = undefined;
    this.savingApp = appStateResp.saving_app;
    this.setAppBundleMaps(appStateResp.current_application_bundles_list);
    if (appStateResp.current_application === undefined) {
      return;
    }
    // Need to make a copy since we cannot modify the readonly data from the store
    this.currentApplicationCopy = cloneDeep(appStateResp.current_application);
    this.currentEnvAppConfigs = appStateResp.current_environment?.application_configs;
    const bundlesColumn = this.columnDefs.get('serverless_image');
    if (!!bundlesColumn) {
      bundlesColumn.allowedValues = [createBlankAppBundle(), ...appStateResp.current_application_bundles_list];
    }
    this.setEditableColumnDefs();
  }

  private doWhenOrgsDataIsLoaded(orgsStateResp: OrganisationsState): void {
    if (orgsStateResp === undefined) {
      return;
    }
    this.allOrganisations = orgsStateResp.all_organisations;
    this.orgIdToOrgNameMap = orgsStateResp.org_id_to_org_name_map;
    this.orgNameToOrgIdMap = orgsStateResp.org_name_to_org_id_map;
  }

  private updateTable(): void {
    // Reset the allowedValues lists
    const assignmentsColumn = this.columnDefs.get('assignments');
    if (!!assignmentsColumn) {
      assignmentsColumn.allowedValues.length = 0;
    }
    const maintenanceOrgIdColumn = this.columnDefs.get('maintenance_org_id');
    if (!!maintenanceOrgIdColumn) {
      maintenanceOrgIdColumn.allowedValues.length = 0;
    }
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<EnvironmentElement> = [];
    if (this.currentApplicationCopy.environments === undefined) {
      this.currentApplicationCopy.environments = [];
    }
    for (let i = 0; i < this.currentApplicationCopy.environments.length; i++) {
      data.push(this.createEnvironmentElement(this.currentApplicationCopy.environments[i], i));
    }
    // Clear the map when data is reloaded
    this.orgNametoAssignmentMap.clear();
    this.allOrganisations.forEach((org: Organisation) => {
      const assignment: ApplicationAssignment = {
        environment_name: '',
        org_id: org.id,
      };
      this.orgNametoAssignmentMap.set(org.organisation, assignment);
      const assignmentsColumn = this.columnDefs.get('assignments');
      if (!!assignmentsColumn) {
        assignmentsColumn.allowedValues.push(assignment);
      }
      const maintenanceOrgIdColumn = this.columnDefs.get('maintenance_org_id');
      if (!!maintenanceOrgIdColumn) {
        maintenanceOrgIdColumn.allowedValues.push(org);
      }
    });
    updateTableElements(this.tableData, data);
  }

  private createEnvironmentElement(env: Environment, index: number): EnvironmentElement {
    const data: EnvironmentElement = {
      assignments: [],
      maintenance_org_id: env.maintenance_org_id,
      name: env.name,
      previous_name: env.name,
      version_tag: env.version_tag,
      serverless_image: env.serverless_image,
      overallStatus: this.getEnvStatusForTable(env),
      ...getDefaultTableProperties(index),
    };
    for (const assignment of this.currentApplicationCopy.assignments) {
      if (assignment.environment_name === data.name) {
        data.assignments.push(assignment);
      }
    }
    return data;
  }

  private getEnvStatusForTable(env: Environment): RuntimeStatus.OverallStatusEnum {
    if (env.status && env.status.runtime_status) {
      return env.status.runtime_status.overall_status;
    }
    return undefined;
  }

  private getVersionTagColumn(): Column<EnvironmentElement> {
    const versionTagColumn = createInputColumn('version_tag');
    versionTagColumn.displayName = 'Tag';
    versionTagColumn.requiredField = () => true;
    versionTagColumn.isEditable = this.isHostedApplicationsEnabled();
    return versionTagColumn;
  }

  private getOwnerColumn(): Column<EnvironmentElement> {
    const ownerColumn = createSelectColumn('maintenance_org_id');
    ownerColumn.displayName = 'Owner';
    ownerColumn.isEditable = this.isHostedApplicationsEnabled();
    ownerColumn.getDisplayValue = (element: OptionalEnvironmentElement): any => {
      return useValueIfNotInMap(element.maintenance_org_id, this.orgIdToOrgNameMap);
    };
    ownerColumn.getOptionValue = this.getMaintenanceOrgValue;
    ownerColumn.getOptionDisplayValue = this.getMaintenanceOrgValue;
    return ownerColumn;
  }

  private getServerlessImageColumn(): Column<EnvironmentElement> {
    const serverlessImageColumn = createSelectColumn('serverless_image');
    serverlessImageColumn.displayName = 'Bundle Name';
    serverlessImageColumn.isEditable = this.isHostedApplicationsEnabled();
    serverlessImageColumn.getDisplayValue = (element: OptionalEnvironmentElement) => {
      return this.appBundleIdToLabelMap.get(element.serverless_image);
    };
    serverlessImageColumn.getOptionValue = this.getServerlessImageValue;
    serverlessImageColumn.getOptionDisplayValue = this.getServerlessImageValue;
    return serverlessImageColumn;
  }

  private getAssignmentsColumn(): Column<EnvironmentElement> {
    const assignmentsColumn = createChipListColumn('assignments');
    assignmentsColumn.displayName = 'Organisations';
    assignmentsColumn.isEditable = this.isHostedApplicationsEnabled();
    assignmentsColumn.getDisplayValue = (assignment: any) => {
      return useValueIfNotInMap(assignment.org_id, this.orgIdToOrgNameMap);
    };
    assignmentsColumn.getElementFromValue = (orgName: string): any => {
      return this.orgNametoAssignmentMap.get(orgName);
    };
    return assignmentsColumn;
  }

  private getOverallStatusColumn(): Column<EnvironmentElement> {
    const overallStatusColumn = createInputColumn('overallStatus');
    overallStatusColumn.displayName = 'Status';
    overallStatusColumn.hasIconPrefix = true;
    overallStatusColumn.getDisplayValue = (element: OptionalEnvironmentElement): string => {
      if (!element.overallStatus) {
        return '';
      }
      return capitalizeFirstLetter(element.overallStatus);
    };
    overallStatusColumn.getIconPrefix = this.getStatusIconFromElement.bind(this);
    overallStatusColumn.getIconColor = this.getStatusIconColorFromElement.bind(this);
    return overallStatusColumn;
  }

  private getActionsColumn(): Column<EnvironmentElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<EnvironmentElement>> = [
      {
        displayName: 'Configure Instance',
        icon: 'open_in_browser',
        tooltip: 'Click to view/modify this instance',
        onClick: (element: any) => {
          this.router.navigate([window.location.pathname + '/instance', element.name], {
            queryParams: { org_id: this.orgId },
          });
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    actionsColumn.disableField = (element: OptionalEnvironmentElement): boolean => {
      return isApplicationExternal(this.currentApplicationCopy) || element.isNew;
    };
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    let columns: Array<Column<InputData>> = [];
    if (this.isHostedApplicationsEnabled()) {
      columns = [
        createSelectRowColumn(),
        this.getVersionTagColumn(),
        this.getOwnerColumn(),
        this.getServerlessImageColumn(),
        this.getAssignmentsColumn(),
        this.getOverallStatusColumn(),
        this.getActionsColumn(),
      ];
    } else {
      columns = [this.getOverallStatusColumn(), this.getActionsColumn()];
    }
    setColumnDefs(columns, this.columnDefs);
  }

  private getStatusIconFromElement(element: EnvironmentElement): string {
    return getStatusIcon(element.overallStatus);
  }

  private getStatusIconColorFromElement(element: EnvironmentElement): string {
    return getStatusIconColor(element.overallStatus);
  }

  private getMaintenanceOrgValue(org: Organisation): string {
    return org.organisation;
  }

  private getServerlessImageValue(appBundle: AppBundle): string {
    return appBundle.label;
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    if (!!selectRowColumn) {
      selectRowColumn.showColumn = !this.fixedTable;
    }

    const versionTagColumn = this.columnDefs.get('version_tag');
    if (!!versionTagColumn) {
      versionTagColumn.isEditable = !this.fixedTable && !isApplicationExternal(this.currentApplicationCopy);
      versionTagColumn.getTooltip = () => {
        return 'Your version tag or "latest" if using a well-known runtime';
      };
      versionTagColumn.requiredField = () => {
        return !isApplicationExternal(this.currentApplicationCopy);
      };
    }

    const ownerColumn = this.columnDefs.get('maintenance_org_id');
    if (!!ownerColumn) {
      // Currently, set to false to disable selection of the maintenance org.
      // This functionality will enabled at a later date.
      ownerColumn.isEditable = false;
    }

    const assignmentsColumn: ChiplistColumn<EnvironmentElement> = this.columnDefs.get('assignments');
    if (!!assignmentsColumn) {
      assignmentsColumn.isEditable = !this.fixedTable;
      assignmentsColumn.isRemovable = !this.fixedTable;
    }

    const serverlessImageColumn = this.columnDefs.get('serverless_image');
    if (!!serverlessImageColumn) {
      serverlessImageColumn.isEditable = !this.fixedTable && !isApplicationExternal(this.currentApplicationCopy);
    }

    const actionsColumn = this.columnDefs.get('actions');
    actionsColumn.showColumn = !!this.currentApplicationCopy.id;
  }

  public makeEmptyTableElement(): EnvironmentElement {
    const newName = generateUniqueUuid(this.tableData, 'name');
    return {
      assignments: [],
      maintenance_org_id: this.orgId,
      name: newName,
      previous_name: '',
      version_tag: '',
      serverless_image: '',
      overallStatus: null,
      ...getDefaultNewRowProperties(),
    };
  }

  private getNewAssignment(updatedEnvironment: EnvironmentElement): ApplicationAssignment {
    let newAssignment: ApplicationAssignment;
    for (const assignment of updatedEnvironment.assignments) {
      if (assignment.environment_name === '') {
        // Adding a new assignment.
        newAssignment = {
          environment_name: updatedEnvironment.name,
          org_id: assignment.org_id,
        };
      }
    }
    return newAssignment;
  }

  private isAppMaintained(element: EnvironmentElement): boolean {
    if (element.maintenance_org_id === this.orgId) {
      return true;
    }
    return this.currentApplicationCopy.environments.some((env) => env.name !== element.name && env.maintenance_org_id === this.orgId);
  }

  /**
   * Receives an EnvironmentElement from the table then updates and saves
   * the current application.
   */
  public updateEvent(updatedEnvironment: EnvironmentElement): void {
    const newAssignment: ApplicationAssignment = this.getNewAssignment(updatedEnvironment);
    if (isOrgAlreadyAssigned(this.currentApplicationCopy.assignments, newAssignment)) {
      const orgName = useValueIfNotInMap(newAssignment.org_id, this.orgIdToOrgNameMap);
      this.notificationService.error('"' + orgName + '" has already been assigned to an instance for this application.');
      return;
    }
    this.updateAppEnvironments(updatedEnvironment);
    this.updateAppAssignments(updatedEnvironment);
    this.currentApplicationCopy.maintained = this.isAppMaintained(updatedEnvironment);
    this.modifyApplication();
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<EnvironmentElement>; element: EnvironmentElement }): void {
    if (params.column.name !== 'maintenance_org_id' && params.column.name !== 'serverless_image') {
      return;
    }
    if (params.column.name === 'maintenance_org_id') {
      params.element.maintenance_org_id = useValueIfNotInMap(params.value, this.orgNameToOrgIdMap);
    }
    if (params.column.name === 'serverless_image') {
      params.element.serverless_image = this.appBundleLabelToIdMap.get(params.value);
    }
    this.currentApplicationCopy.maintained = this.isAppMaintained(params.element);
  }

  private modifyApplication(): void {
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  /**
   * All environments must have the same application_configs.
   */
  private setEnvAppConfigs(newEnv: Environment): void {
    if (!this.currentEnvAppConfigs) {
      newEnv.application_configs = getDefaultApplicationConfig();
      return;
    }
    newEnv.application_configs = this.currentEnvAppConfigs;
  }

  private updateAppEnvironments(updatedEnvironment: EnvironmentElement): void {
    if (updatedEnvironment.index === -1) {
      const newEnv: Environment = {
        maintenance_org_id: updatedEnvironment.maintenance_org_id,
        name: updatedEnvironment.name,
        serverless_image: updatedEnvironment.serverless_image,
      };
      this.setEnvAppConfigs(newEnv);
      if (!isApplicationExternal(this.currentApplicationCopy)) {
        newEnv.version_tag = updatedEnvironment.version_tag;
      }
      this.currentApplicationCopy.environments.unshift(newEnv);
      return;
    }
    const targetIndex = updatedEnvironment.index;
    const targetEnvironment = this.currentApplicationCopy.environments[targetIndex];
    targetEnvironment.maintenance_org_id = updatedEnvironment.maintenance_org_id;
    targetEnvironment.name = updatedEnvironment.name;
    targetEnvironment.version_tag = updatedEnvironment.version_tag;
    targetEnvironment.serverless_image = updatedEnvironment.serverless_image;
  }

  private updateAppAssignments(updatedEnvironment: EnvironmentElement): void {
    const newAssignments = [];
    for (const assignment of this.currentApplicationCopy.assignments) {
      if (assignment.environment_name !== updatedEnvironment.previous_name) {
        newAssignments.push(assignment);
      }
    }
    for (const assignment of updatedEnvironment.assignments) {
      assignment.environment_name = updatedEnvironment.name;
      newAssignments.push(assignment);
    }
    this.currentApplicationCopy.assignments = newAssignments;
  }

  /**
   * Removes the assignments from an application for an environment that is being deleted.
   * @param environment is the environment being deleted.
   */
  private removeAppAssignments(environment: EnvironmentElement): void {
    const updatedAssignments = [];
    for (const assignment of this.currentApplicationCopy.assignments) {
      if (assignment.environment_name !== environment.previous_name) {
        updatedAssignments.push(assignment);
      }
    }
    this.currentApplicationCopy.assignments = updatedAssignments;
  }

  private removeEnvironmentsAndAssignments(environmentsToDelete: Array<EnvironmentElement>): void {
    const currentEnvironments = this.currentApplicationCopy.environments;
    for (const env of environmentsToDelete) {
      if (env.index === -1) {
        continue;
      }
      currentEnvironments[env.index] = undefined;
      this.removeAppAssignments(env);
    }
    const updatedEnvironments = [];
    for (const env of currentEnvironments) {
      if (env !== undefined) {
        updatedEnvironments.push(env);
      }
    }
    this.currentApplicationCopy.environments = updatedEnvironments;
  }

  public deleteSelected(environmentsToDelete: Array<EnvironmentElement>): void {
    this.removeEnvironmentsAndAssignments(environmentsToDelete);
    this.modifyApplication();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private setAppBundleMaps(appBundles: Array<AppBundle>): void {
    this.appBundleIdToLabelMap.clear();
    this.appBundleLabelToIdMap.clear();
    appBundles.forEach((appBundle) => {
      this.appBundleIdToLabelMap.set(appBundle.id, appBundle.label);
      this.appBundleLabelToIdMap.set(appBundle.label, appBundle.id);
    });
  }

  public isHostedApplicationsEnabled(): boolean {
    return this.canOrgCreateHostedApplications;
  }
}
