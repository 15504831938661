import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild, HostListener } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject, Observable, combineLatest } from 'rxjs';
import { Application, ApplicationService, FileSummary } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import {
  getEmptyStringIfUnset,
  setFormControlEnableState,
  isOrgAlreadyAssigned,
  useValueIfNotInMap,
  setAutocomplete,
  getChipValuesOnInput,
  modifyDataOnFormBlur,
  getValuesArray,
  scrollToTop,
} from '../utils';
import { Environment } from '@agilicus/angular';
import {
  ActionApiApplicationsModifyCurrentApp,
  ActionApiApplicationsInitApplications,
} from '@app/core/api-applications/api-applications.actions';
import { cloneDeep } from 'lodash-es';
import { Organisation } from '@agilicus/angular';
import { selectOrganisations } from '@app/core/organisations/organisations.selectors';
import { ENTER, COMMA, I } from '@angular/cdk/keycodes';
import { ApplicationAssignment } from '@agilicus/angular';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { OrganisationsState } from '@app/core/organisations/organisations.models';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import {
  selectApiApplicationsList,
  selectApiCurrentApplication,
  selectApiCurrentApplicationBundlesList,
  selectApiCurrentEnvironment,
} from '@app/core/api-applications/api-applications.selectors';
import { AppBundle } from '@app/core/api/applications/app-bundle';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { FilterChipOptions } from '../filter-chip-options';
import { validateFqdnAliasValues } from '../validation-utils';
import { ApplicationEnvironmentExternalMountsComponent } from '../application-environment-external-mounts/application-environment-external-mounts.component';
import { ApplicationEnvironmentFilesComponent } from '../application-environment-files/application-environment-files.component';
import { ApplicationEnvironmentTemporaryDirectoriesComponent } from '../application-environment-temporary-directories/application-environment-temporary-directories.component';
import { ApplicationEnvironmentVariablesComponent } from '../application-environment-variables/application-environment-variables.component';
import { getSmallScreenSizeBreakpoint, getTabHeaderPositionFromScreenSize } from '../tab-utils';
import { TabHeaderPosition } from '../tab-header-position.enum';
import { selectUI } from '@app/core/ui/ui.selectors';
import { TabGroup, UIState } from '@app/core/ui/ui.models';
import { ActionUIInitConfigureInstanceUIState } from '@app/core/ui/ui.actions';
import { FeatureFlagService } from '@app/core/feature-flag/feature-flag.service';

@Component({
  selector: 'portal-application-environment',
  templateUrl: './application-environment.component.html',
  styleUrls: ['../../../../styles-tab-component.scss', './application-environment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationEnvironmentComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private org_id: string;
  public applications: Array<Application>;
  public currentApplicationCopy: Application;
  public currentEnvironment: Environment;
  public envForm: UntypedFormGroup;
  private orgsState$: Observable<OrganisationsState>;
  public allOrganisations: Array<Organisation>;
  private orgIdToOrgNameMap: Map<string, string> = new Map();
  private orgNameToOrgIdMap: Map<string, string> = new Map();
  public appBundles: Array<AppBundle>;
  private appBundleLabelToIdMap: Map<string, string> = new Map();
  private appBundleIdToLabelMap: Map<string, string> = new Map();
  public fixedData = false;
  private canOrgCreateHostedApplications = false;
  private doesOrgUseLuaWaf = false;

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  public filteredOrgOptions$: Observable<Array<string>>;
  public currentAssignments: Array<ApplicationAssignment>;
  public instanceSelectorForm: UntypedFormGroup;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  @ViewChild(ApplicationEnvironmentExternalMountsComponent)
  public applicationEnvironmentExternalMounts: ApplicationEnvironmentExternalMountsComponent;
  @ViewChild(ApplicationEnvironmentFilesComponent) public applicationEnvironmentFiles: ApplicationEnvironmentFilesComponent;
  @ViewChild(ApplicationEnvironmentTemporaryDirectoriesComponent)
  public applicationEnvironmentTemporaryDirectories: ApplicationEnvironmentTemporaryDirectoriesComponent;
  @ViewChild(ApplicationEnvironmentVariablesComponent) public applicationEnvironmentVariables: ApplicationEnvironmentVariablesComponent;

  public isSmallScreen = false;
  private smallScreenSizeBreakpoint = getSmallScreenSizeBreakpoint();

  public uiState: UIState;
  public tabsLength = 5;
  public tabGroupId = TabGroup.appConfigureInstanceTabGroup;
  public localTabIndex: number;

  // This is required in order to reference the enums in the html template.
  public tabGroup = TabGroup;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private routerHelperService: RouterHelperService,
    private customValidatorsService: CustomValidatorsService,
    private featureFlagService: FeatureFlagService
  ) {}

  @HostListener('window:resize', ['$event'])
  private onResize(event: Event): void {
    this.doResize();
  }

  public ngOnInit(): void {
    // Scroll to top of page when component is loaded.
    this.scrollToTop();
    this.isSmallScreen = window.innerWidth < this.smallScreenSizeBreakpoint;
    this.doResize();
    this.store.dispatch(new ActionApiApplicationsInitApplications());
    this.store.dispatch(new ActionUIInitConfigureInstanceUIState());
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const applicationsListState$ = this.store.pipe(select(selectApiApplicationsList));
    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const currentEnvState$ = this.store.pipe(select(selectApiCurrentEnvironment));
    const currentApplicationBundlesListState$ = this.store.pipe(select(selectApiCurrentApplicationBundlesList));
    this.orgsState$ = this.store.pipe(select(selectOrganisations));
    const uiState$ = this.store.pipe(select(selectUI));
    const canOrgCreateHostedApplications$ = this.featureFlagService.canOrgCreateHostedApplications$();
    const doesOrgUseLuaWaf$ = this.featureFlagService.doesOrgUseLuaWaf$();
    combineLatest([
      orgId$,
      applicationsListState$,
      currentAppState$,
      currentEnvState$,
      currentApplicationBundlesListState$,
      this.orgsState$,
      uiState$,
      canOrgCreateHostedApplications$,
      doesOrgUseLuaWaf$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          orgIdResp,
          applicationsListStateResp,
          currentAppStateResp,
          currentEnvStateResp,
          currentApplicationBundlesListStateResp,
          orgsStateResp,
          uiStateResp,
          canOrgCreateHostedApplicationsResp,
          doesOrgUseLuaWafResp,
        ]) => {
          this.org_id = orgIdResp;
          this.canOrgCreateHostedApplications = canOrgCreateHostedApplicationsResp;
          this.doesOrgUseLuaWaf = doesOrgUseLuaWafResp;
          this.doWhenAppsDataIsLoaded(
            applicationsListStateResp,
            currentAppStateResp,
            currentEnvStateResp,
            currentApplicationBundlesListStateResp
          );
          this.doWhenOrgsDataIsLoaded(orgsStateResp);
          this.doWhenUIStateIsLoaded(uiStateResp);
          if (
            currentAppStateResp === undefined ||
            currentEnvStateResp === undefined ||
            currentApplicationBundlesListStateResp === undefined ||
            orgsStateResp === undefined ||
            orgsStateResp.all_organisations === undefined ||
            orgsStateResp.org_id_to_org_name_map === undefined ||
            orgsStateResp.org_name_to_org_id_map === undefined
          ) {
            return;
          }
          this.doWhenAllDataIsLoaded();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private doWhenAllDataIsLoaded(): void {
    this.currentAssignments = this.getCurrentAssignments();
    this.fixedData = !this.isEnvironmentEditable();
    this.initializeFormGroups(this.currentEnvironment);
    this.filteredOrgOptions$ = setAutocomplete(
      this.envForm.get('organisations'),
      this.allOrganisations.map((org) => org.organisation)
    );
    setFormControlEnableState(['organisations', 'serverless_image', 'domain_aliases'], this.envForm, this.isEnvironmentEditable());
    // Currently, set to false to disable selection of the maintenance org.
    // This functionality will enabled at a later date.
    setFormControlEnableState(['maintenance_org_name'], this.envForm, false);
  }

  private doWhenAppsDataIsLoaded(
    applicationsListStateResp: Array<Application>,
    currentAppStateResp: Application,
    currentEnvStateResp: Environment,
    currentApplicationBundlesListStateResp: Array<FileSummary>
  ): void {
    if (currentAppStateResp === undefined) {
      return;
    }
    this.applications = applicationsListStateResp;
    this.currentApplicationCopy = cloneDeep(currentAppStateResp);
    this.currentEnvironment = currentEnvStateResp;
    this.appBundles = currentApplicationBundlesListStateResp;
    this.setAppBundleMaps(this.appBundles);
  }

  private doWhenUIStateIsLoaded(uiStateResp: UIState): void {
    if (!uiStateResp) {
      return;
    }
    if (!this.localTabIndex) {
      // We only want to set the localTabIndex to the index from the UI State
      // when first loading the page. Otherwise, multiple quick changes to the index
      // would result in the previous UI state value overwriting the currently
      // selected local value before the UI State is updated to match the current local value.
      this.localTabIndex = uiStateResp.tabsState.tabs[this.tabGroupId];
    }
    this.uiState = uiStateResp;
  }

  private doWhenOrgsDataIsLoaded(orgsStateResp: OrganisationsState): void {
    if (orgsStateResp === undefined) {
      return;
    }
    this.allOrganisations = orgsStateResp.all_organisations;
    this.orgIdToOrgNameMap = orgsStateResp.org_id_to_org_name_map;
    this.orgNameToOrgIdMap = orgsStateResp.org_name_to_org_id_map;
  }

  private initializeFormGroups(env: Environment): void {
    this.initializeInstanceSelectorFormGroup(env);
    this.initializeEnvFormGroup(env);
  }

  public initializeInstanceSelectorFormGroup(env: Environment): void {
    this.instanceSelectorForm = this.formBuilder.group({
      instance_selection: {
        value: env.version_tag,
        disabled: !this.currentApplicationCopy?.environments || this.currentApplicationCopy.environments.length === 0,
      },
    });
    this.changeDetector.detectChanges();
  }

  private initializeEnvFormGroup(env: Environment): void {
    this.envForm = this.formBuilder.group({
      version_tag: [
        {
          value: getEmptyStringIfUnset(env.version_tag),
          disabled: this.fixedData,
        },
        [Validators.required, Validators.maxLength(40)],
      ],
      maintenance_org_name: [
        getEmptyStringIfUnset(useValueIfNotInMap(env.maintenance_org_id, this.orgIdToOrgNameMap)),
        Validators.required,
      ],
      // The organisations the env is assigned to.
      organisations: '',
      serverless_image: getEmptyStringIfUnset(this.appBundleIdToLabelMap.get(env.serverless_image)),
      domain_aliases: ['', this.customValidatorsService.hostnameValidator()],
      proxy_location: [
        {
          value: getEmptyStringIfUnset(env.proxy_location),
          disabled: this.fixedData,
        },
        [Validators.required],
      ],
    });
    this.changeDetector.detectChanges();
  }

  public onFormBlur(form: UntypedFormGroup, formField: string): void {
    modifyDataOnFormBlur(form, formField, this.modifyApplicationOnFormBlur.bind(this));
  }

  private modifyApplicationOnFormBlur(): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const env of copyOfCurrentApplicationCopy.environments) {
      if (env.name === this.currentEnvironment.name) {
        this.setEnvFromForm(env);
        this.modifyApplication(copyOfCurrentApplicationCopy);
      }
    }
  }

  public modifyApplicationOnFormSelectionChange(formField: string): void {
    if (this.envForm.controls[formField].invalid) {
      return;
    }
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const env of copyOfCurrentApplicationCopy.environments) {
      if (env.name === this.currentEnvironment.name) {
        this.setEnvFromForm(env);
        this.modifyApplication(copyOfCurrentApplicationCopy);
      }
    }
  }

  private setEnvFromForm(environment: Environment): void {
    const versionTagFormValue = this.envForm.get('version_tag').value;
    environment.version_tag = versionTagFormValue.trim();
    const maintenanceOrgIdFormValue = this.envForm.get('maintenance_org_name').value;
    environment.maintenance_org_id = useValueIfNotInMap(maintenanceOrgIdFormValue.trim(), this.orgNameToOrgIdMap);
    this.setServerlessImageFromForm(environment);
    const proxyLocationFormValue = this.envForm.get('proxy_location').value;
    if (!!proxyLocationFormValue) {
      environment.proxy_location = proxyLocationFormValue;
    }
  }

  private setServerlessImageFromForm(environment: Environment): void {
    const serverlessImageFormValue = this.envForm.get('serverless_image').value;
    const trimmedServerlessImageFormValue = serverlessImageFormValue.trim();
    if (trimmedServerlessImageFormValue === '') {
      environment.serverless_image = '';
      return;
    }
    const appBundleId = this.appBundleLabelToIdMap.get(trimmedServerlessImageFormValue);
    if (!appBundleId) {
      return;
    }
    environment.serverless_image = appBundleId;
  }

  private modifyApplication(updatedApp: Application): void {
    this.currentApplicationCopy = updatedApp;
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  public isAppEditable(): boolean {
    if (this.applications.length === 0 || !this.currentApplicationCopy.owned) {
      return false;
    }
    return true;
  }

  public isEnvironmentEditable(): boolean {
    return this.isAppEditable();
  }

  private getCurrentAssignments(): Array<ApplicationAssignment> {
    const assignments = [];
    for (const assignment of this.currentApplicationCopy.assignments) {
      if (assignment.environment_name === this.currentEnvironment.name) {
        assignments.push(assignment);
      }
    }
    return assignments;
  }

  private addAppAssignment(newAssignment: ApplicationAssignment): boolean {
    if (isOrgAlreadyAssigned(this.currentApplicationCopy.assignments, newAssignment)) {
      const orgName = useValueIfNotInMap(newAssignment.org_id, this.orgIdToOrgNameMap);
      this.notificationService.error('"' + orgName + '" has already been assigned to an instance for this application.');
      return false;
    }
    this.currentApplicationCopy.assignments.push(newAssignment);
    this.currentAssignments.push(newAssignment);
    return true;
  }

  private removeAppAssignment(assignmentToRemove: ApplicationAssignment): void {
    const updatedAssignments: Array<ApplicationAssignment> = [];
    for (const assignment of this.currentApplicationCopy.assignments) {
      if (assignment.environment_name !== assignmentToRemove.environment_name || assignment.org_id !== assignmentToRemove.org_id) {
        updatedAssignments.push(assignment);
      }
    }
    this.currentApplicationCopy.assignments = updatedAssignments;
    this.currentAssignments = this.getCurrentAssignments();
  }

  private addChip(value: string): boolean {
    const targetOrgId = useValueIfNotInMap(value, this.orgNameToOrgIdMap);
    const newAssignment: ApplicationAssignment = {
      environment_name: this.currentEnvironment.name,
      org_id: targetOrgId,
    };
    return this.addAppAssignment(newAssignment);
  }

  /**
   * Adds a new chip to the chips input when an autocomplete option is clicked.
   */
  public addChipOnAutoSelect(optionValue: string): void {
    const appUpdated = this.addChip(optionValue);
    if (appUpdated) {
      this.modifyApplication(this.currentApplicationCopy);
    }
  }

  /**
   * Adds a new chip to the chips input when the user enters a 'separatorKeysCode'.
   * @param event contains the values typed by the user
   */
  public addAssignmentChipOnInputEvent(event: MatChipInputEvent): void {
    const input = event.input;
    const valuesArray = getChipValuesOnInput(event, this.orgNameToOrgIdMap, this.notificationService);
    if (!valuesArray) {
      return;
    }
    for (const item of valuesArray) {
      const appUpdated = this.addChip(item);
      if (!appUpdated) {
        return;
      }
    }
    this.modifyApplication(this.currentApplicationCopy);
    // Resets the input value so that the next chip to be entered starts as empty.
    if (input) {
      input.value = '';
    }
  }

  public removeAssignmentChip(chipValue: string): void {
    const targetOrgId = useValueIfNotInMap(chipValue, this.orgNameToOrgIdMap);
    const assignmentToRemove: ApplicationAssignment = {
      environment_name: this.currentEnvironment.name,
      org_id: targetOrgId,
    };
    this.removeAppAssignment(assignmentToRemove);
    this.modifyApplication(this.currentApplicationCopy);
  }

  public getOrgNameFromId(org_id: string): string {
    return useValueIfNotInMap(org_id, this.orgIdToOrgNameMap);
  }

  /**
   * Scrolls to the top of the page.
   */
  public scrollToTop(): void {
    scrollToTop();
  }

  /**
   * Checks if the option from the autocomplete dropdown has already been
   * added to the chiplist.
   * @param option is the value of the dropdown option.
   */
  public isOptionAlreadySelected(option: string): boolean {
    for (const assignment of this.currentAssignments) {
      if (this.getOrgNameFromId(assignment.org_id) === option) {
        return true;
      }
    }
    return false;
  }

  public returnToApp(): void {
    this.routerHelperService.routeToAppView(this.currentApplicationCopy.id, {
      org_id: this.org_id,
    });
  }

  private setAppBundleMaps(appBundles: Array<AppBundle>): void {
    this.appBundleIdToLabelMap.clear();
    this.appBundleLabelToIdMap.clear();
    appBundles.forEach((appBundle) => {
      this.appBundleIdToLabelMap.set(appBundle.id, appBundle.label);
      this.appBundleLabelToIdMap.set(appBundle.label, appBundle.id);
    });
  }

  public getCurrentEnvFqdnAliases(): Array<string> {
    if (!!this.currentEnvironment?.domain_aliases) {
      return this.currentEnvironment.domain_aliases;
    }
    return [];
  }

  public removeFqdnAliasChip(chipValue: string): void {
    const newDomainNames = this.getCurrentEnvFqdnAliases().filter((hostname) => hostname !== chipValue);
    this.updateEnvFqdnAliases(newDomainNames);
    this.modifyApplication(this.currentApplicationCopy);
  }

  public async addFqdnAliasOnInputEvent(event: MatChipInputEvent): Promise<void> {
    const input = event.input;
    const validateFqdnAliasValuesResult = await validateFqdnAliasValues(
      event,
      this.getCurrentEnvFqdnAliases(),
      this.notificationService,
      this.customValidatorsService
    );
    if (!validateFqdnAliasValuesResult.isValid) {
      if (!!validateFqdnAliasValuesResult.message) {
        this.notificationService.error(validateFqdnAliasValuesResult.message);
      }
      return;
    }
    const valuesArray = getValuesArray(event, this.getCurrentEnvFqdnAliases(), this.notificationService);
    const newFqdnAliases = [...this.getCurrentEnvFqdnAliases(), ...valuesArray];
    this.updateEnvFqdnAliases(newFqdnAliases);
    this.modifyApplication(this.currentApplicationCopy);
    // Resets the input value so that the next chip to be entered starts as empty
    if (input) {
      input.value = '';
    }
  }

  private updateEnvFqdnAliases(newFqdnAliases: Array<string>): void {
    for (const env of this.currentApplicationCopy.environments) {
      if (env.name === this.currentEnvironment.name) {
        env.domain_aliases = newFqdnAliases;
      }
    }
  }

  public onProxyLocationOptionChange(selectedProxyLocationValue: Environment.ProxyLocationEnum): void {
    this.envForm.get('proxy_location').setValue(selectedProxyLocationValue);
    this.modifyApplicationOnFormBlur();
  }

  public isUsingAgent(): boolean {
    if (!this.currentEnvironment?.application_services) {
      return false;
    }
    for (const appService of this.currentEnvironment.application_services) {
      if (appService.service_type === ApplicationService.ServiceTypeEnum.agent) {
        return true;
      }
    }
    return false;
  }

  public canDeactivate(): Observable<boolean> | boolean {
    const applicationEnvironmentExternalMountsValidate = this?.applicationEnvironmentExternalMounts
      ? this.applicationEnvironmentExternalMounts.canDeactivate()
      : true;
    const applicationEnvironmentFilesValidate = this?.applicationEnvironmentFiles ? this.applicationEnvironmentFiles.canDeactivate() : true;
    const applicationEnvironmentTemporaryDirectories = this?.applicationEnvironmentTemporaryDirectories
      ? this.applicationEnvironmentTemporaryDirectories.canDeactivate()
      : true;
    const applicationEnvironmentVariables = this?.applicationEnvironmentVariables
      ? this.applicationEnvironmentVariables.canDeactivate()
      : true;
    return (
      applicationEnvironmentExternalMountsValidate &&
      applicationEnvironmentFilesValidate &&
      applicationEnvironmentTemporaryDirectories &&
      applicationEnvironmentVariables
    );
  }

  public returnToOverview(): void {
    this.routerHelperService.redirect('application-overview/', {
      org_id: this.org_id,
    });
  }

  private doResize(): void {
    if (window.innerWidth < this.smallScreenSizeBreakpoint) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  public getTabHeaderPositionFromScreenSizeFunc(): TabHeaderPosition {
    return getTabHeaderPositionFromScreenSize(this.isSmallScreen);
  }

  public getInstancesList(): Array<Environment> {
    if (!this.currentApplicationCopy.environments) {
      return [];
    }
    return this.currentApplicationCopy.environments;
  }

  public getInstancesVersionTagList(): Array<string> {
    const instancesList = this.getInstancesList();
    return instancesList.map((instance) => instance.version_tag);
  }

  public selectCurrentInstance(selectedEnvVersionTag: string): void {
    const selectedEnv = this.getEnvFromVersionTag(selectedEnvVersionTag);
    this.routerHelperService.redirect(this.getUpdatedInstancePath(selectedEnv), {
      org_id: this.org_id,
    });
  }

  private getUpdatedInstancePath(instance: Environment): string {
    const pathname = window.location.pathname;
    const pathnameArray = pathname.split('/');
    pathnameArray.pop();
    return `${pathnameArray.join('/')}/${instance.name}`;
  }

  private getEnvFromVersionTag(envVersionTag: string): Environment {
    for (const env of this.currentApplicationCopy.environments) {
      if (env.version_tag === envVersionTag) {
        return env;
      }
    }
    return undefined;
  }

  public getInstanceSelectorTooltipText(): string {
    return `This is the version tag of the current instance you are viewing below for application "${this.currentApplicationCopy.name}". 
    You can view/modify a different instance by selecting one from this list.`;
  }

  public isLuaWafEnabled(): boolean {
    return this.doesOrgUseLuaWaf;
  }

  public isHostedApplicationsEnabled(): boolean {
    return this.canOrgCreateHostedApplications;
  }
}
