import { cloneDeep } from 'lodash-es';
import { findParamSpecificSearchOptionByNameAndDisplayValue, removeFromList } from '../filter-utils';
import { FilterOption } from '../filter/filter-manager';

export class ParamSpecificSearchOption extends FilterOption {
  public doParamSpecificSearchFilter: (value?: any) => void;
  public allowMultipleValues? = false;
}

export class ParamSpecificFilterManager {
  public paramSpecificSearchOptions: Array<ParamSpecificSearchOption> = [];

  public addParamSpecificSearchFilterOption(paramSpecificSearchOption: ParamSpecificSearchOption): void {
    this.paramSpecificSearchOptions.push(paramSpecificSearchOption);
  }

  public addParamSpecificFilterOptionWithValueToFilterBar(
    value: string,
    paramSpecificSearchOption: ParamSpecificSearchOption,
    filterBar: Array<FilterOption>
  ): void {
    const trimmedValue = (value || '').trim();
    if (!trimmedValue) {
      return;
    }
    if (!!paramSpecificSearchOption.allowMultipleValues) {
      const paramSpecificSearchOptionCopy = cloneDeep(paramSpecificSearchOption);
      paramSpecificSearchOptionCopy.displayName = value;
      paramSpecificSearchOptionCopy.doParamSpecificSearchFilter(value);
      filterBar.push(paramSpecificSearchOptionCopy);
      return;
    }
    paramSpecificSearchOption.displayName = value;
    paramSpecificSearchOption.doParamSpecificSearchFilter(value);
    if (filterBar.map((option) => option.label).includes(paramSpecificSearchOption.label)) {
      // Do not add the option to the filter bar if it already exists.
      // We just want to change the value in this case.
      return;
    }
    filterBar.push(paramSpecificSearchOption);
  }

  public getParamSpecificSearchOptions(label: string): Array<ParamSpecificSearchOption> {
    let filteredArray: Array<ParamSpecificSearchOption>;
    if (!label) {
      filteredArray = this.paramSpecificSearchOptions;
    } else {
      filteredArray = this.paramSpecificSearchOptions.filter((option) => option.label === label);
    }
    return filteredArray.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }

  public filterSearchDelay(event: { inputValue: string; option: ParamSpecificSearchOption; filterBar: Array<FilterOption> }): void {
    if (!!event.inputValue) {
      this.addParamSpecificFilterOptionWithValueToFilterBar(event.inputValue, event.option, event.filterBar);
    } else {
      this.removeParamSpecificSearchOptionFromFilterBar(event.option, event.filterBar);
    }
  }

  public getAllParamSpecificSearchOptionsWithValues(): Array<ParamSpecificSearchOption> {
    const allParamSpecificSearchOptions = this.getParamSpecificSearchOptions(null);
    return allParamSpecificSearchOptions.filter((option) => option.displayName !== '');
  }

  /**
   * This function is triggered by removing chips from the filter bar
   */
  public removeParamSpecificSearchOptionFromFilterBar(filterOption: ParamSpecificSearchOption, filterBar: Array<FilterOption>): void {
    const cachedDisplayName = filterOption.displayName;
    const targetFilterOption = findParamSpecificSearchOptionByNameAndDisplayValue(filterOption, filterBar);
    const targetFilterOptionAsParamSpecificSearchOption = targetFilterOption as ParamSpecificSearchOption;
    targetFilterOptionAsParamSpecificSearchOption.displayName = '';
    removeFromList(targetFilterOptionAsParamSpecificSearchOption, filterBar);
    if (!!targetFilterOptionAsParamSpecificSearchOption.allowMultipleValues) {
      targetFilterOptionAsParamSpecificSearchOption.doParamSpecificSearchFilter(cachedDisplayName);
    } else {
      targetFilterOptionAsParamSpecificSearchOption.doParamSpecificSearchFilter('');
    }
  }
}
