import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { map, Observable, of } from 'rxjs';
import { FeatureFlag, Organisation } from '@agilicus/angular';
import { selectCurrentOrganisation } from '../organisations/organisations.selectors';
import { DynamicEnvironmentService } from '../services/dynamic-environment.init';

export enum FeatureFlagTypesEnum {
  self_creates_suborg = 'self_creates_suborg',
  suborg_creates_issuer = 'suborg_creates_issuer',
  hosted_applications = 'hosted_applications',
  policy_rules = 'policy_rules',
  lua_waf = 'lua_waf',
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  constructor(private store: Store<AppState>, private envService: DynamicEnvironmentService) {}

  private getOrgFeatureFlagsMap(org: Organisation | undefined): Map<string, FeatureFlag> {
    const featureFlagsMap = new Map();
    const featureFlags = !!org?.feature_flags ? org.feature_flags : [];
    for (const featureFlag of featureFlags) {
      featureFlagsMap.set(featureFlag.feature, featureFlag);
    }
    return featureFlagsMap;
  }

  /**
   * Off if not set
   */
  public canOrgCreateNewCustomerSubOrg$(): Observable<boolean> {
    if (!!this.envService.environment?.overrideFeatureFlags) {
      // Enable feature flag when testing
      return of(true);
    }
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    return currentOrg$.pipe(
      map((currentOrgResp) => {
        const featureFlagsMap = this.getOrgFeatureFlagsMap(currentOrgResp);
        const selfCreatesSuborgFeatureFlag = featureFlagsMap.get(FeatureFlagTypesEnum.self_creates_suborg);
        return !!selfCreatesSuborgFeatureFlag?.enabled;
      })
    );
  }

  /**
   * Off if not set
   */
  public canOrgCreateNewIssuer$(): Observable<boolean> {
    if (!!this.envService.environment?.overrideFeatureFlags) {
      // Enable feature flag when testing
      return of(true);
    }
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    return currentOrg$.pipe(
      map((currentOrgResp) => {
        const featureFlagsMap = this.getOrgFeatureFlagsMap(currentOrgResp);
        const suborgCreatesIssuerFeatureFlag = featureFlagsMap.get(FeatureFlagTypesEnum.suborg_creates_issuer);
        return !!suborgCreatesIssuerFeatureFlag?.enabled;
      })
    );
  }

  /**
   * Off if not set
   */
  public canOrgCreateHostedApplications$(): Observable<boolean | undefined> {
    if (!!this.envService.environment?.overrideFeatureFlags) {
      // Enable feature flag when testing
      return of(true);
    }
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    return currentOrg$.pipe(
      map((currentOrgResp) => {
        if (!currentOrgResp) {
          return undefined;
        }
        const featureFlagsMap = this.getOrgFeatureFlagsMap(currentOrgResp);
        const hostedApplicationsFeatureFlag = featureFlagsMap.get(FeatureFlagTypesEnum.hosted_applications);
        return !!hostedApplicationsFeatureFlag?.enabled;
      })
    );
  }

  /**
   * On if not set
   */
  public doesOrgUsePolicyRules$(): Observable<boolean> {
    if (!!this.envService.environment?.overrideFeatureFlags) {
      // Enable feature flag when testing
      return of(true);
    }
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    return currentOrg$.pipe(
      map((currentOrgResp) => {
        const featureFlagsMap = this.getOrgFeatureFlagsMap(currentOrgResp);
        const policyRulesFeatureFlag = featureFlagsMap.get(FeatureFlagTypesEnum.policy_rules);
        if (policyRulesFeatureFlag === undefined) {
          return true;
        }
        return policyRulesFeatureFlag.enabled;
      })
    );
  }

  /**
   * On if not set
   */
  public doesOrgUseLuaWaf$(): Observable<boolean> {
    if (!!this.envService.environment?.overrideFeatureFlags) {
      // Enable feature flag when testing
      return of(true);
    }
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    return currentOrg$.pipe(
      map((currentOrgResp) => {
        const featureFlagsMap = this.getOrgFeatureFlagsMap(currentOrgResp);
        const hostedApplicationsFeatureFlag = featureFlagsMap.get(FeatureFlagTypesEnum.hosted_applications);
        if (hostedApplicationsFeatureFlag?.enabled === false) {
          return false;
        }
        const luaWafFeatureFlag = featureFlagsMap.get(FeatureFlagTypesEnum.lua_waf);
        if (luaWafFeatureFlag === undefined) {
          return true;
        }
        return luaWafFeatureFlag.enabled;
      })
    );
  }
}
