<portal-page-info
  pageTitle="Audit Destinations"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>


<div *ngIf="hasPermissions !== undefined && !hasPermissions">
    <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions" class="auth-audit-container">

  <div class="config-container">

    <div class="config-label">
      <label> Enable streaming records to Agilicus API for: </label>
    </div>

    <mat-checkbox class="checkbox-container margin-right" 
      [(ngModel)]="agilicusAccess"
      (change)="checkBoxChanged()"
      aria-label="Checkbox that toggles whether the access is selected"
    >
    Access
    </mat-checkbox>

    <mat-checkbox class="checkbox-container"
      [(ngModel)]="agilicusAuthorization"
      (change)="checkBoxChanged()"
      aria-label="Checkbox that toggles whether the authorization is selected"
    >
    Authorization
    </mat-checkbox>
  </div>
    
  <portal-table-layout class="audit-destinations"
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [rowObjectName]="rowObjectName"
    (updateEvent)="updateEvent($event)"
    (updateSelection)="updateSelection($event)"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>