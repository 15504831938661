import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input, ViewChild } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import {
  UsersService,
  ListGroupsResponse,
  ListGroupsRequestParams,
  ListCombinedUserDetailsRequestParams,
  UserStatusEnum,
  ListCombinedUserDetailsResponse,
} from '@agilicus/angular';
import { User } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, forkJoin, Subscription, EMPTY, of } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, filter, take, expand, reduce, map } from 'rxjs/operators';
import { Group } from '@agilicus/angular';
import { GroupsService } from '@agilicus/angular';
import { copyTextToClipboard, getUserNameFromUser, sortArrayByKey } from '@app/shared/components/utils';
import { TableElement } from '../table-layout/table-element';
import { FilterManager, CheckboxOption } from '../filter/filter-manager';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createChipListColumn,
  ChiplistColumn,
  createActionsColumn,
  ActionMenuOptions,
} from '../table-layout/column-definitions';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { PaginatorActions, PaginatorConfig, UpdateTableParams } from '../table-paginator/table-paginator.component';
import { ParamSpecificSearchOptions } from '../param-specific-search-options.enum';
import { UsersFilterLabel } from '../user-filter-label.enum';
import { FilterType } from '../filter-type.enum';
import { ParamSpecificFilterManager } from '../param-specific-filter-manager/param-specific-filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { OptionalGroupElement } from '../optional-types';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { Description } from '../user-admin/user-admin.component';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { createNewGroup$, deleteGroup$, updateExistingGroup$ } from '@app/core/api/group-api-utils';

export interface MemberElement {
  member_id: string;
}

export interface GroupElement extends TableElement, Group, Description {}

export enum GroupsFilterLabel {
  NOLABEL = '',
  GROUP = 'Group Type',
}

export enum GroupsCheckboxOptions {
  BIGROUPS = 'Show builtin-groups',
}

enum GroupQueryType {
  group = 'group',
  sysgroup = 'sysgroup',
  bigroup = 'bigroup',
}

/**
 * Used for the chiplist dialog
 */
export function getGroupElementIdentifyingProperty(): string {
  return 'first_name';
}

@Component({
  selector: 'portal-group-admin',
  templateUrl: './group-admin.component.html',
  styleUrls: ['./group-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupAdminComponent implements OnInit, OnDestroy {
  @Input() public fixedTable = false;
  @Input() public selectable = true;
  @Input() public groupType: GroupQueryType = GroupQueryType.group;
  @Input() public title = 'Groups';
  @Input() public hideGroupsList: Array<string> = [];
  @Input() public pageDescriptiveText = 'Groups behave as users for assigning permissions and can nest. Create groups per role.';
  public productGuideLink = `https://www.agilicus.com/anyx-guide/groups/`;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<GroupElement>> = new Map();
  private orgId: string;
  private usersSubscription: Subscription = new Subscription();
  private groups$: Observable<ListGroupsResponse>;
  public tableData: Array<GroupElement> = [];
  private displayNameToUserMap: Map<string, User> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public paramSpecificFilterManager: ParamSpecificFilterManager = new ParamSpecificFilterManager();
  public rowObjectName = 'GROUP';
  public componentTitle = 'Groups';
  public hasGroupsPermissions: boolean;
  public showBigroups = false;
  public linkDataSource = false;
  public useBackendFilter = true;
  public paginatorConfig = new PaginatorConfig<GroupElement>(true, true, 25, 5, new PaginatorActions<GroupElement>(), 'email', {
    previousKey: '',
    nextKey: '',
    previousWindow: [],
  });
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'bigroups',
      {
        name: 'bigroups',
        displayName: GroupsFilterLabel.GROUP,
        icon: 'groups',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH,
      {
        name: ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH,
        displayName: UsersFilterLabel.EMAIL,
        icon: 'email',
        type: FilterMenuOptionType.text,
      },
    ],
    [
      ParamSpecificSearchOptions.FIRST_NAME,
      {
        name: ParamSpecificSearchOptions.FIRST_NAME,
        displayName: UsersFilterLabel.GROUP_NAME,
        icon: 'person_search',
        type: FilterMenuOptionType.text,
      },
    ],
  ]);
  private cachedAllUsers: Array<User>;

  @Input() public getCustomTooltip: (element: OptionalGroupElement) => string = (element: OptionalGroupElement) => element.first_name;

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<GroupElement>;

  constructor(
    private usersService: UsersService,
    private groupsService: GroupsService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private appErrorHandler: AppErrorHandler
  ) {
    this.filterManager.addCheckboxFilterOption({
      name: GroupsCheckboxOptions.BIGROUPS,
      displayName: GroupsCheckboxOptions.BIGROUPS,
      label: this.filterMenuOptions.get('bigroups').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleShowBigroups.bind(this),
    });

    this.paramSpecificFilterManager.addParamSpecificSearchFilterOption({
      name: ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH,
      displayName: '',
      label: this.filterMenuOptions.get(ParamSpecificSearchOptions.PREFIX_EMAIL_SEARCH).displayName,
      type: FilterType.PARAM_SPECIFIC_SEARCH,
      doParamSpecificSearchFilter: this.reloadFirstPage.bind(this),
    });

    this.paramSpecificFilterManager.addParamSpecificSearchFilterOption({
      name: ParamSpecificSearchOptions.FIRST_NAME,
      displayName: '',
      label: this.filterMenuOptions.get(ParamSpecificSearchOptions.FIRST_NAME).displayName,
      type: FilterType.PARAM_SPECIFIC_SEARCH,
      doParamSpecificSearchFilter: this.reloadFirstPage.bind(this),
    });
  }

  public ngOnInit(): void {
    this.componentTitle = this.title;
    this.initializeColumnDefs();
    const permissions$ = this.store.pipe(select(selectCanAdminUsers));
    permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((hasPermissionsResp) => {
      this.orgId = hasPermissionsResp?.orgId;
      this.hasGroupsPermissions = hasPermissionsResp?.hasPermission;
      if (!this.orgId || !this.hasGroupsPermissions) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      if (!this.groups$) {
        // we are fetching data for the first time
        this.updateTable();
        this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
          this.updateTable(params.key, params.searchDirection, params.limit);
        });
      } else {
        // this case could happen when the org is changed - we don't want to subscribe to updateTableSubject again
        this.reloadFirstPage();
      }
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getFirstNameColumn(): Column<GroupElement> {
    const firstNameColumn = createInputColumn('first_name');
    firstNameColumn.displayName = 'Group Name';
    firstNameColumn.requiredField = () => true;
    firstNameColumn.isEditable = !this.fixedTable;
    firstNameColumn.isUnique = true;
    firstNameColumn.getTooltip = (element: OptionalGroupElement) => {
      return this.getCustomTooltip(element);
    };
    return firstNameColumn;
  }

  private getDescriptionColumn(): Column<GroupElement> {
    const descriptionColumn = createInputColumn('description');
    descriptionColumn.displayName = 'Description';
    descriptionColumn.isEditable = true;
    descriptionColumn.isCaseSensitive = true;
    descriptionColumn.getDisplayValue = (element: OptionalGroupElement) => {
      if (element.inheritable_status?.description) {
        return element.inheritable_status.description;
      }
      return '';
    };
    return descriptionColumn;
  }

  private getMembersColumn(): ChiplistColumn<GroupElement> {
    const membersColumn = createChipListColumn('members');
    membersColumn.displayName = 'Users';
    membersColumn.getDisplayValue = (member: User) => {
      return member.display_name;
    };
    membersColumn.getElementFromValue = (displayName: string): any => {
      return this.displayNameToUserMap.get(displayName);
    };
    membersColumn.getTooltip = (member: any) => {
      let tooltipText = getUserNameFromUser(member);
      return !!tooltipText ? `${tooltipText} : ${member.id}` : member.id;
    };
    membersColumn.formControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((input) => input !== null),
        debounceTime(350), // allow for some delay so we don't make api calls on every keyup, only the last value is returned after 350ms
        distinctUntilChanged() // only make api calls if the latest value is different from the previous value
      )
      .subscribe((input: string) => {
        // cancel old subscription
        if (!this.usersSubscription.closed) {
          this.usersSubscription.unsubscribe();
        }
        // get users whose email prefix matches the input
        this.usersSubscription = this.usersService
          .listUsers({ org_id: this.orgId, prefix_email_search: input, limit: 50 })
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (usersResp) => {
              this.columnDefs.get('members').allowedValues.length = 0;
              usersResp.users.forEach((user) => {
                if (user.enabled) {
                  this.displayNameToUserMap.set(user.display_name, user);
                  this.columnDefs.get('members').allowedValues.push(user);
                }
              });
              // reset the form control so it rebuilds the list of allowed members
              this.columnDefs.get('members').formControl.reset();
            },
            (error) => {
              this.paginatorConfig.actions.errorHandler(error);
              this.notificationService.error('Failed to load users');
            }
          );
      });
    membersColumn.getCustomAllowedValuesFromApi = this.getAllUsersAsUsers.bind(this);
    return membersColumn;
  }

  private getActionsColumn(): Column<GroupElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<GroupElement>> = [
      {
        displayName: 'Copy Group Email',
        icon: 'content_copy',
        tooltip: 'Click to copy the group email to clipboard',
        onClick: (element: OptionalGroupElement) => {
          const groupEmail = element.email;
          copyTextToClipboard(groupEmail);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();
    const firstNameColumn = this.getFirstNameColumn();
    const descriptionColumn = this.getDescriptionColumn();
    const membersColumn = this.getMembersColumn();
    const actionsColumn = this.getActionsColumn();

    // Set the key/values for the column definitions map
    if (this.selectable) {
      this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    }
    this.columnDefs.set(firstNameColumn.name, firstNameColumn);
    this.columnDefs.set(descriptionColumn.name, descriptionColumn);
    this.columnDefs.set(membersColumn.name, membersColumn);
    this.columnDefs.set(actionsColumn.name, actionsColumn);
  }

  public updateTable(
    emailKey = '',
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    const initialParams = this.getParams(emailKey, searchDirectionParam, limitParam);
    const allParams = this.mergeAdditionalGroupFilterParams(initialParams, this.getAdditionalGroupFilterParams());
    this.groups$ = this.groupsService.listGroups(allParams);
    this.groups$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (groupsResp) => {
        let filteredGroups = groupsResp.groups;
        for (const groupToHide of this.hideGroupsList) {
          filteredGroups = filteredGroups.filter((group) => group.first_name !== groupToHide);
        }
        this.buildData(filteredGroups);
        this.replaceTableWithCopy();
        this.paginatorConfig.actions.dataFetched({
          data: this.tableData,
          searchDirection: searchDirectionParam,
          limit: limitParam,
          nextKey: groupsResp.next_page_email,
          previousKey: groupsResp.previous_page_email,
        });
      },
      (error) => {
        this.paginatorConfig.actions.errorHandler(error);
      }
    );
  }

  public reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorConfig.actions.reloadFirstPage();
  }

  private reloadWindow(): void {
    this.paginatorConfig.actions.reloadWindow();
  }

  private createGroupElement(group: Group, index: number): GroupElement {
    const data: GroupElement = {
      ...getDefaultTableProperties(index),
      elementIdentifyingProperty: getGroupElementIdentifyingProperty(),
    };
    for (const key of Object.keys(group)) {
      if (key === 'members') {
        data[key] = sortArrayByKey(group[key], 'email');
      } else {
        data[key] = group[key];
      }
    }
    return data;
  }

  private buildData(groupsList: Array<Group>): void {
    this.tableData = [];
    for (let i = 0; i < groupsList.length; i++) {
      this.tableData.push(this.createGroupElement(groupsList[i], i));
    }
  }

  private updateGroupElement(groupElement: GroupElement, groupResp: Group): void {
    for (const key of Object.keys(groupResp)) {
      groupElement[key] = groupResp[key];
    }
    this.columnDefs.get('members').allowedValues.length = 0;
  }

  private postGroup(updatedGroup: GroupElement): void {
    createNewGroup$(this.groupsService, updatedGroup)
      .pipe(take(1))
      .subscribe(
        (groupResp) => {
          this.notificationService.success('New group "' + groupResp.first_name + '" was successfully created');
          updatedGroup.isNew = false;
          this.updateGroupElement(updatedGroup, groupResp);
        },
        (errorResp) => {
          this.notificationService.error('Failed to create group');
        }
      );
  }

  private putGroup(updatedGroup: GroupElement): void {
    updateExistingGroup$(this.groupsService, updatedGroup)
      .pipe(take(1))
      .subscribe(
        (groupResp) => {
          this.notificationService.success('Group "' + updatedGroup.first_name + '" was successfully updated');
          this.updateGroupElement(updatedGroup, groupResp);
        },
        (errorResp) => {
          const baseMessage = 'Failed to update group "' + updatedGroup.first_name + '"';
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        }
      );
  }

  private checkIfValidGroupName(updatedGroup: Group): boolean {
    return !updatedGroup.first_name.includes(';');
  }

  private notifyInvalidGroupName(updatedGroup: GroupElement): void {
    this.notificationService.error('"' + updatedGroup.first_name + '" is not a valid group name.');
  }

  /**
   * Receives a GroupElement and checks it for validity.
   * It either submits the new data via an api call
   * or notifies the user of an error.
   */
  public updateEvent(updatedGroup: GroupElement): void {
    if (updatedGroup.inheritable_config?.description) {
      updatedGroup.inheritable_config.description = updatedGroup.description;
    } else {
      updatedGroup.inheritable_config = {
        description: updatedGroup.description,
      };
    }
    if (!this.checkIfValidGroupName(updatedGroup)) {
      this.notifyInvalidGroupName(updatedGroup);
      return;
    }
    if (!updatedGroup.id) {
      this.postGroup(updatedGroup);
    } else {
      this.putGroup(updatedGroup);
    }
  }

  private deleteGroups(groupsToDelete: Array<GroupElement>): void {
    if (this.tableData.length === 0) {
      return;
    }
    const observablesArray = [];
    for (const group of groupsToDelete) {
      if (group.isChecked && group.id) {
        observablesArray.push(deleteGroup$(this.groupsService, group.id, this.orgId));
      }
    }
    if (observablesArray.length === 0) {
      this.notificationService.success('Unsaved groups were removed');
      this.reloadWindow();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Groups were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected groups');
        },
        () => {
          this.reloadWindow();
        }
      );
  }

  public removeSelected(groupsToDelete: Array<GroupElement>): void {
    this.deleteGroups(groupsToDelete);
  }

  public makeEmptyTableElement(): GroupElement {
    return {
      ...getDefaultNewRowProperties(),
      first_name: '',
      members: [],
      org_id: this.orgId,
      description: '',
      elementIdentifyingProperty: getGroupElementIdentifyingProperty(),
    };
  }

  public refreshDataSource(): void {
    this.reloadFirstPage();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public toggleShowBigroups(checkboxOption: CheckboxOption): void {
    this.showBigroups = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  private getParams(emailKey: string, searchDirectionParam: 'forwards' | 'backwards', limitParam: number): ListGroupsRequestParams {
    const group_type = [this.groupType];
    if (this.showBigroups) {
      group_type.push(GroupQueryType.bigroup);
    }
    const params: ListGroupsRequestParams = {
      org_id: this.orgId,
      type: group_type,
      previous_email: emailKey,
      search_direction: searchDirectionParam,
      limit: limitParam,
    };
    return params;
  }

  private getAdditionalGroupFilterParams(): ListGroupsRequestParams {
    const listGroupsRequestParams: ListGroupsRequestParams = {
      org_id: this.orgId,
      allow_partial_match: true,
    };
    const genericSearchOptionsWithValues = this.filterManager.getAllInputOptionsWithValues();
    const genericSearchParams = this.filterManager.getGenericSearchParams(genericSearchOptionsWithValues);
    if (genericSearchParams.length !== 0) {
      listGroupsRequestParams.search_params = genericSearchParams;
    }
    const paramSpecificSearchOptionsWithValues = this.paramSpecificFilterManager.getAllParamSpecificSearchOptionsWithValues();
    for (const option of paramSpecificSearchOptionsWithValues) {
      listGroupsRequestParams[option.name.trim().toLowerCase()] = option.displayName;
    }
    return listGroupsRequestParams;
  }

  private mergeAdditionalGroupFilterParams(
    currentParams: ListGroupsRequestParams,
    additonalParams: ListGroupsRequestParams
  ): ListGroupsRequestParams {
    return { ...currentParams, ...additonalParams };
  }

  public filterBySearchParam(): void {
    this.reloadFirstPage();
  }

  public getAllGroups(): Observable<any> {
    const searchDirection = 'forwards';
    const limit = 500;
    const params = this.getParams('', searchDirection, limit);
    return this.groupsService
      .listGroups(params)
      .pipe(
        takeUntil(this.unsubscribe$),
        expand((groupsResp) => {
          if (groupsResp.next_page_email) {
            const newParams = this.getParams(groupsResp.next_page_email, searchDirection, limit);
            return this.groupsService.listGroups(newParams);
          }
          return EMPTY;
        })
      )
      .pipe(
        reduce((accGroups, groupsData) => {
          if (accGroups) {
            return accGroups.concat(groupsData.groups);
          } else {
            // first fetch
            return groupsData.groups;
          }
        }, [])
      );
  }

  private getUserParams(
    email_key: string,
    search_direction_param: 'forwards' | 'backwards',
    limit_param: number
  ): ListCombinedUserDetailsRequestParams {
    const params: ListCombinedUserDetailsRequestParams = {
      org_id: this.orgId,
      status: [UserStatusEnum.active],
      limit: limit_param,
      previous_email: email_key,
      search_direction: search_direction_param,
      type: 'user',
    };
    return params;
  }

  public getAllUsersAsUsers(): Observable<Array<User>> {
    if (!!this.cachedAllUsers) {
      return of([...this.cachedAllUsers]);
    }
    return this.getAllUsers().pipe(
      map((resp: Array<ListCombinedUserDetailsResponse>) => {
        const allUsers: Array<User> = [];
        for (const item of resp) {
          const users: Array<User> = [];
          for (const userDetail of item.combined_user_details) {
            const userAsUser = userDetail.status.user;
            users.push(userAsUser);
            this.displayNameToUserMap.set(userAsUser.display_name, userAsUser);
          }
          allUsers.push(...users);
        }
        this.cachedAllUsers = allUsers;
        return allUsers;
      })
    );
  }

  public getAllUsers(): Observable<any> {
    const searchDirection = 'forwards';
    const limit = 500;
    const params = this.getUserParams('', searchDirection, limit);
    return this.usersService
      .listCombinedUserDetails(params)
      .pipe(
        takeUntil(this.unsubscribe$),
        expand((usersResp) => {
          if (usersResp.next_page_email) {
            const newParams = this.getUserParams(usersResp.next_page_email, searchDirection, limit);
            return this.usersService.listCombinedUserDetails(newParams);
          }
          return EMPTY;
        })
      )
      .pipe(
        reduce((accUsers: Array<ListCombinedUserDetailsResponse>, usersData) => {
          if (accUsers) {
            return accUsers.concat(usersData);
          } else {
            // first fetch
            return usersData;
          }
        }, [])
      );
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
