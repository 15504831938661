<portal-table-layout 
  [tableData]="tableData" 
  [columnDefs]="columnDefs" 
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  [buttonsToShow]="buttonsToShow"
  [pageDescriptiveText]="instancesDescriptiveText"
  [productGuideLink]="instancesProductGuideLink"
  pageInfoWidth="full"
  [hideFilter]="!isHostedApplicationsEnabled()"
  (updateEvent)="updateEvent($event)"
  (removeSelected)="deleteSelected($event)"
  (updateSelection)="updateSelection($event)"
>
</portal-table-layout>
