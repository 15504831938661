import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { EventsService } from '@app/core/services/events.service';
import { AppState, NotificationService } from '@app/core';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import {
  AgentConnector,
  AgentConnectorDynamicStats,
  AgentConnectorProxy,
  ApplicationService,
  Connector,
  ConnectorInstance,
  ConnectorSpec,
  ConnectorsService,
  ConnectorStaticStats,
  ConnectorStatus,
  GetIpsecConnectorRequestParams,
  IpsecConnector,
  patch_via_put,
  ReplaceIpsecConnectorRequestParams,
  RuntimeStatus,
  User,
} from '@agilicus/angular';
import { combineLatest, forkJoin, interval, Observable, Subject, timer } from 'rxjs';
import { concatMap, filter, map, mergeMap, startWith, take, takeUntil } from 'rxjs/operators';
import { ButtonType } from '../button-type.enum';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import {
  ConnectorDownloadDialogComponent,
  ConnectorDownloadDialogData,
} from '../connector-download-dialog/connector-download-dialog.component';
import { FilterManager } from '../filter/filter-manager';
import { OptionalApplicationService, OptionalConnectorElement, OptionalConnectorInstanceElement } from '../optional-types';
import {
  ActionMenuOptions,
  ChiplistColumn,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createChipListColumn,
  createExpandColumn,
  createIconColumn,
  createInputColumn,
  createSelectRowColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { findUniqueItems, updateTableElements, getStatusIconColor, getStatusIcon } from '../utils';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { createDialogData, getDefaultDialogConfig } from '../dialog-utils';
import { getDefaultNestedDataProperties, getDefaultTableProperties } from '../table-layout-utils';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { convertDateToReadableFormat, dateIsTenMinutesAgo } from '../date-utils';
import { construct_issuer } from '@app/core/services/auth-service.service';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { initConnectors, stopConnectorsPolling } from '@app/core/connector-state/connector.actions';
import { selectConnectorList, selectConnectorRefreshDataValue } from '@app/core/connector-state/connector.selectors';
import { initApplicationServices, savingApplicationServices } from '@app/core/application-service-state/application-service.actions';
import {
  selectApplicationServicesList,
  selectApplicationServicesRefreshDataValue,
} from '@app/core/application-service-state/application-service.selectors';
import { cloneDeep } from 'lodash-es';
import {
  deletingAgentConnectors,
  initAgentConnectors,
  savingAgentConnectors,
} from '@app/core/agent-connector-state/agent-connector.actions';
import { selectAgentConnectorList, selectAgentConnectorRefreshDataValue } from '@app/core/agent-connector-state/agent-connector.selectors';
import { ConnectorRoutesDialogComponent, ConnectorRoutesDialogData } from '../connector-routes-dialog/connector-routes-dialog.component';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import {
  getConnectorDynamicStats$,
  getConnectorElementDynamicStatsDisplayValue,
  getConnectorElementStaticStatsDisplayValue,
  getConnectorStaticStats$,
  startFullConnectorStatsPublish$,
} from '@app/core/api/connectors/stats-utils';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { ConnectorStatsDialogComponent, ConnectorStatsDialogData } from '../connector-stats-dialog/connector-stats-dialog.component';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog.component';
import { getNumberOfConnectorInstances, getNumberOfDownConnectorInstances } from '../connector-utils';
import {
  ConnectorWithInnerAndOuterProxyResp,
  ConnectorWithOuterProxyResp,
  getConnectorById,
  getProxiesWhereConnectorIsEitherInnerOrOuterConnector$,
  getOuterProxiesForInnerConnector$,
} from '@app/core/api/connectors/connectors-api-utils';
import { selectUser } from '@app/core/user/user.selectors';
import { FormInput } from '../custom-chiplist-input/form-input';
import { ConnectorProxyDialogComponent, ConnectorProxyDialogData } from '../connector-proxy-dialog/connector-proxy-dialog.component';
import { getTrimmedResourceName } from '../resource-utils';

export interface ConnectorElement extends TableElement, ConnectorSpec {
  readonly id: string;
  application_services: Array<ApplicationService>;
  previous_application_services: Array<ApplicationService>;
  status: ConnectorStatus;
  status_value: string;
  isListening: boolean;
  connections_successful: number;
  connections_failed: number;
  ignore_resource_disconnect_check: boolean;
  backingObject: AgentConnector | IpsecConnector;
  backingConnector: Connector;
}

export interface ConnectorInstanceElement extends TableElement {
  hostname: string;
  version: string;
  status_value: string;
  last_reported: Date;
  parentId: string | number;
  backingObject: ConnectorInstance;
  application_services: Array<ApplicationService>;
  previous_application_services: Array<ApplicationService>;
  ignore_resource_disconnect_check: boolean;
}

export interface CombinedPermissionsAndConnectorData {
  user: User;
  permission: OrgQualifiedPermission;
  connectors: Array<Connector>;
  agentConnectors: Array<AgentConnector>;
  issuer: string;
  applicationServices: Array<ApplicationService>;
  refreshDataStateCombinedValue: number;
}

@Component({
  selector: 'portal-connector-admin',
  templateUrl: './connector-admin.component.html',
  styleUrls: ['./connector-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private unsubscribeStaticAndDynamicStats$: Subject<void> = new Subject<void>();
  private orgId: string;
  private user: User;
  private connectors: Array<Connector>;
  private agentConnectors: Array<AgentConnector> = [];
  private applicationServices: Array<ApplicationService> = [];
  public tableData: Array<ConnectorElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public columnDefs: Map<string, Column<ConnectorElement>> = new Map();
  private issuer: string;
  public hasPermissions: boolean;
  public rowObjectName = 'CONNECTOR';
  public buttonsToShow: Array<string> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton('ADD CONNECTOR', ButtonColor.PRIMARY, 'Add a new connector', 'Button that adds a new connector', () => {
      this.router.navigate(['/connector-new'], {
        queryParams: { org_id: this.orgId },
      });
    }),
  ];
  private appServiceNameToAppServiceMap: Map<string, ApplicationService> = new Map();
  public pageDescriptiveText = `A connector is a means of getting, securely, from one domain of connectivity to another. It provides secure, subset, gated connectivity.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/agilicus-connector/`;
  public localAuthProductGuideLink = `https://www.agilicus.com/anyx-guide/onsite-identity/`;
  private agentConnectorIdToAgentConnectorMap: Map<string, AgentConnector> = new Map();
  public hasCustomDeleteDialogMessage = true;
  private localRefreshDataValue = 0;
  private isPollingStats = undefined;
  private connectorIdToStaticStatsMap: Map<string, ConnectorStaticStats> = new Map();
  private connectorIdToDynamicStatsMap: Map<string, AgentConnectorDynamicStats> = new Map();
  private collectedSinceDate: string;
  private staticStatsFetchCount = 0;
  private dynamicStatsFetchCount = 0;
  private quickStartPollingStaticConnectorStats$: Observable<Array<ConnectorStaticStats>>;
  private quickStartPollingDynamicConnectorStats$: Observable<Array<AgentConnectorDynamicStats>>;
  private ongoingPollingStaticConnectorStats$: Observable<Array<ConnectorStaticStats>>;
  private ongoingPollingDynamicConnectorStats$: Observable<Array<AgentConnectorDynamicStats>>;

  @ViewChild(TableLayoutComponent) private tableLayout: TableLayoutComponent<ConnectorElement>;

  constructor(
    private connectorsService: ConnectorsService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private appErrorHandler: AppErrorHandler,
    private routerHelperService: RouterHelperService,
    private eventsService: EventsService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    this.store.dispatch(initAgentConnectors({ force: true, blankSlate: false }));
    this.store.dispatch(initApplicationServices({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.getAndSetPermissionsAndData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.unsubscribeStaticAndDynamicStats$.next();
    this.unsubscribeStaticAndDynamicStats$.complete();
    this.store.dispatch(stopConnectorsPolling());
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndConnectorData> {
    const user$ = this.store.pipe(select(selectUser));
    const hasPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const agentConnectorListState$ = this.store.pipe(select(selectAgentConnectorList));
    const appServiceListState$ = this.store.pipe(select(selectApplicationServicesList));
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    const refreshAgentConnectorDataState$ = this.store.pipe(select(selectAgentConnectorRefreshDataValue));
    const refreshApplicationServiceDataState$ = this.store.pipe(select(selectApplicationServicesRefreshDataValue));
    return combineLatest([
      user$,
      hasPermissions$,
      connectorListState$,
      agentConnectorListState$,
      appServiceListState$,
      refreshConnectorDataState$,
      refreshAgentConnectorDataState$,
      refreshApplicationServiceDataState$,
    ]).pipe(
      map(
        ([
          userResp,
          hasPermissionsResp,
          connectorListStateResp,
          agentConnectorListStateResp,
          appServiceListStateResp,
          refreshConnectorDataStateResp,
          refreshAgentConnectorDataStateResp,
          refreshApplicationServiceDataStateResp,
        ]) => {
          const combinedPermissionsAndAuditDestinations: CombinedPermissionsAndConnectorData = {
            user: userResp,
            permission: hasPermissionsResp,
            connectors: cloneDeep(connectorListStateResp),
            agentConnectors: cloneDeep(agentConnectorListStateResp),
            applicationServices: cloneDeep(appServiceListStateResp),
            issuer: construct_issuer(),
            refreshDataStateCombinedValue:
              refreshConnectorDataStateResp + refreshAgentConnectorDataStateResp + refreshApplicationServiceDataStateResp,
          };
          return combinedPermissionsAndAuditDestinations;
        }
      )
    );
  }

  private startPublishingStats(): void {
    this.collectedSinceDate = new Date().toISOString();
    const fullConnectorStatsPublish$ = startFullConnectorStatsPublish$(this.connectorsService, this.orgId, this.connectors);
    fullConnectorStatsPublish$.pipe(takeUntil(this.unsubscribe$)).subscribe((_) => {});
  }

  private getStaticStatsForOverview(): void {
    // Get the stats every 1 second for 5 seconds:
    this.quickStartPollingStaticConnectorStats$ = interval(1000) // 1 second
      .pipe(startWith(0))
      .pipe(take(5))
      .pipe(
        mergeMap(() => {
          return getConnectorStaticStats$(
            this.connectorsService,
            this.orgId,
            this.collectedSinceDate,
            this.getCurrentPageConnectorIdsList()
          );
        })
      );
    this.quickStartPollingStaticConnectorStats$
      .pipe(
        filter((_) => {
          return this.isPollingStats;
        })
      )
      .pipe(takeUntil(this.unsubscribeStaticAndDynamicStats$))
      .subscribe((statsResp) => {
        this.setConnectorStaticStatsAndUpdateTable(statsResp);
      });
    // Then get the stats every 10 seconds:
    const pollingStaticConnectorStats$ = interval(10000) // 10 seconds
      .pipe(startWith(0))
      .pipe(
        mergeMap(() => {
          return getConnectorStaticStats$(
            this.connectorsService,
            this.orgId,
            this.collectedSinceDate,
            this.getCurrentPageConnectorIdsList()
          );
        })
      );
    this.ongoingPollingStaticConnectorStats$ = timer(6000).pipe(mergeMap((_) => pollingStaticConnectorStats$));
    this.ongoingPollingStaticConnectorStats$
      .pipe(
        filter((_) => {
          return this.isPollingStats;
        })
      )
      .pipe(takeUntil(this.unsubscribeStaticAndDynamicStats$))
      .subscribe((statsResp) => {
        this.setConnectorStaticStatsAndUpdateTable(statsResp);
      });
  }

  private getDynamicStatsForOverview(): void {
    // Get the stats every 1 second for 5 seconds:
    this.quickStartPollingDynamicConnectorStats$ = interval(1000) // 1 second
      .pipe(startWith(0))
      .pipe(take(5))
      .pipe(
        mergeMap(() => {
          return getConnectorDynamicStats$(
            this.connectorsService,
            this.orgId,
            this.collectedSinceDate,
            this.getCurrentPageConnectorIdsList()
          );
        })
      );
    this.quickStartPollingDynamicConnectorStats$.pipe(takeUntil(this.unsubscribeStaticAndDynamicStats$)).subscribe((statsResp) => {
      this.setConnectorDynamicStatsAndUpdateTable(statsResp);
    });
    // Then get the stats every 10 seconds:
    this.ongoingPollingDynamicConnectorStats$ = interval(10000) // 10 seconds
      .pipe(startWith(0))
      .pipe(
        mergeMap(() => {
          return getConnectorDynamicStats$(
            this.connectorsService,
            this.orgId,
            this.collectedSinceDate,
            this.getCurrentPageConnectorIdsList()
          );
        })
      );
    const delayedPollingConnectorStatus$ = timer(6000).pipe(mergeMap((_) => this.ongoingPollingDynamicConnectorStats$));
    delayedPollingConnectorStatus$.pipe(takeUntil(this.unsubscribeStaticAndDynamicStats$)).subscribe((statsResp) => {
      this.setConnectorDynamicStatsAndUpdateTable(statsResp);
    });
  }

  private setConnectorStaticStatsMap(staticStatsResp: Array<ConnectorStaticStats>): void {
    this.connectorIdToStaticStatsMap.clear();
    for (const staticStatsData of staticStatsResp) {
      this.connectorIdToStaticStatsMap.set(staticStatsData.connector_id, staticStatsData);
    }
  }

  private setConnectorDynamicStatsMap(connectorStatsDataResp: Array<AgentConnectorDynamicStats>): void {
    this.connectorIdToDynamicStatsMap.clear();
    for (const connectorStatsData of connectorStatsDataResp) {
      this.connectorIdToDynamicStatsMap.set(connectorStatsData.connector_id, connectorStatsData);
    }
  }

  private setConnectorStaticStatsAndUpdateTable(staticStats: Array<ConnectorStaticStats>): void {
    if (!this.isPollingStats) {
      return;
    }
    this.staticStatsFetchCount++;
    this.setConnectorStaticStatsMap(staticStats);
    // Redraw the table to show the newly received stats:
    this.updateTable();
  }

  private setConnectorDynamicStatsAndUpdateTable(dynamicStats: Array<AgentConnectorDynamicStats>): void {
    if (!this.isPollingStats) {
      return;
    }
    this.dynamicStatsFetchCount++;
    this.setConnectorDynamicStatsMap(dynamicStats);
    // Trigger the table to show the newly received stats:
    this.tableLayout.triggerChangeDetectionFromParentComponent();
  }

  private getAndSetPermissionsAndData(): void {
    this.getCombinedPermissionsAndData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((combinedPermissionsAndDataResp) => {
        this.setInitialData(combinedPermissionsAndDataResp);
        if (!this.hasPermissions || combinedPermissionsAndDataResp.connectors.length === 0) {
          this.resetEmptyTable();
          return;
        }
        if (!this.isPollingStats && this.connectors.length !== 0 && !!this.orgId) {
          if (this.isPollingStats === undefined) {
            // Initial start of polling:
            this.isPollingStats = true;
            this.startPublishingStats();
            this.getStaticStatsForOverview();
            this.getDynamicStatsForOverview();
          } else {
            // Restart polling:
            this.isPollingStats = true;
          }
        }
        if (this.tableData.length === 0 || this.localRefreshDataValue !== combinedPermissionsAndDataResp.refreshDataStateCombinedValue) {
          this.localRefreshDataValue = combinedPermissionsAndDataResp.refreshDataStateCombinedValue;
          this.updateTable();
        }
      });
  }

  private setInitialData(combinedPermissionsAndDataResp: CombinedPermissionsAndConnectorData): void {
    this.orgId = combinedPermissionsAndDataResp.permission?.orgId;
    this.user = combinedPermissionsAndDataResp.user;
    this.hasPermissions = combinedPermissionsAndDataResp.permission?.hasPermission;
    this.issuer = combinedPermissionsAndDataResp?.issuer;
    this.connectors = !!combinedPermissionsAndDataResp?.connectors ? combinedPermissionsAndDataResp.connectors : [];
    this.agentConnectors = !!combinedPermissionsAndDataResp?.agentConnectors ? combinedPermissionsAndDataResp.agentConnectors : [];
    this.setAgentConnectorIdToAgentConnectorMap();
    this.applicationServices = !!combinedPermissionsAndDataResp?.applicationServices
      ? combinedPermissionsAndDataResp.applicationServices
      : [];
  }

  private setAgentConnectorIdToAgentConnectorMap(): void {
    this.agentConnectorIdToAgentConnectorMap.clear();
    for (const agentConnector of this.agentConnectors) {
      this.agentConnectorIdToAgentConnectorMap.set(agentConnector.metadata.id, agentConnector);
    }
  }

  /**
   * Parent Table Column
   */
  private getTypeColumn(): Column<ConnectorElement> {
    const typeColumn = createIconColumn('type');
    typeColumn.displayName = 'Type';
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    typeColumn.getDisplayValue = (element: OptionalConnectorElement) => {
      if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
        return 'dns';
      }
      if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.ipsec) {
        return 'vpn_key';
      }
      return '';
    };
    typeColumn.getTooltip = (element: OptionalConnectorElement) => {
      if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
        return 'Connector';
      }
      if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.ipsec) {
        return 'VPN Connector';
      }
      return '';
    };
    return typeColumn;
  }

  /**
   * Parent Table Column
   */
  private getNameColumn(): Column<ConnectorElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.requiredField = () => true;
    nameColumn.isEditable = true;
    nameColumn.isValidEntry = (str: string): boolean => {
      return str.length > 0 && str.length < 101;
    };
    nameColumn.getFormattedValue = (name: string, element: ConnectorElement): string => {
      return getTrimmedResourceName(name);
    };
    return nameColumn;
  }

  /**
   * Parent Table Column
   */
  private getParentStatusColumn(): Column<ConnectorElement> {
    const column = createInputColumn('status_value');
    column.displayName = 'Status';
    column.inputSize = InputSize.SMALL;
    column.hasIconPrefix = true;
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return 'The summary status of the Connector.';
    };
    column.getIconPrefix = this.getStaticStatusIconFromParentConnectorElement.bind(this);
    column.getIconColor = this.getStaticStatusIconColorFromElement.bind(this);
    column.getTooltip = (element: ConnectorElement, input: FormInput<ConnectorElement>) => {
      const staticStatsData = this.connectorIdToStaticStatsMap.get(element.id);
      const statusValue = staticStatsData?.operational_status?.status;
      if (statusValue === RuntimeStatus.OverallStatusEnum.warn) {
        const backingObjectAsAgentConnector = element.backingObject as AgentConnector;
        const shareStatsList = backingObjectAsAgentConnector.status?.stats?.shares?.per_share_info;
        if (backingObjectAsAgentConnector.status?.stats?.system?.correct_file_permissions === false) {
          return 'The connector user does not have the correct permissions to access its system files';
        } else if (!!shareStatsList && !!shareStatsList.find((shareStat) => shareStat.correct_share_permissions === false)) {
          return 'The connector user does not have the correct permissions to access its shares';
        }
        return 'The connector is not fully operational. Please check its logs for more info.';
      }
      return input.getDisplayValue(element);
    };
    column.getDisplayValue = (element: ConnectorElement) => {
      const staticStatsData = this.connectorIdToStaticStatsMap.get(element.id);
      return getConnectorElementStaticStatsDisplayValue(staticStatsData?.operational_status?.status, this.staticStatsFetchCount);
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getNetworksColumn(): ChiplistColumn<ConnectorElement | ConnectorInstanceElement> {
    const column = createChipListColumn('application_services');
    column.displayName = 'Networks';
    column.getDisplayValue = (network: OptionalApplicationService) => {
      return network.name;
    };
    column.getElementFromValue = (networkName: string): any => {
      return this.appServiceNameToAppServiceMap.get(networkName);
    };
    column.inputSize = InputSize.STANDARD;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getIsListeningColumn(): Column<ConnectorElement> {
    const isListeningColumn = createCheckBoxColumn('isListening');
    isListeningColumn.displayName = 'Is Listening';
    isListeningColumn.isEditable = false;
    isListeningColumn.getHeaderTooltip = () => {
      return `Indicates whether this connector exposes its assigned resources locally`;
    };
    return isListeningColumn;
  }

  /**
   * Parent Table Column
   */
  private getConnectionsSuccessfulColumn(): Column<ConnectorElement> {
    const connectionsSuccessfulColumn = createInputColumn('connections_successful');
    connectionsSuccessfulColumn.isEditable = false;
    connectionsSuccessfulColumn.isReadOnly = () => true;
    connectionsSuccessfulColumn.getDisplayValue = (element: ConnectorElement) => {
      const dynamicStatsData = this.connectorIdToDynamicStatsMap.get(element.id);
      return getConnectorElementDynamicStatsDisplayValue(
        undefined,
        dynamicStatsData?.upstream_totals?.network_summary_stats?.connections_successful,
        this.dynamicStatsFetchCount
      );
    };
    connectionsSuccessfulColumn.inputSize = InputSize.SMALL;
    return connectionsSuccessfulColumn;
  }

  /**
   * Parent Table Column
   */
  private getConnectionsFailedColumn(): Column<ConnectorElement> {
    const connectionsFailedColumn = createInputColumn('connections_failed');
    connectionsFailedColumn.isEditable = false;
    connectionsFailedColumn.isReadOnly = () => true;
    connectionsFailedColumn.getDisplayValue = (element: ConnectorElement) => {
      const dynamicStatsData = this.connectorIdToDynamicStatsMap.get(element.id);
      return getConnectorElementDynamicStatsDisplayValue(
        undefined,
        dynamicStatsData?.upstream_totals?.network_summary_stats?.connections_failed,
        this.dynamicStatsFetchCount
      );
    };
    connectionsFailedColumn.inputSize = InputSize.SMALL;
    return connectionsFailedColumn;
  }

  private hasOnlyDownInstances(connector: Connector): boolean {
    const numberOfInstances = getNumberOfConnectorInstances(connector);
    const numberOfDownInstances = getNumberOfDownConnectorInstances(connector);
    return numberOfDownInstances > 0 && numberOfInstances === numberOfDownInstances;
  }

  private getConnectorInstanceDeleteDialogMessagePrefix(connector: Connector): string {
    const numberOfInstances = getNumberOfConnectorInstances(connector);
    return `Delete Connector ${numberOfInstances > 1 ? 'Instances' : 'Instance'}`;
  }

  private getConnectorInstanceDeleteDialogMessage(connector: Connector): string {
    const numberOfInstances = getNumberOfConnectorInstances(connector);
    return `Warning: You have ${numberOfInstances > 1 ? 'existing instances' : 'an existing instance'} of this connector.
    In order to continue with this installation you must first delete ${
      numberOfInstances > 1 ? 'these instances' : 'this instance'
    }.\n<br><br>
    Would you like to delete ${
      numberOfInstances > 1 ? 'these instances' : 'this instance'
    } now and reinstall this connector or cancel this installation?`;
  }

  private openDeleteConnectorInstanceDialog(connector: Connector): void {
    const messagePrefix = this.getConnectorInstanceDeleteDialogMessagePrefix(connector);
    const message = this.getConnectorInstanceDeleteDialogMessage(connector);
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: 'Delete', cancel: 'Cancel' },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteAllInstances(connector.status.instances, connector, true);
      }
    });
  }

  private openConnectorInstallDialog(connector: Connector, connectorProxy?: AgentConnectorProxy): void {
    this.eventsService.SendEvent({
      event_id: 'pre-connector-download-2',
      event_type: 'click',
      category: 'navigation',
      sub_category: 'connector',
      action: connector.metadata.id,
    });

    const dialogData: ConnectorDownloadDialogData = {
      connector,
      orgId: connector.spec.org_id,
      issuer: this.issuer,
      agentConnectorProxy: connectorProxy,
    };
    this.dialog.open(
      ConnectorDownloadDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
        minHeight: '650px',
      })
    );
  }

  private openConnectorProxiesDialog(
    agentConnector: AgentConnector,
    connectorIsInnerProxyList?: Array<AgentConnectorProxy>,
    connectorIsOuterProxyList?: Array<AgentConnectorProxy>
  ): void {
    const dialogData: ConnectorProxyDialogData = {
      agentConnector: agentConnector,
      connectorIsInnerProxyList: connectorIsInnerProxyList,
      connectorIsOuterProxyList: connectorIsOuterProxyList,
      connectorList: this.connectors,
      orgId: this.orgId,
    };
    const dialogRef = this.dialog.open(
      ConnectorProxyDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
        maxWidth: '950px',
      })
    );
  }

  private getConnectorInstanceFailedDeleteDialogMessagePrefix(connector: Connector): string {
    const numberOfInstances = getNumberOfConnectorInstances(connector);
    return `Failed to Delete Connector ${numberOfInstances > 1 ? 'Instances' : 'Instance'}`;
  }

  private getConnectorInstanceFailedDeleteDialogMessage(connector: Connector): string {
    const numberOfInstances = getNumberOfConnectorInstances(connector);
    return `Failed to remove the selected ${numberOfInstances > 1 ? 'instances' : 'instance'} from the connector "${
      connector.spec.name
    }". Would you like to try again?`;
  }

  private openFailedDeleteConnectorInstanceDialog(connector: Connector): void {
    const messagePrefix = this.getConnectorInstanceFailedDeleteDialogMessagePrefix(connector);
    const message = this.getConnectorInstanceFailedDeleteDialogMessage(connector);
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: 'Delete', cancel: 'Cancel' },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteAllInstances(connector.status.instances, connector, true);
      }
    });
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<ConnectorElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<ConnectorElement>> = [
      {
        displayName: 'Install Connector',
        icon: 'cloud_download',
        tooltip: 'Click to install this connector',
        onClick: (element: OptionalConnectorElement) => {
          const connector$ = getConnectorById(this.connectorsService, element.id, this.orgId);
          const connectorWithProxy$: Observable<ConnectorWithOuterProxyResp> = connector$.pipe(
            concatMap((connectorResp) => {
              return getOuterProxiesForInnerConnector$(this.connectorsService, connectorResp.metadata.id, this.orgId).pipe(
                map((connectorIsInnerProxyResp) => {
                  const connectorWithOuterProxyResp: ConnectorWithOuterProxyResp = {
                    connector: connectorResp,
                    connectorIsInnerProxy: connectorIsInnerProxyResp,
                  };
                  return connectorWithOuterProxyResp;
                })
              );
            })
          );
          connectorWithProxy$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            if (this.hasOnlyDownInstances(resp.connector)) {
              this.openDeleteConnectorInstanceDialog(resp.connector);
            } else {
              this.openConnectorInstallDialog(resp.connector, resp.connectorIsInnerProxy);
            }
          });
        },
        isDisabled: (element: OptionalConnectorElement) => {
          if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
            return false;
          }
          return true;
        },
      },
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: OptionalConnectorElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.org_id,
            resources_behind_connector_id: element.id,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
        isDisabled: (element: OptionalConnectorElement) => {
          if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
            return false;
          }
          return true;
        },
      },
      {
        displayName: 'Manage Routes',
        icon: 'alt_route',
        tooltip: 'Click to add/delete/modify routes',
        onClick: (element: OptionalConnectorElement) => {
          const dialogData: ConnectorRoutesDialogData = {
            agentConnector: element.backingObject as AgentConnector,
          };
          const dialogRef = this.dialog.open(
            ConnectorRoutesDialogComponent,
            getDefaultDialogConfig({
              data: dialogData,
              maxWidth: '950px',
            })
          );
          dialogRef.afterClosed().subscribe((updatedAgentConnector: AgentConnector) => {
            if (!!updatedAgentConnector) {
              this.store.dispatch(
                savingAgentConnectors({
                  objs: [updatedAgentConnector],
                  trigger_update_side_effects: false,
                  notifyUser: true,
                  refreshData: true,
                })
              );
            }
          });
        },
        isDisabled: (element: OptionalConnectorElement) => {
          if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
            return false;
          }
          return true;
        },
      },
      {
        displayName: 'View Detailed Statistics',
        icon: 'analytics',
        tooltip: 'Click to view detailed statistics for this connector',
        onClick: (element: ConnectorElement) => {
          const dialogData: ConnectorStatsDialogData = {
            connector: element,
            applicationServices: this.applicationServices,
            orgId: element.org_id,
          };
          this.dialog.open(
            ConnectorStatsDialogComponent,
            getDefaultDialogConfig({
              data: dialogData,
              maxWidth: 'inherit',
            })
          );
        },
      },
      {
        displayName: 'Manage Proxies',
        icon: 'settings_ethernet',
        tooltip: 'Click to add/delete/modify proxies',
        onClick: (element: OptionalConnectorElement) => {
          const connector$ = getConnectorById(this.connectorsService, element.id, this.orgId);
          const connectorWithProxy$: Observable<ConnectorWithInnerAndOuterProxyResp> = connector$.pipe(
            concatMap((connectorResp) => {
              return getProxiesWhereConnectorIsEitherInnerOrOuterConnector$(
                this.connectorsService,
                connectorResp.metadata.id,
                this.orgId
              ).pipe(
                map((connectorProxiesResp) => {
                  const connectorWithInnerAndOuterProxyResp: ConnectorWithInnerAndOuterProxyResp = {
                    connector: connectorResp,
                    connectorIsInnerProxy: connectorProxiesResp.connectorIsInnerProxy,
                    connectorIsOuterProxyList: connectorProxiesResp.connectorIsOuterProxyList,
                  };
                  return connectorWithInnerAndOuterProxyResp;
                })
              );
            })
          );
          connectorWithProxy$.pipe(takeUntil(this.unsubscribe$)).subscribe((resp) => {
            const connectorIsInnerProxyAsList = !!resp.connectorIsInnerProxy ? [resp.connectorIsInnerProxy] : [];
            this.openConnectorProxiesDialog(
              element.backingObject as AgentConnector,
              connectorIsInnerProxyAsList,
              resp.connectorIsOuterProxyList
            );
          });
        },
        isDisabled: (element: OptionalConnectorElement) => {
          if (element.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
            return false;
          }
          return true;
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  /**
   * Parent Table Column
   */
  private getExpandColumn(): Column<ConnectorElement> {
    const expandColumn = createExpandColumn();
    expandColumn.disableField = (element: OptionalConnectorElement) => {
      return element.connector_type !== ConnectorSpec.ConnectorTypeEnum.agent;
    };
    return expandColumn;
  }

  private getStaticStatusIconFromNestedInstanceElement(element: ConnectorInstanceElement): string {
    return getStatusIcon(element.status_value);
  }

  private getStaticStatusIconColorFromNestedInstanceElement(element: ConnectorInstanceElement): string {
    return getStatusIconColor(element.status_value);
  }

  private getStaticStatusIconFromParentConnectorElement(element: ConnectorElement): string {
    const staticStatsData = this.connectorIdToStaticStatsMap.get(element.id);
    return getStatusIcon(staticStatsData?.operational_status?.status);
  }

  private getStaticStatusIconColorFromElement(element: ConnectorElement): string {
    const staticStatsData = this.connectorIdToStaticStatsMap.get(element.id);
    return getStatusIconColor(staticStatsData?.operational_status?.status);
  }

  /**
   * Parent Table Columns
   */
  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getTypeColumn(),
        this.getNameColumn(),
        this.getNetworksColumn(),
        this.getParentStatusColumn(),
        this.getConnectionsSuccessfulColumn(),
        this.getConnectionsFailedColumn(),
        this.getIsListeningColumn(),
        this.getActionsColumn(),
        this.getExpandColumn(),
      ],
      this.columnDefs
    );
  }

  private updateTable(): void {
    this.setAppServiceNameToAppServiceMap(this.applicationServices);
    this.columnDefs.get('application_services').allowedValues = this.applicationServices;
    this.buildData(this.connectors);
    this.replaceTableWithCopy();
  }

  private setAppServiceNameToAppServiceMap(appServices: Array<ApplicationService>): void {
    this.appServiceNameToAppServiceMap.clear();
    for (const appService of appServices) {
      this.appServiceNameToAppServiceMap.set(appService.name, appService);
    }
  }

  private buildData(connectors: Array<Connector>): void {
    const data: Array<ConnectorElement> = [];
    for (let i = 0; i < connectors.length; i++) {
      const connector = connectors[i];
      data.push(this.createConnectorElement(connector, i));
    }
    this.setNestedTableData(data);
    updateTableElements(this.tableData, data);
  }

  private createConnectorElement(connector: Connector, index: number): ConnectorElement {
    const data: ConnectorElement = {
      ...getDefaultTableProperties(index),
      id: connector.metadata.id,
      name: connector.spec.name,
      org_id: this.orgId,
      connector_type: connector.spec.connector_type,
      application_services: [],
      previous_application_services: [],
      status: connector.status,
      status_value: connector.status.operational_status.status,
      isListening: false,
      connections_successful: 0,
      connections_failed: 0,
      ignore_resource_disconnect_check: false,
      backingObject: connector,
      backingConnector: connector,
    };
    const networksList: Array<ApplicationService> = [];
    for (const network of this.applicationServices) {
      if (network.connector_id === data.id) {
        networksList.push(network);
      }
    }
    data.application_services = [...networksList];
    data.previous_application_services = [...networksList];
    if (connector.spec.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
      const backingAgentConnector = this.agentConnectorIdToAgentConnectorMap.get(connector.metadata.id);
      data.backingObject = backingAgentConnector;
      if (backingAgentConnector?.status?.operational_status?.status) {
        data.status = RuntimeStatus.OverallStatusEnum[backingAgentConnector.status.operational_status.status];
      }
      data.isListening = this.getIslistening(backingAgentConnector);
    }
    return data;
  }

  private getIslistening(agentConnector: AgentConnector): boolean {
    return !!agentConnector?.spec?.routing?.local_binds && agentConnector.spec.routing.local_binds.length !== 0;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private deleteFromParentTable(connectorsToDelete: Array<ConnectorElement>): void {
    const copyOfAgentConnectorsToDelete = cloneDeep(
      connectorsToDelete.map((connectorElement) => connectorElement.backingObject as AgentConnector)
    );
    this.store.dispatch(
      deletingAgentConnectors({ objs: copyOfAgentConnectorsToDelete, trigger_update_side_effects: false, notifyUser: true })
    );
  }

  static gethostnamesStringFromList(list: Array<ConnectorInstance>): string {
    return list
      .map((instance) => (instance.status?.stats?.system?.hostname ? `"${instance.status?.stats?.system?.hostname}"` : ''))
      .filter((instance) => !!instance)
      .join(', ');
  }

  public getCustomDeleteDialogMessage(connectorsToDelete: Array<ConnectorElement>): string {
    let message = '';
    if (connectorsToDelete.length < 2) {
      const connectorInstances = connectorsToDelete[0].status?.instances;
      const targetInstances = connectorInstances.filter(
        (instance) => instance.status?.operational_status?.status && instance.status?.stats?.system?.hostname
      );
      if (targetInstances.length !== 0) {
        const hostnamesString = ConnectorAdminComponent.gethostnamesStringFromList(targetInstances);
        message = `NOTE: If you have not already, ensure this Agilicus Connector has been uninstalled from ${hostnamesString}. `;
        const recentlyRunningInstances = targetInstances.filter((instance) =>
          dateIsTenMinutesAgo(new Date(instance.status?.stats?.metadata?.receipt_time))
        );
        const recentlyRunningHostnamesString = ConnectorAdminComponent.gethostnamesStringFromList(recentlyRunningInstances);
        if (recentlyRunningInstances.length !== 0) {
          message = `NOTE: This Agilicus Connector was seen running on ${recentlyRunningHostnamesString} recently.
            Please ensure it is uninstalled from the host(s) as well as here. `;
        }
      }
    } else {
      message = 'Please ensure selected connectors are uninstalled from the host as well as here. ';
    }
    return message;
  }

  private getParentElementFromParentId(connectorInstanceElement: ConnectorInstanceElement): ConnectorElement | undefined {
    return this.tableData[connectorInstanceElement.parentId];
  }

  private prepareDeleteInstances(
    elementsToDelete: Array<ConnectorInstance>,
    parentConnector: Connector
  ): Array<Observable<ConnectorInstance>> {
    const observablesArray = [];
    for (const element of elementsToDelete) {
      observablesArray.push(
        this.connectorsService.deleteInstance({
          connector_id: parentConnector.metadata.id,
          connector_instance_id: element.metadata.id,
          org_id: this.orgId,
        })
      );
    }
    return observablesArray;
  }

  private deleteAllInstanceElements(elementsToDelete: Array<ConnectorInstanceElement>, parentConnector: Connector): void {
    const instancesToDelete = elementsToDelete.map((element) => element.backingObject);
    this.deleteAllInstances(instancesToDelete, parentConnector);
  }

  private deleteAllInstances(instancesToDelete: Array<ConnectorInstance>, parentConnector: Connector, openDialog = false): void {
    const observablesArray = this.prepareDeleteInstances(instancesToDelete, parentConnector);
    if (observablesArray.length === 0) {
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (openDialog) {
            this.openConnectorInstallDialog(parentConnector);
          }
        },
        (errorResp) => {
          if (openDialog) {
            this.openFailedDeleteConnectorInstanceDialog(parentConnector);
          } else {
            const numberOfInstances = getNumberOfConnectorInstances(parentConnector);
            this.notificationService.error(
              `Failed to remove the selected ${numberOfInstances > 1 ? 'instances' : 'instance'} from the connector "${
                parentConnector.spec.name
              }"`
            );
          }
        },
        () => {
          // We want to refresh the ngrx state data with the latest from the api
          // since we call a differnet endpoint to delete the instances
          // rather than updating the connectors themselves.
          this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
        }
      );
  }

  private deleteFromNestedTable(connectorInstanceElement: ConnectorInstanceElement): void {
    const parentElement = this.getParentElementFromParentId(connectorInstanceElement);
    const connectorInstancesToDelete: Array<ConnectorInstanceElement> = parentElement.expandedData.nestedTableData.filter(
      (element) => element.isChecked
    );
    this.deleteAllInstanceElements(connectorInstancesToDelete, parentElement.backingConnector);
  }

  public deleteSelected(elementsToDelete: Array<ConnectorElement | ConnectorInstanceElement>, ignoreResourceDisconnectCheck = false): void {
    const elementAsInstance = elementsToDelete[0] as ConnectorInstanceElement;
    if (elementAsInstance.parentId !== undefined && elementAsInstance.parentId !== null) {
      this.deleteFromNestedTable(elementAsInstance);
      return;
    }
    const elementsAsConnector = elementsToDelete as Array<ConnectorElement>;
    for (const connector of elementsAsConnector) {
      const backingAgentConnector = connector.backingObject as AgentConnector;
      if (connector.application_services.length !== 0 && !ignoreResourceDisconnectCheck) {
        // Warn user before delete:
        this.openDeleteConnectorWithResourcesWarningDialog(connector, elementsAsConnector);
        return;
      }
      if (backingAgentConnector.spec.local_authentication_enabled) {
        if (this.isUserLoggedInViaConnectorLocalAuth(connector)) {
          // Do not allow delete:
          this.openPreventDeleteConnectorWithCurrentUserLocalAuthDialog(connector);
          return;
        }
        // Warn user before delete:
        this.openDeleteConnectorWithLocalAuthWarningDialog(elementsAsConnector);
        return;
      }
    }
    this.deleteFromParentTable(elementsAsConnector);
  }

  private isUserLoggedInViaConnectorLocalAuth(connector: ConnectorElement): boolean {
    const upstreamUserIdentities = this.user.upstream_user_identities;
    if (!!upstreamUserIdentities) {
      for (const identity of upstreamUserIdentities) {
        const upstreamIdpId = identity.spec.upstream_idp_id;
        if (!!upstreamIdpId && upstreamIdpId.includes(connector.id)) {
          return true;
        }
      }
    }
    return false;
  }

  private openDeleteConnectorWithResourcesWarningDialog(element: ConnectorElement, elements: Array<ConnectorElement>): void {
    const messagePrefix = `Deleting Connector With Assigned Resources`;
    const message = `Warning: You are about to delete a connector "${element.name}" which is assigned resources.
  Those resources will not be accessible until you assign them to a connector.\n<br><br>
  Would you like to continue?`;
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: 'Yes', cancel: 'No' },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteSelected(elements, true);
      }
    });
  }

  private openPreventDeleteConnectorWithCurrentUserLocalAuthDialog(element: ConnectorElement): void {
    const messagePrefix = `Deleting Connector With Local Authentication In Use`;
    const message = `Warning: You are currently signed in using local authentication via connector "${element.name}".
  Therefore, deleting this connector is not permitted.`;
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: '', cancel: 'Cancel' },
      productGuideData: {
        productGuidePreText: 'For more details, see the ',
        productGuideLinkText: 'Product Guide',
        productGuideLink: this.localAuthProductGuideLink,
      },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
  }

  private openDeleteConnectorWithLocalAuthWarningDialog(elements: Array<ConnectorElement>): void {
    const messagePrefix = `Deleting Connector With Local Authentication Enabled`;
    const message = `Warning: You are about to delete a connector that has local authentication enabled.
  This may create problems for users who authenticate via this connector.\n<br><br>
  Would you like to continue?`;
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: 'Yes', cancel: 'No' },
      productGuideData: {
        productGuidePreText: 'For more details, see the ',
        productGuideLinkText: 'Product Guide',
        productGuideLink: this.localAuthProductGuideLink,
      },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.deleteFromParentTable(elements);
      }
    });
  }

  private getAppServicesToRemove(updatedElement: ConnectorElement | ConnectorInstanceElement): Array<ApplicationService> | undefined {
    const appServicesToRemove = [];
    const updatedElementAsConnectorElement = updatedElement as ConnectorElement;
    const updatedElementAsConnectorInstanceElement = updatedElement as ConnectorInstanceElement;
    let targetAppServices: Array<ApplicationService> = [];
    if (!!updatedElementAsConnectorElement.id) {
      // Parent Connector
      targetAppServices = this.applicationServices.filter((appService) => appService.connector_id === updatedElementAsConnectorElement.id);
    } else {
      // Connector Instance:
      targetAppServices = this.applicationServices.filter(
        (appService) => appService.connector_instance_id === updatedElementAsConnectorInstanceElement.backingObject.metadata.id
      );
    }
    const appServiceNamesToRemove = findUniqueItems(
      targetAppServices.map((appService) => appService.name),
      updatedElement.application_services.map((appService) => appService.name)
    );
    for (const appServiceName of appServiceNamesToRemove) {
      appServicesToRemove.push(this.appServiceNameToAppServiceMap.get(appServiceName));
    }
    return appServicesToRemove;
  }

  private getAndUpdateAgentConnectorFromTableElement(tableElement: ConnectorElement): AgentConnector | undefined {
    if (tableElement.connector_type !== ConnectorSpec.ConnectorTypeEnum.agent) {
      return undefined;
    }
    const copyOfBackingObject: AgentConnector = cloneDeep(tableElement.backingObject as AgentConnector);
    copyOfBackingObject.spec.name = tableElement.name;
    tableElement.backingObject = copyOfBackingObject;
    return tableElement.backingObject as AgentConnector;
  }

  private addAndUpdateAppServicesToRemove(
    updatedElement: ConnectorElement | ConnectorInstanceElement,
    appServicesToUpdateList: Array<ApplicationService>
  ): void {
    const updatedElementAsConnectorElement = updatedElement as ConnectorElement;
    const updatedElementAsConnectorInstanceElement = updatedElement as ConnectorInstanceElement;
    if (!!updatedElementAsConnectorElement.id) {
      // Parent Connector
      const appServicesToRemove = this.getAppServicesToRemove(updatedElementAsConnectorElement);
      for (const appServiceToRemove of appServicesToRemove) {
        const copyOfAppServiceToRemove = cloneDeep(appServiceToRemove);
        copyOfAppServiceToRemove.connector_id = '';
        appServicesToUpdateList.push(copyOfAppServiceToRemove);
      }
    } else {
      // Connector Instance:
      const appServicesToRemove = this.getAppServicesToRemove(updatedElementAsConnectorInstanceElement);
      for (const appServiceToRemove of appServicesToRemove) {
        const copyOfAppServiceToRemove = cloneDeep(appServiceToRemove);
        copyOfAppServiceToRemove.connector_instance_id = '';
        appServicesToUpdateList.push(copyOfAppServiceToRemove);
      }
    }
  }

  private addAppServicesToUpdateToList(
    updatedElement: ConnectorElement | ConnectorInstanceElement,
    appServicesToUpdateList: Array<ApplicationService>
  ): void {
    const updatedElementAsConnectorElement = updatedElement as ConnectorElement;
    const updatedElementAsConnectorInstanceElement = updatedElement as ConnectorInstanceElement;
    if (!!updatedElementAsConnectorElement.id) {
      // Parent Connector
      for (const appServiceToUpdate of updatedElement.application_services) {
        if (appServiceToUpdate.connector_id !== updatedElementAsConnectorElement.id) {
          const copyOfAppServiceToUpdate = cloneDeep(appServiceToUpdate);
          copyOfAppServiceToUpdate.connector_id = updatedElementAsConnectorElement.id;
          appServicesToUpdateList.push(copyOfAppServiceToUpdate);
        }
      }
    } else {
      // Connector Instance:
      for (const appServiceToUpdate of updatedElement.application_services) {
        if (appServiceToUpdate.connector_id !== updatedElementAsConnectorInstanceElement.backingObject.metadata.id) {
          const copyOfAppServiceToUpdate = cloneDeep(appServiceToUpdate);
          // Set connector instance id:
          copyOfAppServiceToUpdate.connector_instance_id = updatedElementAsConnectorInstanceElement.backingObject.metadata.id;
          const parentElement = this.getParentElementFromParentId(updatedElementAsConnectorInstanceElement);
          // Need to set the connector id to the parent connector id:
          copyOfAppServiceToUpdate.connector_id = parentElement.id;
          appServicesToUpdateList.push(copyOfAppServiceToUpdate);
        }
      }
    }
  }

  private getAppServicesToUpdateList(updatedElement: ConnectorElement | ConnectorInstanceElement): Array<ApplicationService> {
    const appServicesToUpdateList: Array<ApplicationService> = [];
    this.addAppServicesToUpdateToList(updatedElement, appServicesToUpdateList);
    this.addAndUpdateAppServicesToRemove(updatedElement, appServicesToUpdateList);
    return appServicesToUpdateList;
  }

  private saveNetworks(updatedAppServices: Array<ApplicationService>, notifyUserOnSave = false): void {
    // Need to make a copy of the updatedAppServices or it will be
    // converted to readonly.
    this.store.dispatch(
      savingApplicationServices({
        objs: updatedAppServices,
        trigger_update_side_effects: false,
        notifyUser: notifyUserOnSave,
        // Since a network can only be assigned to one connector and one instance,
        // we need to refresh the data with the latest from the api after saving
        // the network since the back-end will make updates that need to be reflected
        // in the front-end, such as removing a network from a connector status when
        // it is assigned to a differnt connector, or adding a network to a
        // parent connector status when it is added to an instance of that connector.
        refreshData: false,
        refreshApiData: true,
      })
    );
  }

  public updateAgent(updatedConnectorElement: ConnectorElement): void {
    const updatedAgentConnector = this.getAndUpdateAgentConnectorFromTableElement(updatedConnectorElement);
    const appServicesToRemove = this.getAppServicesToRemove(updatedConnectorElement);
    if (appServicesToRemove.length !== 0 && !updatedConnectorElement.ignore_resource_disconnect_check) {
      this.openRemoveResourceFromConnectorWarningDialog(updatedConnectorElement);
      return;
    }
    const appServicesToUpdate = this.getAppServicesToUpdateList(updatedConnectorElement);
    if (!!updatedAgentConnector) {
      this.store.dispatch(savingAgentConnectors({ objs: [updatedAgentConnector], trigger_update_side_effects: false, notifyUser: true }));
    }
    if (appServicesToUpdate.length !== 0) {
      this.saveNetworks(appServicesToUpdate);
    }
    const copyOfUpdatedConnectorElement = cloneDeep(updatedConnectorElement);
    updatedConnectorElement.previous_application_services = copyOfUpdatedConnectorElement.application_services;
    updatedConnectorElement.ignore_resource_disconnect_check = false;
    this.changeDetector.detectChanges();
  }

  private getVpnConnectorFromTableElement(tableElement: ConnectorElement): IpsecConnector | undefined {
    if (tableElement.connector_type !== ConnectorSpec.ConnectorTypeEnum.ipsec) {
      return undefined;
    }
    const result: IpsecConnector = tableElement.backingObject as IpsecConnector;
    result.spec.name = tableElement.name;
    return result;
  }

  private putVpnConnector(itemToUpdate: IpsecConnector): Observable<IpsecConnector> {
    const getter = (item: IpsecConnector) => {
      const getRequestParams: GetIpsecConnectorRequestParams = {
        connector_id: item.metadata.id,
        org_id: this.orgId,
      };
      return this.connectorsService.getIpsecConnector(getRequestParams);
    };
    const putter = (item: IpsecConnector) => {
      const replaceRequestParams: ReplaceIpsecConnectorRequestParams = {
        connector_id: item.metadata.id,
        IpsecConnector: item,
      };
      return this.connectorsService.replaceIpsecConnector(replaceRequestParams);
    };
    return patch_via_put(itemToUpdate, getter, putter);
  }

  private replaceVpn$(updatedConnectorElement: ConnectorElement): Observable<IpsecConnector> {
    const updatedVpnConnector = this.getVpnConnectorFromTableElement(updatedConnectorElement);
    return this.putVpnConnector(updatedVpnConnector);
  }

  // TODO: figure out if we still need this:
  public updateVpn(updatedConnectorElement: ConnectorElement): void {
    const appServicesToUpdate = this.getAppServicesToUpdateList(updatedConnectorElement);
    const replaceVpn$ = this.replaceVpn$(updatedConnectorElement);
    replaceVpn$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (replaceVpnResp) => {
        updatedConnectorElement.backingObject = replaceVpnResp;
        // When connectors are added to the ngrx entity state, this will go in an effect:
        this.saveNetworks(appServicesToUpdate);
        this.notificationService.success(`Connector "${updatedConnectorElement.name}" was successfully updated`);
      },
      (errorResp) => {
        const baseMessage = `Failed to update connector "${updatedConnectorElement.name}"`;
        this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
      },
      () => {
        this.replaceTableWithCopy();
      }
    );
  }

  /**
   * Receives a ConnectorElement from the table then updates and saves
   * the connector and application services.
   */
  public updateEvent(updatedElement: ConnectorElement | ConnectorInstanceElement): void {
    const updatedElementAsConnectorInstanceElement = updatedElement as ConnectorInstanceElement;
    if (updatedElementAsConnectorInstanceElement.parentId !== undefined && updatedElementAsConnectorInstanceElement.parentId !== null) {
      // Connector Instance:
      const appServicesToRemove = this.getAppServicesToRemove(updatedElementAsConnectorInstanceElement);
      if (appServicesToRemove.length !== 0 && !updatedElementAsConnectorInstanceElement.ignore_resource_disconnect_check) {
        this.openRemoveResourceFromConnectorWarningDialog(updatedElementAsConnectorInstanceElement);
        return;
      }
      const appServicesToUpdate = this.getAppServicesToUpdateList(updatedElementAsConnectorInstanceElement);
      if (appServicesToUpdate.length !== 0) {
        this.saveNetworks(appServicesToUpdate, true);
      }
      const copyOfUpdatedConnectorInstanceElement = cloneDeep(updatedElementAsConnectorInstanceElement);
      updatedElementAsConnectorInstanceElement.previous_application_services = copyOfUpdatedConnectorInstanceElement.application_services;
      updatedElementAsConnectorInstanceElement.ignore_resource_disconnect_check = false;
      this.changeDetector.detectChanges();
      return;
    }
    // Parent Connector
    const updatedElementAsConnectorElement = updatedElement as ConnectorElement;
    if (updatedElementAsConnectorElement.connector_type === ConnectorSpec.ConnectorTypeEnum.agent) {
      this.updateAgent(updatedElementAsConnectorElement);
    } else if (updatedElementAsConnectorElement.connector_type === ConnectorSpec.ConnectorTypeEnum.ipsec) {
      this.updateVpn(updatedElementAsConnectorElement);
    }
  }

  private getInstanceFromStaticStats(element: ConnectorInstanceElement): ConnectorInstance | undefined {
    const parentElement = this.getParentElementFromParentId(element);
    const staticStatsData = this.connectorIdToStaticStatsMap.get(parentElement.id);
    const instanceList = !!staticStatsData ? staticStatsData.instances : [];
    for (const instance of instanceList) {
      if (instance.metadata.id === element.backingObject.metadata.id) {
        return instance;
      }
    }
    return undefined;
  }

  /**
   * Nested Table Column
   */
  private getHostnameColumn(): Column<ConnectorInstanceElement> {
    const column = createInputColumn('hostname');
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return 'The hostname of the computer on which the Connector instance is running. This is for informational purposes only.';
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getVersionColumn(): Column<ConnectorInstanceElement> {
    const column = createInputColumn('version');
    column.inputSize = InputSize.SMALL;
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return 'The version of software currently running for this Connector instance. This includes both the version number and the commit reference from which it was built. This is for informational purposes only.';
    };
    return column;
  }

  /**
   * Nested Table Column
   */
  private getInstanceStatusColumn(): Column<ConnectorInstanceElement> {
    const column = createInputColumn('status_value');
    column.displayName = 'Status';
    column.inputSize = InputSize.SMALL;
    column.hasIconPrefix = true;
    column.isReadOnly = () => true;
    column.getHeaderTooltip = () => {
      return 'The summary status of the Connector instance.';
    };
    column.getIconPrefix = this.getStaticStatusIconFromNestedInstanceElement.bind(this);
    column.getIconColor = this.getStaticStatusIconColorFromNestedInstanceElement.bind(this);
    return column;
  }

  /**
   * Nested Table Column
   */
  private getLastReportedColumn(): Column<ConnectorInstanceElement> {
    const column = createInputColumn('last_reported');
    column.displayName = 'Last Reported';
    column.isReadOnly = () => true;
    column.getDisplayValue = (element: ConnectorInstanceElement) => {
      return convertDateToReadableFormat(element.last_reported);
    };
    column.getHeaderTooltip = () => {
      return 'When the Connector instance last reported in.';
    };
    return column;
  }

  /**
   * Nested Table Columns
   */
  private initializeNestedColumnDefs(nestedColumnDefs: Map<string, Column<ConnectorInstanceElement>>): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getHostnameColumn(),
        this.getVersionColumn(),
        this.getInstanceStatusColumn(),
        this.getLastReportedColumn(),
        this.getNetworksColumn(),
      ],
      nestedColumnDefs
    );
  }

  private createConnectorInstanceElement(
    connectorInstance: ConnectorInstance,
    index: number,
    element: ConnectorElement
  ): ConnectorInstanceElement {
    const data: ConnectorInstanceElement = {
      application_services: [],
      previous_application_services: [],
      hostname: connectorInstance.status?.stats?.system?.hostname ? connectorInstance.status.stats.system.hostname : 'Not Available',
      version: connectorInstance.status?.stats?.system?.version ? connectorInstance.status.stats.system.version : 'Not Available',
      status_value: connectorInstance.status.operational_status.status,
      last_reported: connectorInstance.status?.stats?.metadata?.receipt_time
        ? connectorInstance.status.stats.metadata.receipt_time
        : undefined,
      parentId: element.index,
      backingObject: connectorInstance,
      ignore_resource_disconnect_check: false,
      ...getDefaultTableProperties(index),
    };
    const networksList: Array<ApplicationService> = [];
    for (const network of this.applicationServices) {
      if (network.connector_instance_id === connectorInstance.metadata.id) {
        networksList.push(network);
      }
    }
    data.application_services = [...networksList];
    data.previous_application_services = [...networksList];
    return data;
  }

  private setNestedTableData(data: Array<ConnectorElement>): void {
    for (const element of data) {
      element.expandedData = {
        ...getDefaultNestedDataProperties(element),
        nestedRowObjectName: 'INSTANCE',
        nestedButtonsToShow: [ButtonType.DELETE],
      };
      this.initializeNestedColumnDefs(element.expandedData.nestedColumnDefs);
      const staticStatsData = this.connectorIdToStaticStatsMap.get(element.id);
      const instancesList = !!staticStatsData?.instances ? staticStatsData.instances : [];
      for (let i = 0; i < instancesList.length; i++) {
        const connectorInstance = instancesList[i];
        const nestedElement = this.createConnectorInstanceElement(connectorInstance, i, element);
        element.expandedData.nestedTableData.push(nestedElement);
      }
      element.expandedData.nestedColumnDefs.get('application_services').allowedValues = this.applicationServices;
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  public addAppServiceBackToConnectorAfterRemoved(element: ConnectorElement | ConnectorInstanceElement) {
    const elementAsConnectorElement = element as ConnectorElement;
    const elementAsConnectorInstanceElement = element as ConnectorInstanceElement;
    let chiplistValues: Array<ApplicationService> = [];
    if (!!elementAsConnectorElement.id) {
      const targetChiplistInput: ChiplistColumn<ConnectorElement> = this.columnDefs.get('application_services');
      chiplistValues = targetChiplistInput.getChiplistValues(elementAsConnectorElement, targetChiplistInput);
    } else {
      const parentElement = this.getParentElementFromParentId(elementAsConnectorInstanceElement);
      const targetChiplistInput: ChiplistColumn<ConnectorInstanceElement> =
        parentElement.expandedData.nestedColumnDefs.get('application_services');
      chiplistValues = targetChiplistInput.getChiplistValues(elementAsConnectorInstanceElement, targetChiplistInput);
    }
    const removedAppServices: Array<ApplicationService> = [];
    const removedAppServiceNames = findUniqueItems(
      element.previous_application_services.map((appService) => appService.name),
      element.application_services.map((appService) => appService.name)
    );
    for (const appServiceName of removedAppServiceNames) {
      removedAppServices.push(this.appServiceNameToAppServiceMap.get(appServiceName));
    }
    for (const removedAppService of removedAppServices) {
      // add the removed app service back to the chiplist:
      chiplistValues.push(removedAppService);
    }
  }

  private openRemoveResourceFromConnectorWarningDialog(element: ConnectorElement | ConnectorInstanceElement): void {
    const elementAsConnectorElement = element as ConnectorElement;
    const elementAsConnectorInstanceElement = element as ConnectorInstanceElement;
    const targetName = !!elementAsConnectorElement.id ? elementAsConnectorElement.name : elementAsConnectorInstanceElement.hostname;
    const messagePrefix = `Disconnecting Resource from Connector`;
    const message = `Warning: You are about to disconnect one or more resources from connector "${targetName}".
  These resources will not be accessible until you assign them to a connector.\n<br><br>
  Would you like to continue?`;
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: 'Yes', cancel: 'No' },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        element.ignore_resource_disconnect_check = true;
        this.updateEvent(element);
      } else {
        this.addAppServiceBackToConnectorAfterRemoved(element);
        this.tableLayout.triggerChangeDetectionFromParentComponent();
        element.dirty = true;
        element.ignore_resource_disconnect_check = false;
      }
    });
  }

  public getCurrentPageTableDataFromTableLayout(): Array<ConnectorElement> {
    return !!this.tableLayout ? this.tableLayout.getCurrentPageTableData() : [];
  }

  public getCurrentPageConnectorIdsList(): Array<string> {
    return this.getCurrentPageTableDataFromTableLayout().map((connectorElem) => connectorElem.id);
  }

  private restartStatsPolling(): void {
    // Need to restart the polling when the current page of the table is changed
    this.unsubscribeStaticAndDynamicStats$.next();
    this.getStaticStatsForOverview();
    this.getDynamicStatsForOverview();
  }

  private startStatsPolling(): void {
    this.isPollingStats = true;
  }

  private stopStatsPolling(): void {
    this.isPollingStats = false;
  }

  public doOnPageEventFunc() {
    this.restartStatsPolling();
  }

  public triggerRowDirtyEvent(column: Column<ConnectorElement>): void {
    // Stop polling when the table is being modified to avoid overwriting data
    this.stopStatsPolling();
  }

  public triggerRowCheckedEvent(): void {
    let checkedRow = this.tableData.find((element) => element.isChecked);
    if (!checkedRow) {
      for (const row of this.tableData) {
        const nestedTable = !!row.expandedData?.nestedTableData ? row.expandedData.nestedTableData : [];
        for (const nestedRow of nestedTable) {
          if (nestedRow.isChecked) {
            checkedRow = nestedRow;
            break;
          }
        }
      }
    }
    if (!!checkedRow) {
      // Stop polling when rows of the table are selected (checked)
      this.stopStatsPolling();
    } else {
      // Start polling when no rows of the table are selected (checked)
      this.startStatsPolling();
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}
