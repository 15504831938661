import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import {
  Application,
  CataloguesService,
  CatalogueEntry,
  Environment,
  RoleV2,
  FilesService,
  ListFilesRequestParams,
  FileSummary,
} from '@agilicus/angular';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { Subject, Observable, combineLatest, of } from 'rxjs';
import { takeUntil, map, catchError, concatMap } from 'rxjs/operators';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import {
  ActionApiApplicationsModifyCurrentApp,
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsSavingAppIconFile,
  ActionApiApplicationsResetAppIconFileUploadStatus,
  ActionApiApplicationsSavingRole,
} from '@app/core/api-applications/api-applications.actions';
import { cloneDeep } from 'lodash-es';
import {
  getEmptyStringIfUnset,
  getRouterLinkFromPath,
  getAppFromName,
  reloadFormFieldValues,
  getRequiredFormFields,
  setAutocomplete,
  isApplicationExternal,
  modifyDataOnFormBlur,
} from '@app/shared/components/utils';
import { Router } from '@angular/router';
import { MergedRoute } from '@app/core/merged-route';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { MatAccordion } from '@angular/material/expansion';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { setUpstreamConfigIfUnset } from '../application-configs-utils';
import { ActionIssuerClientsInit } from '@app/core/issuer-clients/issuer-clients.actions';
import { selectCurrentOrgIssuer } from '@app/core/organisations/organisations.selectors';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { TabHeaderPosition } from '../tab-header-position.enum';
import { createDialogData } from '../dialog-utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { getDefaultRoleForPublishedApplication, getDefaultApplicationRoleName } from '@app/core/api-applications/api-applications-utils';
import { ApplicationInstancesComponent } from '../application-instances/application-instances.component';
import { ApplicationBundlesComponent } from '../application-bundles/application-bundles.component';
import { ApplicationRolesComponent } from '../application-roles/application-roles.component';
import { ApplicationRulesComponent } from '../application-rules/application-rules.component';
import { WebApplicationSecurityComponent } from '../web-application-security/web-application-security.component';
import { HttpRewritesComponent } from '../http-rewrites/http-rewrites.component';
import { getSmallScreenSizeBreakpoint, getTabHeaderPositionFromScreenSize } from '../tab-utils';
import { selectUI } from '@app/core/ui/ui.selectors';
import { isExpansionPanelOpen } from '@app/core/models/ui/ui-model.utils';
import { AppDefineExpansionPanel, TabGroup, UIState } from '@app/core/ui/ui.models';
import { getNewCrudStateObjGuid } from '@app/core/api/state-driven-crud/state-driven-crud';
import { ActionUIInitAppDefineUIState } from '@app/core/ui/ui.actions';
import { selectPolicyTemplateInstanceResourcesList } from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import {
  getTargetPolicyTemplateInstanceResource,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { getFormattedLowercaseResourceName, getMaxResourceNameLength } from '../resource-utils';
import { FeatureFlagService } from '@app/core/feature-flag/feature-flag.service';

export interface SelectedApplicationState {
  selected_app: Application;
  mergedRoute: MergedRoute;
}

export enum ApplicationFormField {
  name = 'name',
  category = 'category',
  description = 'description',
  contact_email = 'contact_email',
  port = 'port',
  image = 'image',
}

export interface ApplicationFormFieldTouchedState {
  name: boolean;
  category: boolean;
  description: boolean;
  contact_email: boolean;
  port: boolean;
  image: boolean;
}

@Component({
  selector: 'portal-application-define',
  templateUrl: './application-define.component.html',
  styleUrls: ['../../../../styles-tab-component.scss', './application-define.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationDefineComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public org_id: string;
  public applications: Array<Application>;
  private rolesList: Array<RoleV2>;
  private currentApplicationCopy: Application;
  public currentEnvironment: Environment;
  public savingApp = false;
  public creatingNewApp = false;
  public hasAppsPermissions: boolean;
  public applicationSelectorForm: UntypedFormGroup;
  public applicationForm: UntypedFormGroup;
  public applicationNamesList: Array<string> = [];
  private requiredFormFieldNames: Array<string> = [];
  public catalogueEntries: Array<CatalogueEntry> = [];
  public filteredCatalogueEntries$: Observable<Array<string>>;
  public canCreateNewApp = true;
  public cannotCreateAppTooltipText =
    'Application ownership below root is not yet supported. Please create a new application in the parent organisation and then assign here.';
  public fixedTable = false;
  public publishApplicationTooltipText = `Checking this box makes the application available for users to request access to the application from their profiles. 
    Not checking it will mean it remains hidden and not available for requests. 
    Before you can publish the application, it must have a "default role" set. 
    If you wish to automatically set a "default role" of "self", click "Yes" after selecting the Publish Application option. 
    Alternatively, you may select a "default role" from the Application Roles panel under the Security tab. 
    If no roles exist, please create one and select it as the "default role".`;
  public makeExternalTooltipText = `Checking this box means the application runs elsewhere on the internet and not the Agilicus hosting platform. 
    External applications will not create instances in the Agilicus hosting platform. 
    However, they will be able to use Agilicus for Identity and Authentication.`;
  public externalApplicationDisabledPanelTooltipText = 'This section is not applicable to external applications';
  public upstreamConfigForm: UntypedFormGroup;
  public currentOrgIssuer: string;
  private currentAppFiles: Array<FileSummary>;
  private localRefreshDataValue = 0;
  private policyTemplateInstanceResource: PolicyTemplateInstanceResource;
  private canOrgCreateHostedApplications = false;
  private doesOrgUsePolicyRules = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  // Expansion panels
  @ViewChild(MatAccordion) public accordion: MatAccordion;

  @ViewChild(ApplicationInstancesComponent) public instances: ApplicationInstancesComponent;
  @ViewChild(ApplicationBundlesComponent) public bundles: ApplicationBundlesComponent;
  @ViewChild(ApplicationRolesComponent) public roles: ApplicationRolesComponent;
  @ViewChild(ApplicationRulesComponent) public rules: ApplicationRulesComponent;
  @ViewChild(WebApplicationSecurityComponent) public webApplicationSecurity: WebApplicationSecurityComponent;
  @ViewChild(HttpRewritesComponent) public httpRewrites: HttpRewritesComponent;

  public isUploading = false;
  public progressBarController: ProgressBarController = new ProgressBarController();

  public isApplicationExternal = isApplicationExternal;
  public pageDescriptiveText = `View and configure the detailed web application configuration, including Web Application Firewall, security headers, multiple running instances, security roles, firewall rules`;
  public defineProductGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-define`;

  public hostingProductGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-hosting`;
  public hostingDescriptiveText = `Configure the hosting properties of your application. 
  For example, here you can change the container image or application bundle. 
  Only use this tab if you are running an application inside the Agilicus platform. 
  This means you are providing a container image. 
  This is not a common use case, and most users will not use this tab.`;

  public securityProductGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-security`;
  public securityDescriptiveText = `Configure the security properties of your application. 
  Here you control who can access what, as well as common application security standards like Content Security Policy.`;

  public proxyProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/`;
  public proxyDescriptiveText = `Configure the proxy properties of your application. 
  Here you control how external clients access your application through an Agilicus proxy by configuring things like the HTTP host the application sees.`;
  public proxiedServiceConfigProductGuideLink = `https://www.agilicus.com/anyx-guide/proxy/#h-proxied-service-configuration`;
  public proxiedServiceConfigDescriptiveText = `Configure how the proxy communicates with the main application server here. 
  This field should not be used in normal circumstances. 
  It allows proxying to an external host (e.g. for demonstration purposes).`;

  public isSmallScreen = false;
  private smallScreenSizeBreakpoint = getSmallScreenSizeBreakpoint();

  public uiState: UIState;
  public isExpansionPanelOpen = isExpansionPanelOpen;
  public tabsLength = 4;
  public tabGroupId = TabGroup.appDefineTabGroup;
  public localTabIndex: number;

  // This is required in order to reference the enums in the html template.
  public appDefineExpansionPanel = AppDefineExpansionPanel;

  /**
   * We need to keep track of when a form field has been touched so that
   * validation error messages do not disappear when the state is refreshed.
   */
  private applicationFormFieldTouchedState: ApplicationFormFieldTouchedState = {
    name: false,
    category: false,
    description: false,
    contact_email: false,
    port: false,
    image: false,
  };

  constructor(
    private customValidatorsService: CustomValidatorsService,
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private notificationService: NotificationService,
    private cataloguesService: CataloguesService,
    private routerHelperService: RouterHelperService,
    public publishDialog: MatDialog,
    public externalDialog: MatDialog,
    private files: FilesService,
    private featureFlagService: FeatureFlagService
  ) {}

  @HostListener('window:resize', ['$event'])
  private onResize(event: Event): void {
    this.doResize();
  }

  public ngOnInit(): void {
    this.isSmallScreen = window.innerWidth < this.smallScreenSizeBreakpoint;
    this.doResize();
    this.store.dispatch(new ActionApiApplicationsInitApplications(true));
    this.store.dispatch(new ActionIssuerClientsInit(true));
    this.store.dispatch(new ActionUIInitAppDefineUIState());
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    const appState$ = this.store.pipe(select(selectApiApplications));
    const uiState$ = this.store.pipe(select(selectUI));
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const catalogueEntries$ = this.getCatalogueEntries$();
    const currentOrgIssuer$ = this.store.pipe(select(selectCurrentOrgIssuer));
    const policyTemplateInstanceResourceListState$ = this.store.pipe(select(selectPolicyTemplateInstanceResourcesList));
    const canOrgCreateHostedApplications$ = this.featureFlagService.canOrgCreateHostedApplications$();
    const doesOrgUsePolicyRules$ = this.featureFlagService.doesOrgUsePolicyRules$();
    combineLatest([
      appState$,
      hasAppsPermissions$,
      catalogueEntries$,
      currentOrgIssuer$,
      uiState$,
      policyTemplateInstanceResourceListState$,
      canOrgCreateHostedApplications$,
      doesOrgUsePolicyRules$,
    ])
      .pipe(
        concatMap(
          ([
            appStateResp,
            hasAppsPermissionsResp,
            catalogueResp,
            currentOrgIssuerResp,
            uiStateResp,
            policyTemplateInstanceResourceListStateResp,
            canOrgCreateHostedApplicationsResp,
            doesOrgUsePolicyRulesResp,
          ]) => {
            if (!hasAppsPermissionsResp?.orgId || !appStateResp?.current_application) {
              return combineLatest([
                of(appStateResp),
                of(hasAppsPermissionsResp),
                of(catalogueResp),
                of(currentOrgIssuerResp),
                of(uiStateResp),
                of(policyTemplateInstanceResourceListStateResp),
                of(canOrgCreateHostedApplicationsResp),
                of(doesOrgUsePolicyRulesResp),
                of(undefined),
              ]);
            }
            let appFiles$: Observable<Array<FileSummary> | undefined> = of(undefined);
            // Only fetch the files when the app changes or the application is updated:
            if (
              !this.currentApplicationCopy ||
              this.currentApplicationCopy.id !== appStateResp.current_application.id ||
              this.localRefreshDataValue !== appStateResp.refresh_data
            ) {
              appFiles$ = this.getApplicationFiles(hasAppsPermissionsResp.orgId, appStateResp.current_application.id);
            }
            return combineLatest([
              of(appStateResp),
              of(hasAppsPermissionsResp),
              of(catalogueResp),
              of(currentOrgIssuerResp),
              of(uiStateResp),
              of(policyTemplateInstanceResourceListStateResp),
              of(canOrgCreateHostedApplicationsResp),
              of(doesOrgUsePolicyRulesResp),
              appFiles$,
            ]);
          }
        )
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          appStateResp,
          hasAppsPermissionsResp,
          catalogueResp,
          currentOrgIssuerResp,
          uiStateResp,
          policyTemplateInstanceResourceListStateResp,
          canOrgCreateHostedApplicationsResp,
          doesOrgUsePolicyRulesResp,
          appFilesResp,
        ]) => {
          if (!this.localTabIndex) {
            // We only want to set the localTabIndex to the index from the UI State
            // when first loading the page. Otherwise, multiple quick changes to the index
            // would result in the previous UI state value overwriting the currently
            // selected local value before the UI State is updated to match the current local value.
            this.localTabIndex = uiStateResp.tabsState.tabs[this.tabGroupId];
          }
          this.canOrgCreateHostedApplications = canOrgCreateHostedApplicationsResp;
          this.doesOrgUsePolicyRules = doesOrgUsePolicyRulesResp;
          this.savingApp = appStateResp?.saving_app;
          this.creatingNewApp = appStateResp?.creating_new_app;
          this.uiState = uiStateResp;
          this.hasAppsPermissions = hasAppsPermissionsResp?.hasPermission;
          if (!this.hasAppsPermissions || !appStateResp || !appStateResp.current_application || !!this.savingApp) {
            // Need this in order for the "No Permissions" text to be displayed when the page first loads.
            this.changeDetector.detectChanges();
            return;
          }
          this.org_id = hasAppsPermissionsResp.orgId;
          this.catalogueEntries = catalogueResp;
          this.applications = appStateResp.applications;
          this.rolesList = appStateResp.current_roles_list;
          this.currentApplicationCopy = cloneDeep(appStateResp.current_application);
          this.currentEnvironment = appStateResp.current_environment;
          this.currentOrgIssuer = currentOrgIssuerResp;
          if (!!appFilesResp) {
            this.currentAppFiles = appFilesResp;
          }
          this.handleProgressBarState(appStateResp);
          const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
            ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplicationCopy?.id)
            : undefined;
          this.policyTemplateInstanceResource = targetPolicyTemplateInstanceResource;
          if (
            this.localRefreshDataValue !== appStateResp.refresh_data ||
            !this.applicationForm ||
            !this.applicationSelectorForm ||
            !this.upstreamConfigForm
          ) {
            this.initializeFormGroups();
            reloadFormFieldValues(this.currentApplicationCopy, this.applicationForm, this.requiredFormFieldNames, this.changeDetector);
            this.setPortDisplayValue(this.currentApplicationCopy);
            this.setImageDisplayValue(this.currentApplicationCopy);
            this.localRefreshDataValue = appStateResp.refresh_data;
          }
          this.applicationNamesList = this.applications.map((app) => app.name);
          this.filteredCatalogueEntries$ = setAutocomplete(
            this.applicationForm.get('image'),
            this.catalogueEntries.map((e) => this.getCatalogueEntryDisplayValue(e))
          );
          this.fixedTable = !this.areTablesEditable();
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getApplicationFiles(orgId: string, appId: string): Observable<Array<FileSummary> | undefined> {
    if (!orgId || !appId) {
      return of(undefined);
    }
    const args: ListFilesRequestParams = { org_id: orgId, tag: appId };
    return this.files.listFiles(args).pipe(map((resp) => resp.files));
  }

  private getCatalogueEntries$(): Observable<Array<CatalogueEntry>> {
    return this.cataloguesService.listAllCatalogueEntries({ catalogue_category: 'application_runtimes' }).pipe(
      map((catalogueResp) => {
        return catalogueResp.catalogue_entries;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }

  public initializeAppSelectorFormGroup(): void {
    this.applicationSelectorForm = this.formBuilder.group({
      app_selection: {
        value: this.currentApplicationCopy.name,
        disabled: this.creatingNewApp || this.applications.length === 0,
      },
    });
    this.changeDetector.detectChanges();
  }

  public initializeAppFormGroup(): void {
    const portValidators = [this.customValidatorsService.portValidator(), Validators.max(65535)];
    const imageValidators = [];
    if (!isApplicationExternal(this.currentApplicationCopy)) {
      portValidators.push(Validators.required);
      imageValidators.push(Validators.required);
    }
    this.applicationForm = this.formBuilder.group({
      category: [
        {
          value: getEmptyStringIfUnset(this.currentApplicationCopy.category),
          disabled: !this.isAppEditable(),
        },
        [Validators.required, Validators.maxLength(100)],
      ],
      contact_email: [
        {
          value: getEmptyStringIfUnset(this.currentApplicationCopy.contact_email),
          disabled: !this.isAppEditable(),
        },
        [Validators.email, Validators.maxLength(100)],
      ],
      description: [
        {
          value: getEmptyStringIfUnset(this.currentApplicationCopy.description),
          disabled: !this.isAppEditable(),
        },
        Validators.required,
      ],
      image: [
        {
          value: getEmptyStringIfUnset(this.currentApplicationCopy.image),
          disabled: !this.isAppEditable() || isApplicationExternal(this.currentApplicationCopy),
        },
        imageValidators,
      ],
      location: this.currentApplicationCopy.location === Application.LocationEnum.external,
      name: [
        {
          value: getEmptyStringIfUnset(this.currentApplicationCopy.name),
          disabled: !this.isAppEditable() || !this.creatingNewApp,
        },
        [
          Validators.required,
          this.customValidatorsService.fqdnSingleLabelNameValidator(),
          Validators.maxLength(getMaxResourceNameLength()),
          this.customValidatorsService.uniqueAppNameValidator(this.applications, this.currentApplicationCopy.id),
        ],
      ],
      port: [
        {
          value: getEmptyStringIfUnset(this.currentApplicationCopy.port),
          disabled:
            !this.isAppEditable() ||
            !this.shouldPortBeSet(this.currentApplicationCopy) ||
            isApplicationExternal(this.currentApplicationCopy),
        },
        portValidators,
      ],
      published: this.currentApplicationCopy.published === Application.PublishedEnum.public,
    });
    this.requiredFormFieldNames = getRequiredFormFields(this.applicationForm);
    this.changeDetector.detectChanges();
  }

  /**
   * When we update data on blur we need to check the state of the form
   * so that validation error messages do not vanish. Marking the fields as
   * "touched" is required in order for the errors messages to be displayed.
   */
  private setAppFormGroupState(): void {
    const formFieldNamesArray = Object.values(ApplicationFormField);
    for (const formFieldName of formFieldNamesArray) {
      if (this.applicationFormFieldTouchedState[formFieldName]) {
        this.applicationForm.get(formFieldName).markAsTouched();
      }
    }
  }

  private isCurrentlyEditingAppForm(): boolean {
    const formFieldNamesArray = Object.values(ApplicationFormField);
    for (const formFieldName of formFieldNamesArray) {
      if (this.applicationFormFieldTouchedState[formFieldName]) {
        return true;
      }
    }
    return false;
  }

  private initializeFormGroups(): void {
    this.initializeAppSelectorFormGroup();
    this.initializeAppFormGroup();
    this.initializeUpstreamConfigFormGroup();
    this.setAppFormGroupState();
    this.changeDetector.detectChanges();
  }

  private getCatalogueEntryFromAppImage(app: Application): CatalogueEntry | undefined {
    for (const entry of this.catalogueEntries) {
      if (entry.content === app.image) {
        return entry;
      }
    }
    return undefined;
  }

  private shouldPortBeSet(app: Application): boolean {
    const targetEntry = this.getCatalogueEntryFromAppImage(app);
    if (targetEntry) {
      return false;
    }
    return true;
  }

  private setPortDisplayValue(app: Application): void {
    const portFormControl = this.applicationForm.get('port');
    if (this.shouldPortBeSet(app)) {
      portFormControl.setValue(getEmptyStringIfUnset(app.port));
      return;
    }
    portFormControl.setValue('');
  }

  private setImageDisplayValue(app: Application): void {
    const imageFormControl = this.applicationForm.get('image');
    let displayValue = getEmptyStringIfUnset(app.image);
    const targetEntry = this.getCatalogueEntryFromAppImage(app);
    if (targetEntry) {
      displayValue = this.getCatalogueEntryDisplayValue(targetEntry);
    }
    imageFormControl.setValue(displayValue);
  }

  private setAppFromForm(): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    const categoryFormValue = this.applicationForm.get('category').value;
    copyOfCurrentApplicationCopy.category = categoryFormValue.trim();
    const contactEmailFormValue = this.applicationForm.get('contact_email').value;
    copyOfCurrentApplicationCopy.contact_email = contactEmailFormValue.trim();
    const descriptionFormValue = this.applicationForm.get('description').value;
    copyOfCurrentApplicationCopy.description = descriptionFormValue.trim();
    const nameFormValue = this.applicationForm.get('name').value;
    copyOfCurrentApplicationCopy.name = getFormattedLowercaseResourceName(nameFormValue);
    this.setImageFromForm(copyOfCurrentApplicationCopy);
    this.setPortFromForm(copyOfCurrentApplicationCopy);
    this.setPublishedFromForm(copyOfCurrentApplicationCopy);
    this.setLocationFromForm(copyOfCurrentApplicationCopy);
    this.currentApplicationCopy = copyOfCurrentApplicationCopy;
  }

  private setImageFromForm(app: Application): void {
    const imageFormValue = this.applicationForm.get('image').value;
    for (const entry of this.catalogueEntries) {
      const displayValue = this.getCatalogueEntryDisplayValue(entry);
      if (displayValue === imageFormValue) {
        app.image = entry.content;
        return;
      }
    }
    app.image = imageFormValue.trim();
  }

  private setPortFromForm(app: Application): void {
    if (!this.shouldPortBeSet(app)) {
      return;
    }
    const portFormValue = this.applicationForm.get('port').value;
    if (portFormValue !== '') {
      // This check prevents the port field from being populated with
      // 'NaN' when other form fields are updated.
      app.port = parseInt(portFormValue, 10);
    }
  }

  private setPublishedFromForm(app: Application): void {
    const publishedFormValue = this.applicationForm.get('published').value;
    if (publishedFormValue) {
      app.published = Application.PublishedEnum.public;
      return;
    }
    app.published = Application.PublishedEnum.no;
  }

  private setLocationFromForm(app: Application): void {
    const locationIsExternal = this.applicationForm.get('location').value;
    if (locationIsExternal) {
      app.location = Application.LocationEnum.external;
      delete app.port;
      delete app.image;
      return;
    }
    app.location = Application.LocationEnum.hosted;
  }

  public selectCurrentApp(optionValue: string): void {
    const selectedApp = getAppFromName(optionValue, this.applications);
    if (selectedApp === undefined) {
      this.notificationService.error('There was an error retrieving the selected application');
    }
    const routerLink = getRouterLinkFromPath();
    this.router.navigate([routerLink, selectedApp.id], {
      queryParams: { org_id: this.org_id },
    });
  }

  /**
   * This is called when a user clicks the "CREATE" button under the define screen's "common" tab
   */
  public createNewlyDefinedApplication(): void {
    if (this.applicationForm.invalid) {
      return;
    }
    if (this.currentApplicationCopy.id === getNewCrudStateObjGuid()) {
      this.currentApplicationCopy.org_id = this.org_id;
    }
    if (this.currentApplicationCopy.published === Application.PublishedEnum.public) {
      // We cannot set to public until the default role is set, so we need to unset "public" before posting the new application.
      this.currentApplicationCopy.published = Application.PublishedEnum.no;
      this.store.dispatch(
        new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy, true, false, this.arePolicyRulesEnabled())
      );
      return;
    }
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy, false, false, this.arePolicyRulesEnabled()));
  }

  public isAppEditable(): boolean {
    if (!this.currentApplicationCopy.owned) {
      return false;
    }
    if (this.applications.length !== 0) {
      return true;
    }
    if (this.creatingNewApp) {
      return true;
    }
    return false;
  }

  public areTablesEditable(): boolean {
    return this.isAppEditable() && !this.creatingNewApp;
  }

  public onFormBlur(form: UntypedFormGroup, formField: string): void {
    this.applicationFormFieldTouchedState[formField] = true;
    modifyDataOnFormBlur(form, formField, this.modifyApplicationOnUpdate.bind(this));
  }

  private setAllFieldsFromForms(): void {
    this.setAppFromForm();
    this.setUpstreamConfigFromForm();
  }

  private modifyApplicationOnUpdate(): void {
    this.setAllFieldsFromForms();
    this.modifyApplication();
  }

  private modifyApplication(): void {
    if (this.creatingNewApp) {
      return;
    }
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  private getCatalogueEntryDisplayValue(entry: CatalogueEntry): string {
    return entry.name;
  }

  public onPublishCheckboxChange(isChecked: boolean): void {
    if (isChecked && !this.isDefaultRoleSet()) {
      this.openPublishApplicationDialog();
      return;
    }
    this.updateApplicationFromAppForm();
  }

  public onExternalCheckboxChange(isChecked: boolean): void {
    if (isChecked && !this.creatingNewApp) {
      this.openExternalApplicationDialog();
      return;
    }
    this.updateApplicationFromAppForm();
  }

  public uploadFile(file: File): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.isUploading = true;
    this.store.dispatch(new ActionApiApplicationsSavingAppIconFile(file));
  }

  public handleFileDropError(): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.resetProgressBar();
    this.notificationService.error('Cannot upload multiple files. Please select a single file for upload.');
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }

  private handleProgressBarState(appStateResp: ApiApplicationsState): void {
    if (appStateResp.saving_app_icon_file) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.initializeProgressBar();
      this.isUploading = true;
    }
    if (!appStateResp.app_icon_file_save_success && !appStateResp.app_icon_file_save_fail) {
      return;
    }
    if (appStateResp.app_icon_file_save_success) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.updateProgressBarValue(1, 1);
      this.delayHideProgressBar();
    }
    if (appStateResp.app_icon_file_save_fail) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.onFailedUpload();
    }
    this.store.dispatch(new ActionApiApplicationsResetAppIconFileUploadStatus());
    this.isUploading = false;
  }

  private isIconMissing(): boolean {
    if (!this.currentApplicationCopy?.icon_url) {
      // The application has not been assigned an icon file, so nothing is missing
      return false;
    }
    const targetFile = this.currentAppFiles?.find((file) => file.public_url === this.currentApplicationCopy?.icon_url);
    return !targetFile;
  }

  public getAppIconUrl(): string | undefined {
    if (this.isIconMissing()) {
      return undefined;
    }
    return !!this.currentApplicationCopy?.icon_url ? this.currentApplicationCopy.icon_url : '';
  }

  public getDisabledAppConfigTooltipText(): string {
    if (!this.currentEnvironment) {
      return 'No instances have been configured for this application. You can add one using the "Instances" panel under the "Hosted" tab.';
    }
    return this.externalApplicationDisabledPanelTooltipText;
  }

  private initializeUpstreamConfigFormGroup(): void {
    this.upstreamConfigForm = this.formBuilder.group({
      hostname: [
        getEmptyStringIfUnset(this.currentEnvironment?.application_configs?.oidc_config?.upstream_config?.hostname),
        [this.customValidatorsService.hostnameOrIP4Validator()],
      ],
    });
  }

  private setUpstreamConfigFromForm(): void {
    for (const env of this.currentApplicationCopy.environments) {
      setUpstreamConfigIfUnset(env, this.currentOrgIssuer);
      env.application_configs.oidc_config.upstream_config.hostname = this.upstreamConfigForm.get('hostname').value;
    }
  }

  public returnToOverview(): void {
    this.routerHelperService.redirect('application-overview/', {
      org_id: this.org_id,
    });
  }

  public getApplicationSelectorTooltipText(): string {
    if (this.creatingNewApp) {
      return `You are currently creating a new application which has not yet been saved`;
    }
    if (this.applications.length === 0) {
      return `No applications currently exist for this organisation`;
    }
    return `This is the name of the current application you are viewing below. 
    You can view/modify a different application by selecting one from this list.`;
  }

  private doResize(): void {
    if (window.innerWidth < this.smallScreenSizeBreakpoint) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }
  }

  public getTabHeaderPositionFromScreenSizeFunc(): TabHeaderPosition {
    return getTabHeaderPositionFromScreenSize(this.isSmallScreen);
  }

  public getCreateButtonTooltipText(): string {
    if (!this.canCreateNewApp) {
      return this.cannotCreateAppTooltipText;
    }
    if (!this.creatingNewApp) {
      return `This button only applies to creating a new application. You are currently viewing/modifying an existing application.`;
    }
    if (!this.applicationForm.valid) {
      return `Please ensure the form is valid and complete before creating`;
    }
    return `Apply changes to a newly created application`;
  }

  public getAppNameTitleDisplayValue(): string {
    const appNameFormFieldValue = this.applicationForm?.get('name')?.value;
    if (!appNameFormFieldValue) {
      return 'Unset';
    }
    return appNameFormFieldValue;
  }

  public openPublishApplicationDialog(): void {
    const messagePrefix = `Publish Application`;
    const message = `Warning: You have not assigned a "default role" to this application. 
    Would you like a role of "self" assigned as the default? 
    If not, you will need to assign a "default role" manually using the Application Roles panel under the Security tab before the application can be made public.`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.publishDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.publishApplication();
        return;
      }
      this.unsetPublished();
    });
  }

  private unsetPublished(): void {
    this.applicationForm.get('published').setValue(false);
  }

  private isDefaultRoleSet(): boolean {
    return !!this.currentApplicationCopy.default_role_id;
  }

  private publishApplication(): void {
    if (this.creatingNewApp) {
      this.modifyApplicationOnUpdate();
      return;
    }
    const existingDefaultRole = this.getDefaultRoleIfItExists();
    if (!!existingDefaultRole) {
      this.setDefaultRoleFromExistingAndPublish(existingDefaultRole);
      return;
    }
    this.createAndSetDefaultRole();
  }

  private getDefaultRoleIfItExists(): RoleV2 | undefined {
    for (const role of this.rolesList) {
      if (role.spec.name === getDefaultApplicationRoleName()) {
        return role;
      }
    }
    return undefined;
  }

  private setDefaultRoleFromExistingAndPublish(role: RoleV2): void {
    this.currentApplicationCopy.default_role_id = role.metadata.id;
    this.currentApplicationCopy.published = Application.PublishedEnum.public;
    this.modifyApplication();
  }

  private createAndSetDefaultRole(): void {
    const defaultRole = getDefaultRoleForPublishedApplication(this.currentApplicationCopy, this.org_id);
    this.store.dispatch(new ActionApiApplicationsSavingRole(defaultRole, true));
  }

  public openExternalApplicationDialog(): void {
    const messagePrefix = `Make Application External`;
    const message = `Warning: This will disable your running instances. Would you like to continue?`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.externalDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.makeApplicationExternal();
        return;
      }
      this.unsetExternal();
    });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    const selectedTab = this.uiState?.tabsState?.tabs[this.tabGroupId];
    if (selectedTab === 1) {
      const instancesValidate = this?.instances ? this.instances.canDeactivate() : true;
      const bundlesValidate = this?.bundles ? this.bundles.canDeactivate() : true;
      return instancesValidate && bundlesValidate;
    }
    if (selectedTab === 2) {
      const rolesValidate = this?.roles ? this.roles.canDeactivate() : true;
      const rulesValidate = this?.rules ? this.rules.canDeactivate() : true;
      const webApplicationSecurityValidate = this?.webApplicationSecurity ? this.webApplicationSecurity.canDeactivate() : true;
      return rolesValidate && rulesValidate && webApplicationSecurityValidate;
    }
    if (selectedTab === 3) {
      const httpRewritesValidate = this?.httpRewrites ? this.httpRewrites.canDeactivate() : true;
      return httpRewritesValidate;
    }
    return true;
  }

  private unsetExternal(): void {
    this.applicationForm.get('location').setValue(false);
  }

  private makeApplicationExternal(): void {
    this.updateApplicationFromAppForm();
  }

  private updateApplicationFromAppForm(): void {
    this.setAppFromForm();
    this.modifyApplication();
  }

  public disableInstanceRequiredPanel(): boolean {
    return isApplicationExternal(this.currentApplicationCopy) || !this.currentEnvironment;
  }

  /**
   * We need to update the applicationSelectorForm with the new app name
   * in addition to updating the application object
   */
  public onApplicationNameChange(): void {
    this.onFormBlur(this.applicationForm, 'name');
    this.applicationSelectorForm.get('app_selection').setValue(this.applicationForm.get('name').value);
  }

  public isHostedApplicationsEnabled(): boolean {
    return this.canOrgCreateHostedApplications;
  }

  public arePolicyRulesEnabled(): boolean {
    return this.doesOrgUsePolicyRules;
  }

  public appHasPolicyTemplateRules(): boolean {
    return this.policyTemplateInstanceResource !== undefined;
  }
}
