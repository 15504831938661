<div
  class="tab-component-outer-container"
  portalSwipeable [appTabState]="uiState?.tabsState" [tabGroupId]="tabGroupId" [tabsLength]="tabsLength"
>
  <div class="top-header top">
    <div
      *ngIf="applications !== undefined"
      class="main-selector-container"
    >
      <form
        [formGroup]="applicationSelectorForm"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-icon
          class="back-arrow"
          (click)="returnToOverview()"
          matTooltip="Return to Application Overview"
        >
          arrow_back
        </mat-icon>
        <span class="title-prefix">Define Application:</span>
        <mat-form-field>
          <mat-label *ngIf="applications.length === 0 || creatingNewApp">{{ getAppNameTitleDisplayValue() }}</mat-label>
          <mat-select
            #select
            formControlName="app_selection"
            (selectionChange)="selectCurrentApp($event.value)"
            (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
          >
            <mat-option
              *ngFor="let option of applicationNamesList"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon
          class="help-icon"
          [matTooltip]="getApplicationSelectorTooltipText()"
        >
          help_outline
        </mat-icon>
      </form>
    </div>
  </div>

  <!-- This empty div is so the background of the tab header will appear to span the full screen width -->
  <div class="background-span" *ngIf="!isSmallScreen && hasAppsPermissions"></div>

  <div class="main-content">
    <div *ngIf="hasAppsPermissions !== undefined && !hasAppsPermissions">
      <portal-no-permissions></portal-no-permissions>
    </div>

    <div *ngIf="hasAppsPermissions" class="content-container">
      <div class="tab-group-container row content">
        <mat-tab-group
          [headerPosition]="getTabHeaderPositionFromScreenSizeFunc()"
          mat-stretch-tabs
          animationDuration="0ms"
          class="tab-group-overrides"
          [ngClass]="{ 'fixed-tabs-top': !isSmallScreen }"
          [selectedIndex]="localTabIndex"
          portalTabState [appTabState]="uiState.tabsState" [tabGroupId]="tabGroupId"
        >
          <!-- Common Tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                settings_applications
              </mat-icon>
              <span>Common</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card class="tab-card-container">
                <div class="tab-page-info">
                  <portal-page-info
                    pageDescriptiveText="Configure the common properties of your application. Set up configuration which is present on all instances, all configurations."
                    [productGuideLink]="defineProductGuideLink"
                  >
                  </portal-page-info>
                </div>
                <div
                  *ngIf="!!applicationForm"
                  class="content-container"
                >
                  <div class="application-define-form-container">
                    <form
                      *ngIf="applications !== undefined"
                      class="app-update-form"
                      [formGroup]="applicationForm"
                      spellcheck="false"
                      autocomplete="off"
                    >
                      <div class="left-app-define-form-section">
                        <mat-form-field>
                          <input
                            matInput
                            type="text"
                            placeholder="Name"
                            formControlName="name"
                            required
                            (blur)="onApplicationNameChange()"
                            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                            matTooltip="Application name cannot be changed"
                            [matTooltipDisabled]="creatingNewApp"
                          />
                          <mat-error>A valid application name is required</mat-error>
                        </mat-form-field>
            
                        <mat-form-field
                          hintLabel="Application type, ie 'fire', 'water', 'police', etc..."
                          class="extended-field"
                        >
                          <input
                            matInput
                            type="text"
                            placeholder="Category"
                            formControlName="category"
                            required
                            (blur)="onFormBlur(applicationForm, 'category')"
                            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                          />
                          <mat-error>Category is required</mat-error>
                        </mat-form-field>
                  
                        <mat-form-field class="extended-field">
                          <input
                            matInput
                            type="text"
                            placeholder="Description"
                            formControlName="description"
                            required
                            (blur)="onFormBlur(applicationForm, 'description')"
                            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                            spellcheck="true"
                          />
                          <mat-error>Description is required</mat-error>
                        </mat-form-field>
                  
                        <mat-form-field class="extended-field">
                          <input
                            matInput
                            type="text"
                            placeholder="Contact Email"
                            formControlName="contact_email"
                            (blur)="onFormBlur(applicationForm, 'contact_email')"
                            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                          />
                          <mat-error>Please enter a valid email address</mat-error>
                        </mat-form-field>
                  
                        <mat-form-field class="extended-field">
                          <input
                            matInput
                            type="text"
                            placeholder="Port"
                            formControlName="port"
                            required
                            (blur)="onFormBlur(applicationForm, 'port')"
                            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                          />
                          <mat-error>A valid port is required</mat-error>
                        </mat-form-field>
                  
                        <mat-form-field
                          *ngIf="isHostedApplicationsEnabled()"
                          appearance="fill"
                          matTooltip="Select an existing image runtime from the list or type your own"
                        >
                          <mat-label>Image Runtime</mat-label>
                          <input
                            matInput
                            type="text"
                            placeholder="Image Runtime"
                            formControlName="image"
                            [matAutocomplete]="autoImage"
                            (blur)="onFormBlur(applicationForm, 'image')"
                            #trigger="matAutocompleteTrigger"
                            (keyup.enter)="
                              keyTabManager.keyTabAutoInput($event.target.id, trigger)
                            "
                          />
                          <span matSuffix><mat-icon>arrow_drop_down</mat-icon></span>
                          <mat-autocomplete #autoImage="matAutocomplete">
                            <mat-option
                              *ngFor="let option of filteredCatalogueEntries$ | async"
                              [value]="option"
                            >
                              {{ option }}
                            </mat-option>
                          </mat-autocomplete>
                          <mat-error>Image runtime is required</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="right-app-define-form-section">
                        <div class="checkbox-container">
                          <mat-checkbox
                            formControlName="published"
                            (change)="onPublishCheckboxChange($event.checked)"
                            aria-label="Checkbox that toggles whether the application is published"
                          >
                            Publish Application
                          </mat-checkbox>
                          <mat-icon 
                            class="help-icon" 
                            [matTooltip]="publishApplicationTooltipText"
                          >
                            help_outline
                          </mat-icon>
                        </div>
                        
                        <div class="checkbox-container">
                          <mat-checkbox
                            formControlName="location"
                            (change)="onExternalCheckboxChange($event.checked)"
                            aria-label="Checkbox that toggles whether the application is external"
                          >
                            External
                          </mat-checkbox>
                          <mat-icon 
                            class="help-icon" 
                            [matTooltip]="makeExternalTooltipText"
                          >
                            help_outline
                          </mat-icon>
                        </div>

                        <portal-upload-area
                          defaultText="Logo"
                          [iconUrl]="getAppIconUrl()"
                          [isUploading]="isUploading"
                          tooltipText="Drop a file here or click to select a file to upload"
                          (uploadFile)="uploadFile($event)"
                          (handleFileDropError)="handleFileDropError()"
                        >
                        </portal-upload-area>

                        <portal-progress-bar
                          [progressBarController]="progressBarController"
                        >
                        </portal-progress-bar>
                      </div>
                    </form>

                    <div
                      class="apply-button-container"
                      [matTooltip]="getCreateButtonTooltipText()"
                      aria-label="Displays a tooltip for the create application button"
                    >
                      <button
                        type="button"
                        mat-raised-button
                        color="primary"
                        class="expansion-control-button"
                        aria-label="Button to apply changes to a newly created application"
                        (click)="createNewlyDefinedApplication()"
                        [disabled]="savingApp || !applicationForm.valid || !canCreateNewApp || !creatingNewApp"
                      >
                        CREATE
                      </button>
                    </div>
                  </div>
                </div>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- Hosting Tab -->
          <mat-tab [disabled]="creatingNewApp">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                cloud
              </mat-icon>
              <span>Hosting</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card class="tab-card-container">
                <div class="tab-page-info">
                  <portal-page-info
                    [pageDescriptiveText]="hostingDescriptiveText"
                    [productGuideLink]="hostingProductGuideLink"
                  >
                  </portal-page-info>
                </div>
                <div class="content-container">
                  <mat-accordion class="expansion-container" multi>
                    <!-- Instances Panel -->
                    <mat-expansion-panel
                      #appDefineInstancesPanel
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineInstancesPanel" [initializeAsOpen]="true"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Instances
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineInstancesPanel)">
                          Run multiple copies of an application with different versions
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <portal-application-instances
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineInstancesPanel)"
                        [fixedTable]="fixedTable"
                        class="embedded-table"
                      >
                      </portal-application-instances>
                    </mat-expansion-panel>

                    <!-- Bundles Panel -->
                    <mat-expansion-panel
                      *ngIf="isHostedApplicationsEnabled()"
                      #appDefineBundlesPanel
                      [disabled]="isApplicationExternal(currentApplicationCopy)"
                      [matTooltip]="externalApplicationDisabledPanelTooltipText"
                      [matTooltipDisabled]="!isApplicationExternal(currentApplicationCopy)"
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineBundlesPanel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Bundles
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineBundlesPanel)">
                          Upload support files of your application
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <portal-application-bundles
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineBundlesPanel)"
                        [fixedTable]="fixedTable"
                        class="embedded-table"
                      >
                      </portal-application-bundles>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- Security Tab -->
          <mat-tab [disabled]="creatingNewApp">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                security
              </mat-icon>
              <span>Security</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card class="tab-card-container">
                <div class="tab-page-info">
                  <portal-page-info
                    [pageDescriptiveText]="securityDescriptiveText"
                    [productGuideLink]="securityProductGuideLink"
                  >
                  </portal-page-info>
                </div>
                <div class="content-container">
                  <mat-accordion class="expansion-container" multi>
                    <!-- Application Roles Panel -->
                    <mat-expansion-panel
                      #appDefineApplicationRolesPanel
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineApplicationRolesPanel" [initializeAsOpen]="true"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Application Roles
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineApplicationRolesPanel)">
                          Create groupings of security access
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <portal-application-roles
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineApplicationRolesPanel)"
                        [fixedTable]="fixedTable"
                        class="embedded-table"
                      >
                      </portal-application-roles>
                    </mat-expansion-panel>
            
                    <!-- Firewall Rules Panel -->
                    <mat-expansion-panel
                      #appDefineFirewallRulesPanel
                      [disabled]="isApplicationExternal(currentApplicationCopy)"
                      [matTooltip]="externalApplicationDisabledPanelTooltipText"
                      [matTooltipDisabled]="!isApplicationExternal(currentApplicationCopy)"
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineFirewallRulesPanel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Firewall Rules
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineFirewallRulesPanel)">
                          Define rules that allow/deny security access
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <!-- Original Http rules -->
                      <portal-application-rules
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineFirewallRulesPanel) && !appHasPolicyTemplateRules()"
                        [fixedTable]="fixedTable"
                        class="embedded-table"
                      >
                      </portal-application-rules>

                      <!-- Policy Http rules -->
                      <portal-application-policy-rules
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineFirewallRulesPanel) && appHasPolicyTemplateRules()"
                        [fixedTable]="fixedTable"
                        class="embedded-table"
                      >
                      </portal-application-policy-rules>

                      <!-- Original Geolocation rules -->
                      <portal-application-geolocation-rules
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineFirewallRulesPanel)"
                        [fixedTable]="fixedTable"
                        class="embedded-table"
                      >
                      </portal-application-geolocation-rules>

                    </mat-expansion-panel>

                    <!-- Web Application Security Panel -->
                    <mat-expansion-panel
                      #appDefineWebApplicationSecurityPanel
                      [disabled]="disableInstanceRequiredPanel()"
                      [matTooltip]="getDisabledAppConfigTooltipText()"
                      [matTooltipDisabled]="!isApplicationExternal(currentApplicationCopy) && !!currentEnvironment"
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineWebApplicationSecurityPanel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Web Application Security
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineWebApplicationSecurityPanel)">
                          Configure your web application's security settings
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <portal-web-application-security
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineWebApplicationSecurityPanel)"
                        [fixedData]="fixedTable"
                        [currentOrgIssuer]="currentOrgIssuer"
                        class="embedded-table"
                      >
                      </portal-web-application-security>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- Proxy Tab -->
          <mat-tab [disabled]="creatingNewApp">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                storage
              </mat-icon>
              <span>Proxy</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card class="tab-card-container">
                <div class="tab-page-info">
                  <portal-page-info
                    [pageDescriptiveText]="proxyDescriptiveText"
                    [productGuideLink]="proxyProductGuideLink"
                  >
                  </portal-page-info>
                </div>
                <div class="content-container">
                  <mat-accordion class="expansion-container" multi>
                    <!-- Http Rewrites Panel -->
                    <mat-expansion-panel
                      #appDefineHttpRewritesPanel
                      [disabled]="disableInstanceRequiredPanel()"
                      [matTooltip]="getDisabledAppConfigTooltipText()"
                      [matTooltipDisabled]="!isApplicationExternal(currentApplicationCopy) && !!currentEnvironment"
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineHttpRewritesPanel" [initializeAsOpen]="true"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          HTTP Rewrites
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineHttpRewritesPanel)">
                          Configure how requests and responses appear to the client and server
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <portal-http-rewrites
                        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineHttpRewritesPanel)"
                        [fixedData]="fixedTable"
                        [currentOrgIssuer]="currentOrgIssuer"
                        class="embedded-table"
                      >
                      </portal-http-rewrites>
                    </mat-expansion-panel>

                    <!-- Proxied Service Configuration Panel -->
                    <mat-expansion-panel
                      #appDefineProxiedServiceConfigPanel
                      [disabled]="disableInstanceRequiredPanel()"
                      [matTooltip]="getDisabledAppConfigTooltipText()"
                      [matTooltipDisabled]="!isApplicationExternal(currentApplicationCopy) && !!currentEnvironment"
                      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineProxiedServiceConfigPanel"
                    >
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          Proxied Service Configuration
                        </mat-panel-title>
                        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineProxiedServiceConfigPanel)">
                          Proxy to an external host
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <portal-page-info
                        [pageDescriptiveText]="proxiedServiceConfigDescriptiveText"
                        [productGuideLink]="proxiedServiceConfigProductGuideLink"
                        pageInfoWidth="full"
                        class="proxied-service-config-info"
                      >
                      </portal-page-info>
                      <div
                        *ngIf="!!upstreamConfigForm && isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineProxiedServiceConfigPanel)"
                        class="form-container"
                      >
                        <form
                          [formGroup]="upstreamConfigForm"
                          spellcheck="false"
                          autocomplete="off"
                        >
                          <mat-form-field class="mostly-extended-field">
                            <input
                              matInput
                              type="text"
                              placeholder=""
                              formControlName="hostname"
                              (blur)="onFormBlur(upstreamConfigForm, 'hostname')"
                              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                            />
                          </mat-form-field>
                          <mat-icon
                            class="help-icon"
                            matTooltip="This is how the system proxies to your actual application."
                          >
                            help_outline
                          </mat-icon>
                        </form>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-card>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
