import * as uuid from 'short-uuid';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { UntypedFormGroup, AbstractControl, UntypedFormArray } from '@angular/forms';
import { ChangeDetectorRef } from '@angular/core';
import { TableElement } from './table-layout/table-element';
import { getCurrentDate, addSecondsToDate } from './date-utils';
import {
  Application,
  Environment,
  ApplicationAssignment,
  RuleV2,
  RuntimeStatus,
  EnvironmentConfigVarList,
  EnvironmentConfigVar,
  MFAChallengeAnswerResult,
  ListUserMetadataResponse,
  UserMetadata,
  UserMetadataSpec,
  User,
  CSPSettings,
  Icon,
  Role,
} from '@agilicus/angular';
import { PropertyNamesOfType } from '../utilities/generics/type-scrubbers';
import { Column } from './table-layout/column-definitions';
import { startWith, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of, EMPTY } from 'rxjs';
import { NotificationService, AppState } from '@app/core';
import { HasAPIMetadata, ApiAction } from './utils-models';
import { select, Action, Store } from '@ngrx/store';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import * as pluralize from 'pluralize';
import { IconColor } from './icon-color.enum';
import { getDefaultCspModeValue } from '@app/core/models/application/application-model-api-utils';
import * as copy from 'copy-to-clipboard';
import { ResourceObject } from '@app/core/api/state-driven-crud/state-driven-crud';
import { PortalVersion } from './portal-version.enum';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { convert } from 'html-to-text';

/**
 * Case-insensitively sorts an array of objects alphabetically by a specific key
 */
export function sortArrayByKey<T>(data: Array<T>, key: PropertyNamesOfType<T, string | number>): Array<T> {
  return data.sort((a, b) => {
    const lhs = a[key];
    const rhs = b[key];
    if (typeof lhs === 'number' && typeof rhs === 'number') {
      return lhs - rhs;
    } else if (typeof lhs === 'string' && typeof rhs === 'string') {
      return lhs.localeCompare(rhs, undefined, { sensitivity: 'base' });
    }

    throw TypeError('Type must be string or number');
  });
}

/**
 * Case-insensitively sorts an array of strings alphabetically in ascending order
 */
export function sortStringArray(list: Array<string>): Array<string> {
  return list.sort((a, b) => a.localeCompare(b, undefined, { sensitivity: 'base' }));
}

/**
 * Returns an array which includes the values which can be found in the first
 * list, but do not exist in the second list.
 */
export function findUniqueItems(listA: Array<string>, listB: Array<string>): Array<string> {
  const setA: Set<string> = new Set(listA);
  const setB: Set<string> = new Set(listB);
  setB.forEach((b) => {
    if (setA.has(b)) {
      setA.delete(b);
    }
  });
  return sortStringArray(Array.from(setA));
}

/**
 * Returns an object that is present in the first list,
 * but not in the second list.
 * @param largerList The list containing the larger number of elements.
 * @param smallerList The list containing the smaller number of elements.
 * @param uniqueKey The key used to filter the elements into string values.
 */
export function getUniqueElement<T extends object>(largerList: Array<T>, smallerList: Array<T>, uniqueKey: string): T {
  const largerListOfKeyValues: Array<string> = largerList.map((elem) => elem[uniqueKey].toString());
  const smallerListOfKeyValues: Array<string> = smallerList.map((elem) => elem[uniqueKey].toString());
  const uniqueValuesArray: Array<string> = findUniqueItems(largerListOfKeyValues, smallerListOfKeyValues);
  if (uniqueValuesArray === undefined || uniqueValuesArray.length === 0) {
    return undefined;
  }
  const targetValue = uniqueValuesArray[0];
  for (const elem of largerList) {
    if (elem[uniqueKey] === targetValue) {
      return elem;
    }
  }
  return undefined;
}

export function isStringNumeric(str: string): boolean {
  const numberFormat = `^[0-9]+$`;
  if (typeof str !== 'string') {
    return false;
  }
  if (str.match(numberFormat) === null) {
    return false;
  }
  return true;
}

export function getEmptyStringIfUnset(value: any): any {
  if (value === undefined || value === null) {
    return '';
  }
  return value;
}

/**
 * Will return a value of '0' if the current value is empty,
 * null or undefined.
 */
export function giveValueIfUnset(value: string | number): string {
  if (!value) {
    return '0';
  }
  return value.toString();
}

export function getRouterLinkFromPath(): string {
  const fullPath = window.location.pathname;
  const paths = fullPath.split('/');
  const routerLink = '/' + paths[1];
  return routerLink;
}

export function generateRandomUuid(): string {
  return uuid.generate();
}

export function generateUniqueUuid(data: Array<object>, key: string): string {
  let newUuid = '';
  while (newUuid === '') {
    newUuid = generateRandomUuid();
    for (const item of data) {
      if (item[key] === newUuid) {
        newUuid = '';
        break;
      }
    }
  }
  return newUuid;
}

/**
 * Gets an array of values when multiple values are entered
 * into an input separated by semicolons.
 * @param event contains the values typed by the user
 */
export function getValuesFromInputEventValue(eventValue: string): Array<string> {
  if (!eventValue) {
    return [];
  }
  const valuesArray = eventValue
    .split(';')
    .map((item) => item.trim())
    .filter((item) => item !== '');
  return valuesArray;
}

/**
 * Gets the input values entered by the user that are duplicates.
 * @param valuesArray is the array of input values.
 */
export function getDuplicateInputValues(valuesArray: Array<string>): Array<string> {
  const sortedValuesArray = valuesArray.slice().sort();
  const duplicateInputValues = [];
  for (let i = 0; i < sortedValuesArray.length - 1; i++) {
    if (sortedValuesArray[i] === sortedValuesArray[i + 1]) {
      duplicateInputValues.push(sortedValuesArray[i]);
    }
  }
  // Convert to a set to remove duplicate entries.
  return Array.from(new Set(duplicateInputValues));
}

export function handleDuplicateInputValues(duplicateInputValues: Array<string>, notificationService: NotificationService): void {
  let message = duplicateInputValues.join(', ');
  if (duplicateInputValues.length === 1) {
    message += ' has been entered more than once';
  } else {
    message += ' have been entered more than once';
  }
  notificationService.error(message);
}

export function handleDuplicateChipValues(duplicateChipValues: Array<string>, notificationService: NotificationService): void {
  let message = duplicateChipValues.join(', ');
  if (duplicateChipValues.length === 1) {
    message += ' already exists';
  } else {
    message += ' already exist';
  }
  notificationService.error(message);
}

/**
 * Gets the input values entered by the user that are duplicates
 * of existing chip values.
 * @param valuesArray is the array of input values.
 * @param existingChipValues are the values of the chips that already exist.
 */
export function getDuplicateChipValues(valuesArray: Array<string>, existingChipValues: Set<string>): Array<string> {
  const duplicateValues: Array<string> = [];
  for (const value of valuesArray) {
    if (existingChipValues.has(value)) {
      duplicateValues.push(value);
    }
  }
  return duplicateValues;
}

/**
 * Gets the input values that do not exist.
 * @param valuesArray is the array of input values
 */
export function getNonexistantValuesFromMap(valuesArray: Array<string>, myMap: Map<string, string>): Array<string> {
  const nonexistantValues = [];
  for (const value of valuesArray) {
    if (myMap.get(value) === undefined) {
      nonexistantValues.push(value);
    }
  }
  return nonexistantValues;
}

export function getAppFromId(appId: string, applications: Array<Application>): Application {
  if (applications === undefined) {
    return undefined;
  }
  for (const app of applications) {
    if (app.id === appId) {
      return app;
    }
  }
  return undefined;
}

export function autocompleteSearchFilter(value: string, data: Array<string>): Array<string> {
  if (!data) {
    return [];
  }
  if (value === null || value === undefined) {
    return [];
  }
  const filterValue = value.toLowerCase();
  return data.filter((option) => option.toLowerCase().includes(filterValue));
}

export function setAutocomplete(formControl: AbstractControl, data: Array<string>): Observable<Array<string>> {
  return formControl.valueChanges.pipe(
    startWith(''),
    map((value) => autocompleteSearchFilter(value, data))
  );
}

/**
 * Dropdown selections, checkboxes and autocomplete inputs must be disabled/enabled here.
 * @param list is an array of formControl names
 * @param form is a form group
 * @param condition evaluates to a boolean
 */
export function setFormControlEnableState(list: Array<string>, form: UntypedFormGroup, condition: boolean): void {
  list.forEach((option) => {
    form.get(option)[condition ? 'enable' : 'disable']();
  });
}

export function getAppFromName(appName: string, applications: Array<Application>): Application {
  if (applications === undefined) {
    return undefined;
  }
  for (const app of applications) {
    if (app.name === appName) {
      return app;
    }
  }
  return undefined;
}

export function getRuleFromId(targetId: string, rules: Array<RuleV2>): RuleV2 {
  if (rules === undefined) {
    return undefined;
  }
  for (const rule of rules) {
    if (rule.metadata.id === targetId) {
      return rule;
    }
  }
  return undefined;
}

export function getEnvFromName(name: string, application: Application): Environment {
  if (application === undefined) {
    return undefined;
  }
  for (const env of application.environments) {
    if (env.name === name) {
      return env;
    }
  }
  return undefined;
}

export function removeEmptyValuesFromObject(data: object): void {
  for (const key of Object.keys(data)) {
    if (data[key] === '' || data[key] === null || data[key] === undefined) {
      delete data[key];
    }
  }
}

/**
 * Will replace an object in an array with its updated version.
 * @param objectArray is a list of objects.
 * @param updatedObject is the updated version of the object
 * being modified.
 * @param currentObject is the existing version of the updated
 * object.
 * @param uniqueKey is the key used to locate the object that has
 * been modified.
 */
export function replaceObjectInArray<T extends object>(objectArray: Array<T>, updatedObject: T, currentObject: T, uniqueKey: string): void {
  if (objectArray !== undefined) {
    for (let i = 0; i < objectArray.length; i++) {
      if (objectArray[i][uniqueKey] === currentObject[uniqueKey]) {
        objectArray.splice(i, 1);
        break;
      }
    }
  } else {
    objectArray = [];
  }
  objectArray.unshift(updatedObject);
}

/**
 * Converts a Map to a JSON.
 */
export function toJson(myMap: Map<string, string>): Array<any> {
  if (myMap === undefined) {
    return undefined;
  }
  return Array.from(myMap.entries());
}

/**
 * Converts a JSON to a Map<string, string>.
 */
export function fromJson(json: Array<any>): Map<string, string> {
  if (json === undefined) {
    return undefined;
  }
  return new Map(json);
}

export function setFormFieldValuesFromObject(
  obj: object,
  form: UntypedFormGroup,
  formFieldNamesList: Array<string>,
  changeDetector: ChangeDetectorRef
): void {
  formFieldNamesList.forEach((field) => {
    form.get(field).setValue(getEmptyStringIfUnset(obj[field]));
  });
  changeDetector.detectChanges();
}

/**
 * Adds a value to all fields that are currently set to empty,
 * null or undefined. This is required to be called when the
 * form state is changed between enabled and disabled so that
 * fields that are empty, but also required will be marked with
 * an * when enabled and have the * removed when disabled.
 * Requires setFormFieldValuesFromObject method to be called after
 * in order to reset the form fields to their correct values.
 */
export function populateFormFieldsWithValue(
  obj: object,
  form: UntypedFormGroup,
  formFieldNamesList: Array<string>,
  changeDetector: ChangeDetectorRef
): void {
  formFieldNamesList.forEach((field) => {
    form.get(field).setValue(giveValueIfUnset(obj[field]));
  });
  changeDetector.detectChanges();
}

/**
 * Will add and then remove a value from empty form fields
 * so that required fields will be marked with an * when
 * enabled and have the * removed when disabled.
 */
export function reloadFormFieldValues(
  obj: object,
  form: UntypedFormGroup,
  formFieldNamesList: Array<string>,
  changeDetector: ChangeDetectorRef
): void {
  populateFormFieldsWithValue(obj, form, formFieldNamesList, changeDetector);
  setFormFieldValuesFromObject(obj, form, formFieldNamesList, changeDetector);
}

export function isRequiredFormControl(form: UntypedFormGroup, control: string): boolean {
  const validatorResult = form.get(control).validator ? form.get(control).validator({} as AbstractControl) : '';
  if (validatorResult && validatorResult.required) {
    return true;
  }
  return false;
}

export function getRequiredFormFields(form: UntypedFormGroup): Array<string> {
  return Object.keys(form.controls).filter((control) => isRequiredFormControl(form, control));
}

export function resetIndices(data: Array<any>): void {
  for (let i = 0; i < data.length; i++) {
    data[i].index = i;
  }
}

/**
 * Compares two arrays and returns the length of the longest list.
 */
export function getLongerListLength(listA: Array<any>, listB: Array<any>): number {
  if (listA.length > listB.length) {
    return listA.length;
  }
  return listB.length;
}

export function updateTableElements<T extends TableElement>(tableData: Array<T>, updatedElements: Array<T>, searchKey?: string): void {
  if (searchKey) {
    // We need to find the first element in the current table and
    // move it to the front of the updated list so that new elements
    // remains at the top when the table is updated.
    const searchValue = getFirstElementValue(tableData, searchKey);
    findAndMoveElementToFrontOfArray(updatedElements, searchKey, searchValue);
    resetIndices(updatedElements);
  }
  let i: number;
  // Update existing elements.
  for (i = 0; i < tableData.length && i < updatedElements.length; i++) {
    for (const key of Object.keys(updatedElements[i])) {
      tableData[i][key] = updatedElements[i][key];
    }
  }
  // Copy over any extra elements.
  for (; i < updatedElements.length; i++) {
    tableData.push(updatedElements[i]);
  }
  // Delete any elements which didn't exist.
  if (i < tableData.length) {
    tableData.splice(i);
  }
}

function getFirstElementValue(arr: Array<object>, searchKey: string): any {
  if (arr.length === 0 || arr[0][searchKey] === undefined) {
    return undefined;
  }
  return arr[0][searchKey];
}

export function findAndMoveElementToFrontOfArray(arr: Array<object>, searchKey: string, searchValue: string): void {
  if (searchValue === undefined) {
    return;
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][searchKey] === searchValue) {
      moveElementToFrontOfArray(arr, i);
    }
  }
}

export function moveElementToFrontOfArray(arr: Array<object>, currentIndex: number): void {
  const elementToMove = arr.splice(currentIndex, 1)[0];
  arr.unshift(elementToMove);
}

/**
 * Adds the focus to the newly added input in the table.
 */
export function addNewEntryFocus(rowObjectName: string): void {
  const classString = '.' + getFocusClass(rowObjectName);
  setTimeout(() => {
    addFocusToInput(classString);
  }, 200);
}

/**
 * Adds the focus to the first input in the table or first empty input.
 */
export function addFocusToInput(classString: string): void {
  let inputField: HTMLElement;
  const nodeList: NodeListOf<Element> = document.querySelectorAll(classString);
  const inputFields: Array<HTMLElement> = [];
  nodeList.forEach((node) => {
    inputFields.push(node as HTMLElement);
  });
  if (inputFields.length === 0) {
    return;
  }
  inputField = inputFields[0];
  if (!inputField) {
    return;
  }
  inputField.focus();
}

export function getFocusClass(rowObjectName: string): string {
  const formattedClass = rowObjectName.split(' ').join('-').toLowerCase();
  return formattedClass === '' ? 'focus-input' : formattedClass + '-focus-input';
}

/**
 * Removes the substring from the beginning of the provided string.
 */
export function removeFromBeginningOfString(str: string, substring: string): string {
  if (str.startsWith(substring)) {
    return str.slice(substring.length);
  }
  return str;
}

/**
 * Will exclude optional fields from the http request if their values are undefined or an empty string.
 */
export function fillOptionalDataFromForm(data: object, form: UntypedFormGroup, fields: Array<string>): void {
  for (const field of fields) {
    if (form.value[field] !== undefined && form.value[field] !== '') {
      data[field] = form.value[field];
    }
  }
}

export function clearForm(form: UntypedFormGroup): void {
  Object.keys(form.controls).forEach((key) => {
    form.controls[key].setValue('');
  });
}

export function unshiftElementToListIfProperty<T extends object>(
  elementToAdd: T,
  currentList: Array<T>,
  requiredProperty?: string
): Array<T> {
  if (!requiredProperty) {
    requiredProperty = 'id';
  }
  if (elementToAdd[requiredProperty] === undefined) {
    return currentList;
  }
  return [elementToAdd, ...currentList];
}

export function updateElementInListIfProperty<T extends object>(
  elementToUpdate: T,
  currentList: Array<T>,
  requiredProperty?: string
): Array<T> {
  if (!requiredProperty) {
    requiredProperty = 'id';
  }
  if (elementToUpdate === undefined || elementToUpdate[requiredProperty] === undefined) {
    return currentList;
  }
  const currentListCopy: Array<T> = [...currentList];
  for (let i = 0; i < currentListCopy.length; i++) {
    if (currentListCopy[i][requiredProperty] === elementToUpdate[requiredProperty]) {
      currentListCopy[i] = elementToUpdate;
      break;
    }
  }
  return currentListCopy;
}

export function removeElementFromListIfProperty<T extends object>(
  elementToDelete: T,
  currentList: Array<T>,
  requiredProperty?: string
): Array<T> {
  if (!requiredProperty) {
    requiredProperty = 'id';
  }
  if (elementToDelete === undefined || elementToDelete[requiredProperty] === undefined) {
    return currentList;
  }
  return currentList.filter((entry) => entry[requiredProperty] !== elementToDelete[requiredProperty]);
}

export function unshiftElementToListIfId<T extends HasAPIMetadata>(elementToAdd: T, currentList: Array<T>): Array<T> {
  if (!elementToAdd.metadata || !elementToAdd.metadata.id) {
    return currentList;
  }
  return [elementToAdd, ...currentList];
}

export function updateElementInListIfId<T extends HasAPIMetadata>(elementToUpdate: T, currentList: Array<T>): Array<T> {
  if (!elementToUpdate || !elementToUpdate.metadata || !elementToUpdate.metadata.id) {
    return currentList;
  }
  const currentListCopy: Array<T> = [...currentList];
  for (let i = 0; i < currentListCopy.length; i++) {
    if (currentListCopy[i].metadata.id === elementToUpdate.metadata.id) {
      currentListCopy[i] = elementToUpdate;
      break;
    }
  }
  return currentListCopy;
}

export function removeElementFromListIfId<T extends HasAPIMetadata>(elementToDelete: T, currentList: Array<T>): Array<T> {
  if (!elementToDelete || !elementToDelete.metadata || !elementToDelete.metadata.id) {
    return currentList;
  }
  return currentList.filter((entry) => entry.metadata.id !== elementToDelete.metadata.id);
}

export function createFirstKeyToSecondKeyMap(data: Array<object>, firstKey: string, secondKey: string): Map<string, string> {
  const stringToStringMap: Map<string, string> = new Map();
  if (data === undefined || data.length === 0) {
    return stringToStringMap;
  }
  data.forEach((element) => {
    stringToStringMap.set(element[firstKey], element[secondKey]);
  });
  return stringToStringMap;
}

export function createJsonFromObject(data: Array<object>, firstKey: string, secondKey: string): Array<any> {
  // Need to convert to a JSON string in order to store in state.
  const myMap: Map<string, string> = createFirstKeyToSecondKeyMap(data, firstKey, secondKey);
  return toJson(myMap);
}

export function getEnumValuesAsStringArray<T extends string, TEnumValue extends string>(enumVariable: {
  [key in T]: TEnumValue;
}): Array<string> {
  return Object.values(enumVariable);
}

export function createEnumChecker<T extends string, TEnumValue extends string>(enumVariable: { [key in T]: TEnumValue }): (
  value: string
) => value is TEnumValue {
  const enumValues = getEnumValuesAsStringArray(enumVariable);
  return (value: string): value is TEnumValue => enumValues.includes(value);
}

export function useValueIfNotInMapRaw<KEY, VALUE>(value: KEY, myMap: Map<KEY, VALUE>, accessor: (item: VALUE) => KEY): KEY {
  const targetValue = myMap.get(value);
  if (targetValue) {
    return accessor(targetValue);
  }
  return value;
}

/**
 * Will check if the value exists in the map. If so, returns the matching value.
 * If not, returns the original value.
 */
export function useValueIfNotInMap<KEY>(value: KEY, myMap: Map<KEY, KEY>): KEY {
  return useValueIfNotInMapRaw(value, myMap, (x) => x);
}

/**
 * Checks if a newly created assignment is for an organisation that has already
 * been assigned to an instance for the current application.
 */
export function isOrgAlreadyAssigned(assignmentsList: Array<ApplicationAssignment>, newAssignment: ApplicationAssignment): boolean {
  if (!newAssignment) {
    return false;
  }
  for (const assignment of assignmentsList) {
    if (newAssignment.org_id === assignment.org_id) {
      return true;
    }
  }
  return false;
}

export function isOneFormChipRequired(formControl: AbstractControl): boolean {
  if (formControl.validator) {
    const formControlValidator = formControl.validator({} as AbstractControl);
    if (formControlValidator && formControlValidator.required) {
      return true;
    }
  }
  return false;
}

export function hasOnlyOneRequiredFormChip(data: any, formControl: AbstractControl): boolean {
  if (!data) {
    return false;
  }
  if (isOneFormChipRequired(formControl) && data.length === 1) {
    return true;
  }
  return false;
}

export function isFormChipRemovable(data: any, formControl: AbstractControl, areChipsRemovable: boolean): boolean {
  return areChipsRemovable && !hasOnlyOneRequiredFormChip(data, formControl);
}

export function getChipValuesOnInput(
  event: MatChipInputEvent,
  valuesMap: Map<string, string>,
  notificationService: NotificationService
): Array<string> | undefined {
  const valuesArray = getValuesFromInputEventValue(event.value);
  if (valuesArray.length === 0) {
    return undefined;
  }
  const errorValues = getNonexistantValuesFromMap(valuesArray, valuesMap);
  if (errorValues.length > 0) {
    let message = errorValues.join(', ');
    if (errorValues.length === 1) {
      message += ' does not exist';
    } else {
      message += ' do not exist';
    }
    notificationService.error(message);
    return undefined;
  }
  return valuesArray;
}

export function getValuesArray(
  event: MatChipInputEvent,
  currentList: Array<string>,
  notificationService: NotificationService
): Array<string> | undefined {
  const valuesArray = getValuesFromInputEventValue(event.value);
  if (valuesArray.length === 0) {
    return undefined;
  }
  const duplicateInputValues = getDuplicateInputValues(valuesArray);
  if (duplicateInputValues.length > 0) {
    handleDuplicateInputValues(duplicateInputValues, notificationService);
    return undefined;
  }
  const duplicateChipValues = getDuplicateChipValues(valuesArray, new Set(currentList));
  if (duplicateChipValues.length > 0) {
    handleDuplicateChipValues(duplicateChipValues, notificationService);
    return undefined;
  }
  return valuesArray;
}

export function saveAction<API_TYPE extends HasAPIMetadata, STATE_TYPE>(
  store: Store<AppState>,
  actions$: Actions,
  actionType: any,
  stateSelector: any,
  postFunction: (action: ApiAction<API_TYPE>, state: STATE_TYPE) => Observable<Action>,
  putFunction: (obj: API_TYPE, state: STATE_TYPE, orgId: string) => Observable<Action>
): Observable<Action> {
  return createEffect(() =>
    actions$.pipe(
      ofType(actionType),
      concatMap((action: ApiAction<API_TYPE>) => {
        return of(action).pipe(withLatestFrom(store.pipe(select(stateSelector)), store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, state, orgId]: [ApiAction<API_TYPE>, STATE_TYPE, string]) => {
        if (!action.api_obj.metadata) {
          return postFunction(action, state);
        }
        return putFunction(action.api_obj, state, orgId);
      })
    )
  );
}

export function deleteAction<API_TYPE extends HasAPIMetadata, STATE_TYPE>(
  store: Store<AppState>,
  actions$: Actions,
  actionType: any,
  stateSelector: any,
  deleteFunction: (obj: API_TYPE, state: STATE_TYPE, orgId: string) => Observable<Action>
): Observable<Action> {
  return createEffect(() =>
    actions$.pipe(
      ofType(actionType),
      concatMap((action: ApiAction<API_TYPE>) => {
        return of(action).pipe(withLatestFrom(store.pipe(select(stateSelector)), store.pipe(select(selectApiOrgId))));
      }),
      concatMap(([action, state, orgId]: [ApiAction<API_TYPE>, STATE_TYPE, string]) => {
        if (!action.api_obj.metadata) {
          // Deleting a new, unsaved api_obj.
          return EMPTY;
        }
        return deleteFunction(action.api_obj, state, orgId);
      })
    )
  );
}

export function getApplicationStatus(app: Application): RuntimeStatus.OverallStatusEnum | undefined {
  if (!app.environments || app.environments.length === 0) {
    return undefined;
  }

  const statusToSeverityMap: Map<RuntimeStatus.OverallStatusEnum, number> = new Map();
  statusToSeverityMap.set(RuntimeStatus.OverallStatusEnum.good, 1);
  statusToSeverityMap.set(RuntimeStatus.OverallStatusEnum.stale, 2);
  statusToSeverityMap.set(RuntimeStatus.OverallStatusEnum.warn, 3);
  statusToSeverityMap.set(RuntimeStatus.OverallStatusEnum.down, 4);

  let appStatus = RuntimeStatus.OverallStatusEnum.good;
  for (const env of app.environments) {
    if (!env.status || !env.status.runtime_status || !env.status.runtime_status.overall_status) {
      // If we are missing a status for an env, then we will display no status for the app.
      return undefined;
    }
    if (statusToSeverityMap.get(env.status.runtime_status.overall_status) > statusToSeverityMap.get(appStatus)) {
      appStatus = env.status.runtime_status.overall_status;
    }
  }
  return appStatus;
}

export function getStatusIcon(status: RuntimeStatus.OverallStatusEnum | string): string {
  switch (status) {
    case RuntimeStatus.OverallStatusEnum.good:
      return 'check_circle';
    case RuntimeStatus.OverallStatusEnum.stale:
      return 'access_time';
    case RuntimeStatus.OverallStatusEnum.warn:
      return 'warning';
    case RuntimeStatus.OverallStatusEnum.down:
      return 'error';
    case RuntimeStatus.OverallStatusEnum.degraded:
      return 'trending_down';
    default:
      return '';
  }
}

export function getStatusIconColor(status: RuntimeStatus.OverallStatusEnum | string): IconColor {
  switch (status) {
    case RuntimeStatus.OverallStatusEnum.good:
      return IconColor.success;
    case RuntimeStatus.OverallStatusEnum.stale:
      return IconColor.intermediate;
    case RuntimeStatus.OverallStatusEnum.warn:
      return IconColor.intermediate;
    case RuntimeStatus.OverallStatusEnum.degraded:
      return IconColor.intermediate;
    case RuntimeStatus.OverallStatusEnum.down:
      return IconColor.warn;
    default:
      return IconColor.none;
  }
}

export function capitalizeFirstLetter(str: string): string {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getCspEnvConfigVarName(): string {
  return 'HDR_CONTENT_SECURITY_POLICY';
}

export function getDefaultReportURI(): string {
  return '/.well-known/csp-violation-report-endpoint/';
}

export function getDefaultCspValue(): string {
  return '';
}

export function getDefaultCspSettings(): CSPSettings {
  return {
    enabled: false,
    mode: getDefaultCspModeValue(),
    directives: [],
  };
}

export function getDefaultCspEnvConfigVar(): EnvironmentConfigVar {
  return {
    name: getCspEnvConfigVarName(),
    value: getDefaultCspValue(),
  };
}

export function createCspEnvConfigVar(cspValue: string): EnvironmentConfigVar {
  return {
    name: getCspEnvConfigVarName(),
    value: cspValue,
  };
}

export function getCspFromEnvConfigVarsList(envConfigVarList: EnvironmentConfigVarList): string | undefined {
  const targetEnvConfigVar = envConfigVarList.configs.find((envConfigVar) => envConfigVar.name === getCspEnvConfigVarName());
  if (targetEnvConfigVar) {
    return targetEnvConfigVar.value;
  }
  return undefined;
}

export function getCspFromEnvironmentsList(envsList: Array<Environment>): CSPSettings | undefined {
  const targetEnvironment = envsList.find((env) => !!env.application_configs?.security?.http?.csp);
  if (targetEnvironment) {
    return targetEnvironment.application_configs.security.http.csp;
  }
  return undefined;
}

/**
 * Returns an array of the maps keys.
 */
export function getKeysFromMap<KEY, VALUE>(myMap: Map<KEY, VALUE>): Array<KEY> {
  return Array.from(myMap.keys());
}

/**
 * Creates the previous version of a Role without any rules.
 */
export function createBasicRoleWithName(name: string): Role {
  return { name, rules: [] };
}

export function getDefaultRoleNameFromApp(app: Application): string {
  if (app && app.default_role_name) {
    return app.default_role_name;
  }
  return '';
}

export function pluralizeString(str: string): string {
  return pluralize(str);
}

export function getFirstElementForDefault<T extends object>(elementList: Array<T>): T | undefined {
  if (!elementList || elementList.length === 0) {
    return undefined;
  }
  return elementList[0];
}

export function getMFAChallengeAnswerResultOptions(): Array<MFAChallengeAnswerResult.ActionEnum> {
  const mFAChallengeAnswerResultOptions = Object.values(MFAChallengeAnswerResult.ActionEnum);
  // We need to hide 'dont_mfa' from the list of options.
  return mFAChallengeAnswerResultOptions.filter((option) => option !== MFAChallengeAnswerResult.ActionEnum.dont_mfa);
}

export function getDefaultMfaActionDisplayValue(action: MFAChallengeAnswerResult.ActionEnum): string {
  switch (action) {
    case MFAChallengeAnswerResult.ActionEnum.do_mfa:
      return 'Require Second Factor';
    case MFAChallengeAnswerResult.ActionEnum.dont_mfa:
      return 'Do Not Require Second Factor';
    case MFAChallengeAnswerResult.ActionEnum.allow_login:
      return 'Allow Login';
    case MFAChallengeAnswerResult.ActionEnum.deny_login:
      return 'Deny Login';
    case MFAChallengeAnswerResult.ActionEnum.authenticate:
      return 'Authenticate with an identity provider';
    default:
      return '';
  }
}

export function replaceCharacterWithSpace(value: string, character: string): string {
  if (character === '.') {
    character = '\\.';
  }
  const regexExp = new RegExp(character, 'g');
  return value.replace(regexExp, ' ');
}

export function replaceUnderscoreWithHyphen(str: string): string {
  let newStringArray = [];
  const stringAsArray = str.split('');
  for (const char of stringAsArray) {
    if (char === '_') {
      newStringArray.push('-');
    } else {
      newStringArray.push(char);
    }
  }
  return newStringArray.join('');
}

export function setDropdownEnableStateForTableAutoInputs<T extends TableElement>(
  columnName: string,
  formControlColumn: Column<T>,
  data: Array<T>
): void {
  for (const elem of data) {
    if (!formControlColumn.isEditable || formControlColumn.disableField(elem)) {
      elem[columnName].disable();
    } else {
      elem[columnName].enable();
    }
  }
}

export function getMfaEnrollmentExpiryFromUserMetadata(userMetadataResp: ListUserMetadataResponse): UserMetadata | undefined {
  if (!userMetadataResp) {
    return undefined;
  }
  return userMetadataResp.user_metadata.find(
    (userMetadata) => userMetadata.spec.data_type === UserMetadataSpec.DataTypeEnum.mfa_enrollment_expiry
  );
}

export function isApplicationExternal(app: Application | undefined): boolean {
  if (!app) {
    return false;
  }
  return app.location === Application.LocationEnum.external;
}

export function modifyDataOnFormBlur<T extends object>(
  form: UntypedFormGroup,
  formField: string,
  modifyData: (form: UntypedFormGroup, formField: string, obj?: T) => void,
  obj?: T
): void {
  if (form.controls[formField].invalid) {
    return;
  }
  // Do not proceed if the value has not been changed.
  if (!form.controls[formField].dirty) {
    return;
  }
  modifyData(form, formField, obj);
}

export function getUniqueNamesList(names: Array<string>): Array<string> {
  const set = new Set<string>();
  for (const name of names) {
    set.add(name);
  }
  return Array.from(set);
}

export function getMfaEnrollmentExpiryDateString(orgDuration: number): string {
  const date = getCurrentDate();
  const updatedDate = addSecondsToDate(orgDuration, date);
  return updatedDate.toISOString();
}

export function getDefaultLocalAuthUpstreamIssuer(): string {
  return 'http://agent-server.agent-server';
}

export function getUserTypeIcon(userType: User.TypeEnum): string {
  switch (userType) {
    case User.TypeEnum.user:
      return 'person';
    case User.TypeEnum.group:
    case User.TypeEnum.sysgroup:
    case User.TypeEnum.bigroup:
      return 'group';
    case User.TypeEnum.service_account:
      return 'miscellaneous_services';
    default:
      return '';
  }
}

export function getUserTypeTooltip(userType: User.TypeEnum): string {
  switch (userType) {
    case User.TypeEnum.user:
      return 'User';
    case User.TypeEnum.group:
      return 'Group';
    case User.TypeEnum.sysgroup:
      return 'System Group';
    case User.TypeEnum.bigroup:
      return 'Built-in Group';
    case User.TypeEnum.service_account:
      return 'Service Account';
    default:
      return '';
  }
}

export function getExistingChipValuesSet(list: Array<string>): Set<string> {
  const existingChipValues: Set<string> = new Set();
  for (const item of list) {
    existingChipValues.add(item);
  }
  return existingChipValues;
}

/**
 * Scrolls to the top of the page.
 */
export function scrollToTop(): void {
  document.querySelector('mat-sidenav-content').scrollTop = 0;
}

export function getRewriteCommonMediaTypesTooltip(): string {
  return `Some legacy web applications use hard-coded hosts names, e.g. HTTP://myname, rather than the Host header. 
  If this option is enabled, a common set of media types including HTML and JSON will be rewritten for compatibility.`;
}

export function removeStringFromList(val: string, list: Array<string>): void {
  const index = list.indexOf(val);
  if (index > -1) {
    list.splice(index, 1);
  }
}

/**
 * Will return undefined if the string is empty or not set. Otherwise, will return the string.
 */
export function convertEmptyStringToUndefined(str: string): string | undefined {
  if (!str) {
    return undefined;
  }
  return str;
}

export function copyTextToClipboard(str: string): void {
  copy(str);
}

export function getHTTPSPrefix(): string {
  return 'https://';
}

export function addHTTPSToUrlIfNotSet(url: string): string {
  if (url.indexOf(getHTTPSPrefix()) === 0) {
    return url;
  }
  return `${getHTTPSPrefix()}${url}`;
}

/**
 * Will confirm whether a list of fields in a formGroup are valid.
 * If no field names are provided it will return whether the form itself is valid.
 */
export function areFormFieldsValid(formGroup: UntypedFormGroup, fieldNames?: Array<string>): boolean {
  if (!fieldNames || fieldNames.length === 0) {
    return formGroup.valid;
  }
  for (const fieldName of fieldNames) {
    const fieldControl = formGroup.get(fieldName);
    if (!fieldControl || !fieldControl.valid) {
      return false;
    }
  }
  return true;
}

export function getDefaultIconPurpose(): string {
  return 'agilicus-profile';
}

export function getIconFromResource(resource: ResourceObject<string>): Icon {
  return resource.spec.resource_config?.display_info?.icons
    ? resource.spec.resource_config.display_info.icons.find((icon) => icon.purposes.some((purpose) => purpose === getDefaultIconPurpose()))
    : undefined;
}

export function getIconURIFromResource(resource: ResourceObject<string>): string {
  const logo = getIconFromResource(resource);
  return logo?.uri || '';
}

export function getIconURIWithoutQueryParams(uri: string): string {
  const targetIndex = uri.indexOf('?');
  if (!targetIndex) {
    return uri;
  }
  return uri.substring(0, targetIndex);
}

export function addQueryParamToURI(uri: string, newQueryParam: string): string {
  const targetIndex = uri.indexOf('?');
  if (!targetIndex) {
    return `${uri}?${newQueryParam}`;
  }
  return `${uri}&${newQueryParam}`;
}

/**
 * If the string is surrounded by quotation marks, for example, "this is a test",
 * the surrounding quotations will be removed. Otherwise, the original string is returned unchanged.
 */
export function removeSurroundingQuotesFromString(str: string | undefined | null): string {
  if (str === undefined) {
    return undefined;
  }
  if (str === null) {
    return null;
  }
  const lastCharIndex = str.length - 1;
  const firstChar = str.charAt(0);
  const lastChar = str.charAt(lastCharIndex);
  if (firstChar === '"' && lastChar === '"') {
    return str.substring(1, lastCharIndex);
  }
  return str;
}

export function groupByKey<T>(array: Array<T>, key: string): Map<string, Array<T>> {
  const endResult = array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
  return new Map(Object.entries(endResult));
}

export function getUrlSearchParams(): URLSearchParams {
  const queryString = window.location.search;
  return new URLSearchParams(queryString);
}

export function clearFormArray(formArray: UntypedFormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
}

export function setToUndefinedIfEmptyString(value: string | undefined): undefined | string {
  return value === '' ? undefined : value;
}

export function areAllValuesPresentInArray(valuesArray: Array<string>, mainArray: Array<string>): boolean {
  return valuesArray.every((val) => mainArray.includes(val));
}

export function getUserNameFromUser(user: User): string {
  let userName = '';
  if (!!user.first_name) {
    userName += `${user.first_name} `;
  }
  if (!!user.last_name) {
    userName += `${user.last_name}`;
  }
  return userName.trim();
}

export function getPortalVersionFromCookie(cookie: string): PortalVersion {
  if (!!cookie) {
    if (cookie === 'master' || cookie === 'latest') {
      return PortalVersion.beta;
    }
    if (cookie === 'alpha') {
      return PortalVersion.alpha;
    }
  }
  return PortalVersion.stable;
}

/**
 * This allows skipping to the "Apply" step rather than having to
 * click through each already completed step when editing a stepper.
 * Fyi: need to set (animationDone) on stepper in html to call this function.
 */
export function allowSkippingCompletedStepsOnStepperInit(stepper: MatStepper): void {
  stepper.steps.forEach((matStep: MatStep) => {
    matStep.interacted = true;
  });
}

export function isValueAnObject(value: any): boolean {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
}

export function getConvertedTextFromHtml(htmlString: string): string {
  return convert(htmlString);
}

export function arrayIncludesSubstringOfValue(arr: Array<string>, value: string | undefined): boolean {
  if (!value) {
    return false;
  }
  const matches = arr.filter((item) => value.includes(item));
  return matches.length !== 0;
}
