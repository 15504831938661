import { BillingPortalLink, CreateBillingPortalLinkRequestParams, OrganisationsService } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { addQueryParamToURI } from '@app/shared/components/utils';
import { Observable, Subject, takeUntil } from 'rxjs';
import { getRefreshBillingQueryParam } from '../billing-state/billing-api-utils';
import { EventsService } from '../services/events.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private organisationsService: OrganisationsService, private eventsService: EventsService) {}

  public getBillingPortalLink$(orgId: string, refreshBilling = false): Observable<BillingPortalLink> {
    let returnURI = window.location.href;
    if (!!refreshBilling) {
      returnURI = addQueryParamToURI(returnURI, getRefreshBillingQueryParam());
    }
    const createBillingPortalLinkRequestParams: CreateBillingPortalLinkRequestParams = {
      org_id: orgId,
      BillingPortalLink: {
        return_uri: returnURI,
      },
    };
    return this.organisationsService.createBillingPortalLink(createBillingPortalLinkRequestParams);
  }

  public handleSetupPaymentClick(orgId: string, unsubscribe$: Subject<void>, refreshBilling = false): void {
    this.eventsService.SendEvent({
      event_id: 'payment-pre-setup',
      event_type: 'admin-payment',
      category: 'upsell',
      sub_category: 'payment',
    });

    this.getBillingPortalLink$(orgId, refreshBilling)
      .pipe(takeUntil(unsubscribe$))
      .subscribe((billingPortalLinkResp) => {
        window.open(billingPortalLinkResp.portal_uri, '_self');
      });
  }
}
