export enum PermissionsCheckboxOptions {
  DISABLED = 'Show disabled users',
  PENDING = 'Show pending users',
  USERS = 'user',
  GROUPS = 'group',
  BIGROUPS = 'bigroup',
  SERVICE_ACCOUNT = 'service account',
  RESOURCE_GROUP = 'Hide resources in a group',
  HIDE_DELETED_RESOURCES = 'Hide permissions for deleted resources',
  VARY_COLOR_BY_RESOURCE_TYPE = 'Vary color by resource type',
  APPLICATION = 'application',
  APPLICATION_SERVICE = 'application_service',
  DESKTOP = 'desktop',
  FILESHARE = 'fileshare',
  GROUP = 'group',
  LAUNCHER = 'launcher',
  SERVICE_FORWARDER = 'service_forwarder',
  SSH = 'ssh',
}
