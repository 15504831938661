import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { ColumnTypes } from '../column-types.enum';
import { Column } from '../table-layout/column-definitions';
import { FilterType } from '../filter-type.enum';
import { findFilterOptionByName, removeFromList } from '../filter-utils';
import { TableElement } from '../table-layout/table-element';

export class FilterOption {
  public name: string;
  public displayName: string;
  public label: string;
  public type: FilterType;
}

export class CheckboxOption extends FilterOption {
  public isChecked: boolean;
  public doFilter: (checkboxOption: CheckboxOption) => void;
}

export class InputOption extends FilterOption {
  public doFilter: <T extends object>(dataSource: MatTableDataSource<T>) => void;
}

export class FilterManager {
  public filterBar: Array<FilterOption> = [];
  public checkboxOptions: Array<CheckboxOption> = [];
  public inputOptions: Array<InputOption> = [];
  public newInputOptionAdded = false;

  constructor() {}

  public getActiveFilterOptions(): Array<FilterOption> {
    return this.filterBar;
  }

  public getCheckboxOptions(label: string): CheckboxOption[] {
    const filteredArray = this.checkboxOptions.filter((option) => option.label === label);
    return filteredArray.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
  }

  /**
   * Filters the material table dataSource by the values entered by the user.
   * The data is filter by the list of inputOptions. However, the value of dataSource.filter must
   * change in order to trigger the filterPredicate. Therefore, we trigger the filterPredicate by
   * toggling the string value each time it is invoked.
   */
  public applyFilter<T extends object>(dataSource: MatTableDataSource<T> | undefined): void {
    if (!dataSource) {
      return;
    }
    if (dataSource.filter === 'state1') {
      dataSource.filter = 'state2';
    } else {
      dataSource.filter = 'state1';
    }
  }

  public createFilterPredicate<T extends object>(dataSource: MatTableDataSource<T>, displayedColumns: Array<string>): void {
    dataSource.filterPredicate = (data): boolean => {
      if (this.inputOptions.length === 0) {
        return true;
      }
      let matchCount = 0;
      for (const option of this.inputOptions) {
        for (const column of displayedColumns) {
          if (data[column] !== null && data[column] !== undefined) {
            if (data[column].toString().toLowerCase().includes(option.name.toLowerCase())) {
              matchCount++;
              break;
            }
          }
        }
      }
      if (matchCount === this.inputOptions.length) {
        return true;
      }
      return false;
    };
  }

  /**
   * Gets the list of values to check a filter value against.
   */
  // TODO: replace object with TableElement or InputData
  private getValuesToFilter<T extends object>(data: T, columnDefs: Map<string, Column<T>>): Array<string> {
    const filteredColumns = Array.from(columnDefs.values())
      .filter((element) => element.filterOn)
      .filter((element) => element.showColumn);
    const values = [];
    for (const column of filteredColumns) {
      if (data[column.name] === null || data[column.name] === undefined) {
        continue;
      }
      if (column.type === ColumnTypes.CHIPLIST) {
        for (const entry of data[column.name]) {
          // A chiplist can contain a list of objects or a list of strings
          const value = column.getDisplayValue(entry);
          values.push(value);
        }
      } else {
        // The value might be a string or an object
        const value = column.getDisplayValue(data);
        values.push(value);
      }
    }
    return values;
  }

  /**
   * Allows filtering on columns whose values are an array of objects.
   * In order for filtering to work on these columns we need to
   * specify which property of that object we want to filter on.
   */
  public createNestedFilterPredicate<T extends TableElement>(dataSource: MatTableDataSource<T>, columnDefs: Map<string, Column<T>>): void {
    dataSource.filterPredicate = (data): boolean => {
      if (this.inputOptions.length === 0) {
        return true;
      }
      if (data.isNew) {
        return true;
      }
      const values = this.getValuesToFilter(data, columnDefs);
      let matchCount = 0;
      for (const option of this.inputOptions) {
        for (const value of values) {
          if (!value) {
            continue;
          }
          if (value.toString().toLowerCase().includes(option.name.toLowerCase())) {
            matchCount++;
            break;
          }
        }
      }
      if (matchCount === this.inputOptions.length) {
        return true;
      }
      return false;
    };
  }

  /**
   * This function is triggered by adding chips to the filter bar
   */
  public addInputChipFilterOption<T extends object>(
    event: MatChipInputEvent,
    dataSource: MatTableDataSource<T>,
    doBackendFilter?: () => void
  ): void {
    const input = event.input;
    const value = event.value;
    this.addInputFilterOption(value, dataSource, doBackendFilter);
    // Resets the input value so that the next chip to be entered starts as empty
    if (input) {
      input.value = '';
    }
  }

  public addInputFilterOption<T extends object>(value: string, dataSource: MatTableDataSource<T>, doBackendFilter?: () => void): void {
    const trimmedValue = (value || '').trim();
    if (trimmedValue) {
      const inputOption = {
        name: trimmedValue,
        displayName: trimmedValue,
        label: '',
        type: FilterType.INPUT,
        doFilter: !!doBackendFilter ? doBackendFilter : this.applyFilter.bind(this),
      };
      this.inputOptions.push(inputOption);
      this.newInputOptionAdded = true;
      this.filterBar.push(inputOption);
      inputOption.doFilter(dataSource);
    } else {
      this.newInputOptionAdded = false;
    }
  }

  public addCheckboxFilterOption(checkboxOption: CheckboxOption): void {
    this.checkboxOptions.push(checkboxOption);
    // In the unlikely event the default state is checked,
    // ensure the option is added to the filter bar
    if (checkboxOption.isChecked) {
      this.filterBar.push(checkboxOption);
    }
  }

  public removeCheckboxFilterOption(name: string, label: string): void {
    const checkboxOption = findFilterOptionByName(name, label, this.checkboxOptions);
    removeFromList(checkboxOption, this.checkboxOptions);
    removeFromList(checkboxOption, this.filterBar);
  }

  /**
   * This function is triggered by removing chips from the filter bar
   */
  public removeOptionFromFilterBar<T extends object>(
    filterOption: FilterOption,
    dataSource?: MatTableDataSource<T>,
    doBackendFilter?: () => void
  ): void {
    // The filter bar contains filters of all types
    // We need to check the type to determine the action required
    switch (filterOption.type) {
      case FilterType.CHECKBOX:
        const checkboxOption = findFilterOptionByName(filterOption.name, filterOption.label, this.checkboxOptions);
        this.onCheckBoxToggle(checkboxOption, dataSource);
        break;
      case FilterType.INPUT:
        const inputOption = findFilterOptionByName(filterOption.name, filterOption.label, this.inputOptions);
        removeFromList(inputOption, this.filterBar);
        removeFromList(inputOption, this.inputOptions);
        if (!!doBackendFilter) {
          doBackendFilter();
        } else {
          this.applyFilter(dataSource);
        }
        break;
      default:
        break;
    }
  }

  /**
   * Toggles the checkbox option isChecked property and either
   * adds it to or removes it from the filter bar
   */
  public onCheckBoxToggle<T extends object>(checkboxOption: CheckboxOption, dataSource: MatTableDataSource<T>): void {
    checkboxOption.isChecked = !checkboxOption.isChecked;
    if (checkboxOption.isChecked) {
      this.filterBar.push(checkboxOption);
    } else {
      removeFromList(checkboxOption, this.filterBar);
    }
    checkboxOption.doFilter(checkboxOption);
    this.applyFilter(dataSource);
  }

  public getInputOptions(label: string): Array<InputOption> {
    let filteredArray: Array<InputOption>;
    if (!label) {
      filteredArray = this.inputOptions;
    } else {
      filteredArray = this.inputOptions.filter((option) => option.label === label);
    }
    return filteredArray.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
  }

  public getAllInputOptionsWithValues(): Array<InputOption> {
    const allInputOptions = this.getInputOptions(null);
    return allInputOptions.filter((option) => option.displayName !== '');
  }

  public getGenericSearchParams(options: Array<FilterOption>): Array<string> {
    const genericSearchParams = [];
    for (const option of options) {
      genericSearchParams.push(option.displayName);
    }
    return genericSearchParams;
  }
}
